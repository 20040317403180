import React, {useCallback, useState} from "react";
import {
	Exist,
	IconRemove,
	ModalBackdrop,
	ModalClose,
	ModalCloseButton,
	ModalContent,
} from "components";
import styled from "styled-components";
import {DesktopDatePicker} from "@mui/x-date-pickers";
import {
	AuthLogos,
	ErrorText,
	Input,
	NavigateToAnotherAuthPageLink,
	PureButton,
	SubmitButtonWrapper,
	TextFieldControl,
} from "components/styled";
import {DateTime} from "luxon";
import {Checkbox, FormControlLabel} from "@mui/material";
import {IRegisterFormPayload, IRegisterPayload} from "modules/types/user";
import {ICheckboxDataset} from "modules/types";
import {handleAuthModal, userRegister} from "modules/actions";
import {getIsSavingMode} from "modules/selectors";
import {
	USER_NAME_PATTERN,
	MAX_USER_DATE,
	MIN_USER_DATE,
	PASSWORD_PATTERN,
	USER_DOB_FORMAT,
} from "modules/constants";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import LogoIcon from "assets/images/ford_logo_blue.svg";
import fantasyIcon from "assets/images/fantasy_logo_wrap.svg";
import {EAuthModal} from "modules/types/enums";
import {ReactComponent as CheckedIcon} from "assets/images/icons/checkbox_checked.svg";
import {ReactComponent as UncheckedIcon} from "assets/images/icons/checkbox_unchecked.svg";
import {useTranslation} from "react-i18next";

const Title = styled.h2`
	color: #222222;
	text-align: center;
	font-weight: 700;
`;

const Form = styled.form`
	margin-top: 40px;
`;

const LabelWithLink = styled(FormControlLabel)`
	a {
		color: ${({theme}) => theme.text_color};
	}
`;

export const ModalRegister: React.FC = () => {
	const dispatch = useDispatch();
	const {t} = useTranslation();
	const isSaveMode = useSelector(getIsSavingMode);
	const [error, setError] = useState("");
	const [registerValues, setRegisterValue] = useState<Partial<IRegisterFormPayload>>({
		birthday: MAX_USER_DATE.toJSDate(),
		email: "",
		confirmEmail: "",
		firstName: "",
		geniusOptIn: false,
		isNotificationsEnabled: false,
		lastName: "",
		iflOptIn: false,
		password: "",
		confirmPassword: "",
		terms: false,
	});

	const updateCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.currentTarget.checked;
		const fieldName = e.currentTarget.dataset["field"];

		if (!fieldName) {
			return;
		}

		setRegisterValue({
			...registerValues,
			[fieldName]: value,
		});
	};

	const updateField = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			const value = e.target.value;
			const fieldName = e.target.name || e.target.dataset["field"];

			if (!value || !fieldName) {
				return;
			}

			setRegisterValue({
				...registerValues,
				[fieldName]: value,
			});
		},
		[registerValues]
	);

	const setDOB = useCallback(
		(date: DateTime | null) => {
			if (!date) {
				return;
			}

			setRegisterValue({
				...registerValues,
				birthday: date.toJSDate(),
			});
		},
		[registerValues]
	);

	const onFormSubmit = (e: React.SyntheticEvent) => {
		e.preventDefault();

		setError("");
		if (registerValues.email?.toLowerCase() !== registerValues.confirmEmail?.toLowerCase()) {
			setError("Emails don't match");
			return;
		}

		if (
			registerValues.password?.toLowerCase() !== registerValues.confirmPassword?.toLowerCase()
		) {
			setError("Passwords don't match");
			return;
		}

		const userPayload: IRegisterPayload = {
			email: registerValues.email!,
			password: registerValues.password!,
			firstName: registerValues.firstName!,
			lastName: registerValues.lastName!,
			birthday: DateTime.fromJSDate(registerValues.birthday!).toFormat(USER_DOB_FORMAT),
			iflOptIn: registerValues.iflOptIn!,
			geniusOptIn: registerValues.geniusOptIn!,
			isNotificationsEnabled: registerValues.iflOptIn! || registerValues.geniusOptIn!,
		};

		dispatch(userRegister(userPayload));
	};

	const toggleModal = useCallback(() => {
		dispatch(handleAuthModal({authModalToShow: EAuthModal.LOGIN}));
	}, [dispatch]);

	const closeModal = useCallback(() => {
		dispatch(handleAuthModal({showAuthModal: false}));
	}, [dispatch]);

	const modalHasFadeClass = isSaveMode ? "fade" : "";

	const validateEmailText = () => {
		if (registerValues?.email !== "" && !/\S+@\S+\.\S+/.test(registerValues?.email || "")) {
			return t("registration.modal.email_requirements");
		}
		return "";
	};

	const validatePasswordText = () => {
		const validPassword = Boolean(registerValues?.password?.match(PASSWORD_PATTERN));
		if (registerValues?.password !== "" && !validPassword) {
			return t("registration.modal.password_requirements");
		}
		return "";
	};

	const validateLastNameText = () => {
		const validPassword = Boolean(registerValues?.lastName?.match(USER_NAME_PATTERN));
		if (registerValues?.lastName !== "" && !validPassword) {
			return t("registration.modal.last_name_requirements");
		}
		return "";
	};

	const validateFirstNameText = () => {
		const validPassword = Boolean(registerValues?.firstName?.match(USER_NAME_PATTERN));
		if (registerValues?.firstName !== "" && !validPassword) {
			return t("registration.modal.first_name_requirements");
		}
		return "";
	};

	return (
		<ModalBackdrop className={modalHasFadeClass}>
			<ModalContent className="full">
				<Exist when={isSaveMode}>
					<ModalCloseButton onClick={closeModal}>
						<IconRemove />
					</ModalCloseButton>
				</Exist>
				<Exist when={!isSaveMode}>
					<ModalClose to="/">
						<IconRemove />
					</ModalClose>
				</Exist>
				<AuthLogos>
					<img src={LogoIcon} alt="Besta Deildin" width="168" />
					<img src={fantasyIcon} alt="Football Fantasy" width="147" />
				</AuthLogos>
				<Title>{t("registration.modal.account_title")}</Title>
				<Exist when={Boolean(error)}>
					<ErrorText className="center">{error}</ErrorText>
				</Exist>
				<Form onSubmit={onFormSubmit}>
					<TextFieldControl className="sized">
						<Input
							variant="outlined"
							label={t("registration.modal.first_name")}
							onChange={updateField}
							required={true}
							helperText={validateFirstNameText()}
							inputProps={{
								"data-field": "firstName",
								pattern: USER_NAME_PATTERN,
							}}
						/>
					</TextFieldControl>

					<TextFieldControl className="sized">
						<Input
							variant="outlined"
							label={t("registration.modal.last_name")}
							onChange={updateField}
							required={true}
							helperText={validateLastNameText()}
							inputProps={{
								"data-field": "lastName",
								pattern: USER_NAME_PATTERN,
							}}
						/>
					</TextFieldControl>

					<TextFieldControl className="sized">
						<Input
							variant="outlined"
							label={t("registration.modal.email")}
							type="email"
							inputProps={{"data-field": "email"}}
							onChange={updateField}
							helperText={validateEmailText()}
							required={true}
						/>
					</TextFieldControl>

					<TextFieldControl className="sized">
						<Input
							variant="outlined"
							type="email"
							label={t("registration.modal.email")}
							onChange={updateField}
							inputProps={{"data-field": "confirmEmail"}}
							required={true}
						/>
					</TextFieldControl>
					<TextFieldControl className="sized">
						<Input
							variant="outlined"
							label={t("registration.modal.password")}
							type="password"
							onChange={updateField}
							required={true}
							inputProps={{
								"data-field": "password",
								pattern: PASSWORD_PATTERN,
							}}
							helperText={validatePasswordText()}
						/>
					</TextFieldControl>
					<TextFieldControl className="sized">
						<Input
							variant="outlined"
							label={t("registration.modal.confirm")}
							type="password"
							onChange={updateField}
							required={true}
							inputProps={{
								"data-field": "confirmPassword",
								pattern: PASSWORD_PATTERN,
							}}
						/>
					</TextFieldControl>
					<TextFieldControl className="sized">
						<DesktopDatePicker
							label={t("registration.modal.dob")}
							minDate={MIN_USER_DATE}
							maxDate={MAX_USER_DATE}
							format={USER_DOB_FORMAT}
							value={DateTime.fromJSDate(registerValues.birthday ?? new Date())}
							onChange={setDOB}
							slots={{
								textField: Input,
							}}
							slotProps={{
								textField: {
									required: true,
								},
							}}
						/>
					</TextFieldControl>

					<TextFieldControl className="sized checkbox">
						<LabelWithLink
							control={
								<Checkbox
									checked={registerValues.terms}
									onChange={updateCheckbox}
									required={true}
									// Seems as broken types in library, InputProps appends error, used disabled to avoid any
									// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
									inputProps={{"data-field": "terms"} as ICheckboxDataset}
									icon={<UncheckedIcon />}
									checkedIcon={<CheckedIcon />}
								/>
							}
							label={
								<span>
									{t("registration.modal.t&C")}{" "}
									<a
										href="https://fantasy.bestadeildin.is/help/terms"
										target="_blank"
										rel="noreferrer">
										https://fantasy.bestadeildin.is/help/terms
									</a>
								</span>
							}
						/>
						{registerValues.terms}
					</TextFieldControl>

					<TextFieldControl className="sized checkbox">
						<FormControlLabel
							control={
								<Checkbox
									checked={registerValues.iflOptIn}
									onChange={updateCheckbox}
									// Seems as broken types in library, InputProps appends error, used disabled to avoid any
									// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
									inputProps={{"data-field": "iflOptIn"} as ICheckboxDataset}
									icon={<UncheckedIcon />}
									checkedIcon={<CheckedIcon />}
								/>
							}
							label={t("registration.modal.liquid_media").toString()}
						/>
					</TextFieldControl>
					<TextFieldControl className="sized checkbox">
						<FormControlLabel
							control={
								<Checkbox
									checked={registerValues.geniusOptIn}
									onChange={updateCheckbox}
									// Seems as broken types in library, InputProps appends error, used disabled to avoid any
									// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
									inputProps={{"data-field": "geniusOptIn"} as ICheckboxDataset}
									icon={<UncheckedIcon />}
									checkedIcon={<CheckedIcon />}
								/>
							}
							label={t("registration.modal.agree_tc").toString()}
						/>
					</TextFieldControl>
					<SubmitButtonWrapper>
						<button>{t("registration.modal.register")}</button>
					</SubmitButtonWrapper>
					<NavigateToAnotherAuthPageLink>
						<Exist when={!isSaveMode}>
							{t("landing.text.account_log_in")}{" "}
							<Link to="/login">
								{t("registration.modal.existing_account_log_in")}
							</Link>
						</Exist>
						<Exist when={isSaveMode}>
							{t("landing.text.account_log_in")}{" "}
							<PureButton type="button" onClick={toggleModal}>
								{t("registration.modal.existing_account_log_in")}
							</PureButton>
						</Exist>
					</NavigateToAnotherAuthPageLink>
				</Form>
			</ModalContent>
		</ModalBackdrop>
	);
};
