import {createReducer} from "redux-act";
import {INotification, IShareDataReducer} from "modules/types/reducers";
import {Project, Sport} from "modules/types/sport";
import {
	fetchAllTeamsStatsSuccess,
	hideNotification,
	setLanguage,
	setProject,
	setSport,
	setTheme,
	showNotification,
} from "modules/actions";
import {theme} from "assets/theme";
import {Theme} from "@mui/material";

const defaultState: IShareDataReducer = {
	sport: Sport.IFL,
	project: Project.Classic,
	language: "en",
	notification: null,
	teamsStats: {},
	appTheme: theme,
};

const setSportHandler = (state: IShareDataReducer, payload: Sport): IShareDataReducer => ({
	...state,
	sport: payload,
});

const setProjectHandler = (state: IShareDataReducer, payload: Project): IShareDataReducer => ({
	...state,
	project: payload,
});

const setLanguageHandler = (state: IShareDataReducer, payload: string): IShareDataReducer => ({
	...state,
	language: payload,
});

const onShowNotification = (
	state: IShareDataReducer,
	payload: INotification
): IShareDataReducer => ({
	...state,
	notification: {
		type: payload.type ?? "success",
		duration: payload.duration || 3000,
		message: payload.message,
	},
});

const onHideNotification = (state: IShareDataReducer): IShareDataReducer => ({
	...state,
	notification: null,
});

const onFetchAllTeamsStatsSuccess = (
	state: IShareDataReducer,
	payload: IShareDataReducer["teamsStats"]
): IShareDataReducer => ({
	...state,
	teamsStats: payload,
});

const onSetTheme = (state: IShareDataReducer, payload: Theme): IShareDataReducer => ({
	...state,
	appTheme: payload,
});

export const shared = createReducer<IShareDataReducer>({}, defaultState)
	.on(setSport, setSportHandler)
	.on(setProject, setProjectHandler)
	.on(setLanguage, setLanguageHandler)
	.on(showNotification, onShowNotification)
	.on(hideNotification, onHideNotification)
	.on(fetchAllTeamsStatsSuccess, onFetchAllTeamsStatsSuccess)
	.on(setTheme, onSetTheme);
