// import {HTTPClient as HTTPClientCore, IRequestConfig} from "@fanhubmedia/fe-common-utils";
import {API_URL, JSON_URL} from "modules/constants";
import {
	IAutoPickRequest,
	IContactUsRequestPayload,
	ICreateLeagueRequestPayload,
	IDictionary,
	IFetchLeaguesGetParams,
	IFetchRanksRequestPayload,
	IFetchTeamByIdParams,
	IFetchTeamHistoryParams,
	IGameBarPayload,
	ILeagueInvitePayload,
	IMessageBar,
	INewTeamRequest,
	IPaginationRequest,
	IResetPasswordPayload,
	ISportWithIdPayload,
	ISquad,
	ITradeRequestPayload,
	IUserUpdatePasswordPayload,
	IUserUpdatePayload,
	IWithSportPayload,
	Sport,
} from "modules/types";
import {ILoginPayload, IRegisterPayload} from "modules/types/user";
import {
	TApiAutoPickResponse,
	TApiCreateLeaguesResponse,
	TApiFetchJoinLeaguesResponse,
	TApiFetchLeagueRanksResponse,
	TApiFetchLeaguesResponse,
	TApiFetchLeagueUsersResponse,
	TApiFetchRanksResponse,
	TApiGameBarResponse,
	TApiGetTeamHistoryResponse,
	TApiGetTeamResponse,
	TApiRegisterResponse,
	TApiTransferResponse,
} from "modules/types/api";
import {IPlayer, IPlayerStat, IRound} from "modules/types/json";
import {IHelpReducer, IHelpSection} from "modules/types/reducers";
import {IStatsCentrePlayer, IStatsCentreTeam} from "modules/types/shared";
import i18n from "i18next";
import {HttpClientService} from "modules/utils/Api/HTTPClient";

export const getBaseAPI = () => {
	const currentLang = i18n.language || "en";
	return API_URL + currentLang + "/";
};

const APIClient = new HttpClientService({
	baseURL: getBaseAPI(),
	withCredentials: true,
});

const JSONClient = new HttpClientService({
	baseURL: JSON_URL,
});

export const Api = {
	General: {
		ping: () => APIClient.get("ping"),
	},
	JSON: {
		checksums: () => JSONClient.get<IDictionary<string>>(`/checksums.json`),
		help_pages: (name: keyof IHelpReducer) => JSONClient.get<IHelpSection>(`/${name}.json`),
		rounds: (SPORT: string) => JSONClient.get<IRound[]>(`/${SPORT}/rounds.json`),
		players: () => JSONClient.get<IPlayer[]>("/players.json"),
		squads: (SPORT: string) => JSONClient.get<ISquad[]>(`/${SPORT}/squads.json`),
		venues: () => JSONClient.get("/venues.json"),
		all_players_stats: (SPORT: string) =>
			JSONClient.get<Record<number, IStatsCentrePlayer>>(`/${SPORT}/player_stats.json`),
		all_teams_stats: (SPORT: string) =>
			JSONClient.get<Record<number, IStatsCentreTeam>>(`/${SPORT}/team_stats.json`),
		player_stats: (request: IWithSportPayload<number>) =>
			JSONClient.get<IPlayerStat[]>(
				`${request.sport.toLocaleLowerCase()}/player_stats/${request.payload}.json`
			),
		message_bar: () => JSONClient.get<IMessageBar>(`/message_bar.json`),
	},
	Team: {
		get: (sport: Sport) =>
			APIClient.get<TApiGetTeamResponse>(`/${sport.toLocaleLowerCase()}/fantasy_team`),
		getHistory: ({sport, roundId}: IFetchTeamHistoryParams) =>
			APIClient.get<TApiGetTeamHistoryResponse>(
				`/${sport.toLocaleLowerCase()}/fantasy_team/history/${roundId}`
			),
		create: (params: IWithSportPayload<INewTeamRequest>) =>
			APIClient.post<TApiGetTeamResponse>(
				`/${params.sport.toLocaleLowerCase()}/fantasy_team`,
				params.payload
			),
		update: (params: IWithSportPayload<INewTeamRequest>) =>
			APIClient.post<TApiGetTeamResponse>(
				`${params.sport.toLocaleLowerCase()}/fantasy_team`,
				params.payload
			),
		autoPick: (params: IWithSportPayload<IAutoPickRequest>) =>
			APIClient.post<TApiAutoPickResponse>(
				`${params.sport.toLocaleLowerCase()}/fantasy_team/autopick`,
				params.payload
			),
		autoPickFree: (params: IWithSportPayload<IAutoPickRequest>) =>
			APIClient.post<TApiAutoPickResponse>(
				`${params.sport.toLocaleLowerCase()}/fantasy_team/autopick-free`,
				params.payload
			),
		makeTrade: (params: IWithSportPayload<ITradeRequestPayload>) =>
			APIClient.post<TApiTransferResponse>(
				`${params.sport.toLocaleLowerCase()}/fantasy_trade/make`,
				params.payload
			),
		fetchGameBar: (params: IWithSportPayload<IGameBarPayload>) =>
			APIClient.get<TApiGameBarResponse>(
				`${params.sport.toLocaleLowerCase()}/fantasy_ranking/gamebar`,
				params.payload
			),
		fetchLeaderboard: (params: IWithSportPayload<IFetchRanksRequestPayload>) =>
			APIClient.get<TApiFetchRanksResponse>(
				`${params.sport.toLocaleLowerCase()}/fantasy_ranking`,
				params.payload
			),
		getById: (params: IFetchTeamByIdParams) =>
			APIClient.get<TApiGetTeamResponse>(
				`/${params.sport.toLocaleLowerCase()}/fantasy_team/other_user_team/${
					params.roundId
				}/${params.id}`
			),
	},
	Contact: {},
	User: {
		getUser: () => APIClient.get<TApiRegisterResponse>("/user"),
		updateUser: (request: IUserUpdatePayload) =>
			APIClient.post<TApiRegisterResponse>("/user/update", request),
		changePassword: (request: IUserUpdatePasswordPayload) =>
			APIClient.post<TApiRegisterResponse>("/user/update", request),
		passwordChangeRequest: (request: string) =>
			APIClient.post<unknown>("/auth/password_reset/request", {email: request}),
		passwordResetRequest: (request: IResetPasswordPayload) =>
			APIClient.post<unknown>("auth/password_reset", request),
		contactUs: (request: IContactUsRequestPayload) =>
			APIClient.post<unknown>("/contact", request),
		viewWelcomeModal: () => APIClient.post<unknown>("/user/view_welcome_modal"),
		recover: () => APIClient.post<TApiRegisterResponse>("/user/recover"),
	},
	Auth: {
		register: (request: IRegisterPayload) =>
			APIClient.post<TApiRegisterResponse>("auth/register", request),
		login: (request: ILoginPayload) =>
			APIClient.post<TApiRegisterResponse>("auth/login", request),
		logout: () => APIClient.post("auth/logout"),
	},
	League: {
		fetch: (request: IWithSportPayload<IFetchLeaguesGetParams>) =>
			APIClient.get<TApiFetchLeaguesResponse>(
				`${request.sport.toLocaleLowerCase()}/fantasy_leagues`,
				request.payload
			),
		create: (request: IWithSportPayload<ICreateLeagueRequestPayload>) =>
			APIClient.post<TApiCreateLeaguesResponse>(
				`${request.sport.toLocaleLowerCase()}/fantasy_league`,
				request.payload
			),
		update: (request: ISportWithIdPayload<ICreateLeagueRequestPayload>) =>
			APIClient.post<TApiCreateLeaguesResponse>(
				`${request.sport.toLocaleLowerCase()}/fantasy_league/${request.id}`,
				request.payload
			),
		fetchJoin: (request: IWithSportPayload<IPaginationRequest>) =>
			APIClient.get<TApiFetchJoinLeaguesResponse>(
				`${request.sport.toLocaleLowerCase()}/fantasy_league/show-for-join`,
				request.payload
			),
		join: (request: IWithSportPayload<string>) =>
			APIClient.post<TApiCreateLeaguesResponse>(
				`${request.sport.toLocaleLowerCase()}/fantasy_league/${request.payload}/join`
			),
		leave: (request: IWithSportPayload<number>) =>
			APIClient.post<unknown>(
				`${request.sport.toLocaleLowerCase()}/fantasy_league/${request.payload}/leave`
			),
		sendInvites: (request: ISportWithIdPayload<ILeagueInvitePayload[]>) =>
			APIClient.post<unknown>(
				`${request.sport.toLocaleLowerCase()}/fantasy_league/${request.id}/invite`,
				{invites: request.payload}
			),
		fetchUsers: (request: ISportWithIdPayload<IPaginationRequest>) =>
			APIClient.get<TApiFetchLeagueUsersResponse>(
				`${request.sport.toLocaleLowerCase()}/fantasy_league/${request.id}/league-users`,
				request.payload
			),
		fetchRanks: (request: ISportWithIdPayload<IFetchRanksRequestPayload>) =>
			APIClient.get<TApiFetchLeagueRanksResponse>(
				`${request.sport.toLocaleLowerCase()}/fantasy_ranking/league/${request.id}`,
				request.payload
			),
		removeLeagueUser: (request: ISportWithIdPayload<number>) =>
			APIClient.post<unknown>(
				`${request.sport.toLocaleLowerCase()}/fantasy_league/${request.id}/user/${
					request.payload
				}`
			),
		fetchTable: (request: ISportWithIdPayload<IFetchRanksRequestPayload>) =>
			APIClient.get<TApiFetchRanksResponse>(
				`${request.sport.toLocaleLowerCase()}/fantasy_ranking/league/${request.id}`,
				request.payload
			),
	},
	Rankings: {},
	Dashboard: {},
	Gamebar: {},
	Answer: {},
};

export * from "./ApiError";
