import {createReducer} from "redux-act";

export interface IPosition {
	id: number;
	name: string;
	shortName: string;
	plural: string;
	text: string;
}

const initialState: IPosition[] = [
	{
		id: 1,
		name: "Goalkeepers",
		shortName: "GK",
		plural: "Goalkeepers",
		text: "team.player_pool.filter_goalkeeper",
	},
	{
		id: 2,
		name: "Defenders",
		shortName: "DEF",
		plural: "Defenders",
		text: "team.player_pool.filter_defenders",
	},
	{
		id: 3,
		name: "Midfielders",
		shortName: "MID",
		plural: "Midfielders",
		text: "team.player_pool.filter_midfielders",
	},
	{
		id: 4,
		name: "Strikers",
		shortName: "ST",
		plural: "Strikers",
		text: "team.player_pool.filter_strikers",
	},
];

export const positions = createReducer<IPosition[]>({}, initialState);
