import {createReducer} from "redux-act";
import {IHelpReducer, IHelpSuccessPayload} from "modules/types/reducers";
import {fetchHelpSuccess} from "modules/actions";

const defaultState: IHelpReducer = {
	guidelines: null,
	terms: null,
	faq: null,
	prizes: null,
};

const onFetchHelpSuccess = (state: IHelpReducer, payload: IHelpSuccessPayload): IHelpReducer => ({
	...state,
	[payload.key]: payload.value,
});

export const help = createReducer<IHelpReducer>({}, defaultState).on(
	fetchHelpSuccess,
	onFetchHelpSuccess
);
