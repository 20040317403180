import {SecondaryLink} from "components/styled";
import React from "react";
import styled from "styled-components";
import {useSelector} from "react-redux";
import {getProject, getSport} from "modules/selectors";
import {useTranslation} from "react-i18next";

const Wrapper = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 12px;

	a {
		margin: 0;
	}
`;

export const StatsCentreNavigation: React.FC = () => {
	const project = useSelector(getProject);
	const sport = useSelector(getSport);
	const {t} = useTranslation();

	return (
		<Wrapper>
			<SecondaryLink end to={`/${project}/${sport}/stats-centre`}>
				{t("join_leagues.table.h_players")}
			</SecondaryLink>
			<SecondaryLink to={`/${project}/${sport}/stats-centre/teams`}>{t("stats_centre.teams.teams")}</SecondaryLink>
		</Wrapper>
	);
};
