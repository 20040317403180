import React, {FC} from "react";
import styled from "styled-components";

const Link = styled.a`
	color: #0000b8;
	text-decoration: underline;
`;

export const TextLink: FC<
	Omit<
		React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>,
		"ref"
	>
> = (props) => {
	return <Link {...props}>{props.children}</Link>;
};
