import {createReducer} from "redux-act";
import {IPlayerReducer} from "modules/types/reducers";
import * as actions from "modules/actions/players";
import {IPlayer, IPlayerStat} from "modules/types/json";
import {IStatsCentrePlayer} from "modules/types/shared";

const defaultState: IPlayerReducer = {
	players: [],
	playerStats: [],
	statsCentrePlayerStats: {},
};

const onFetchPlayersSuccess = (state: IPlayerReducer, payload: IPlayer[]): IPlayerReducer => ({
	...state,
	players: payload,
});

const onFetchPlayerStatsSuccess = (
	state: IPlayerReducer,
	payload: IPlayerStat[]
): IPlayerReducer => ({
	...state,
	playerStats: payload,
});

const onPlayerStatsClear = (state: IPlayerReducer): IPlayerReducer => ({
	...state,
	playerStats: [],
});

const onFetchAllPlayersStatsSuccess = (
	state: IPlayerReducer,
	payload: Record<number, IStatsCentrePlayer>
): IPlayerReducer => ({
	...state,
	statsCentrePlayerStats: payload,
});

export const player = createReducer<IPlayerReducer>({}, defaultState)
	.on(actions.fetchPlayersSuccess, onFetchPlayersSuccess)
	.on(actions.fetchPlayerStatsSuccess, onFetchPlayerStatsSuccess)
	.on(actions.playerStatsClear, onPlayerStatsClear)
	.on(actions.fetchAllPlayersStatsSuccess, onFetchAllPlayersStatsSuccess);
