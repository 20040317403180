import {
	IAutoPickTeam,
	IFetchRanksRequestPayload,
	IGenerateRequestStateHandler,
	IStore,
	IWithNextPayload,
	ITeamHeads,
	ILeaderboardPayload,
	Sport,
	ITeamHistory,
} from "modules/types";
import {createAction} from "redux-act";
import {IFormation} from "modules/utils/Team";
import {IAuthModal, ILineup, INewTeam, ITeam} from "modules/types/team";
import {ITeamsReducer} from "modules/types/reducers";
import {StatsTab} from "modules/types/enums";

export const getTeamsState = ({teams}: IStore) => teams;

export const fetchTeam = createAction();
export const saveTeam = createAction();
export const setTeamSaved = createAction();
export const handleAuthModal = createAction<IAuthModal>();
export const handlePreAuthModal = createAction<boolean>();

export interface IFetchTeamHistoryPayload {
	roundId: number;
}

export interface IFetchTeamHistoryParams extends IFetchTeamHistoryPayload {
	sport: Sport;
}

export const fetchTeamHistory = createAction<IFetchTeamHistoryPayload>();
export const fetchTeamHistorySuccess = createAction<ITeamHistory>();
export const fetchTeamHistoryFailed = createAction();

export const changeFormation = createAction<IFormation>();
export const changeFormationSuccess = createAction<INewTeam>();

export const pickPlayerToTeam = createAction<number>();
export const removePlayerFromTeam = createAction<number>();

export const toggleNoHeadsModal = createAction<boolean>();
export const toggleEditTeamNameModal = createAction<boolean>();
export const editModalSavingMode = createAction<boolean>();
export const storeTeamName = createAction<string>();

export const toggleTeamSavedModal = createAction<boolean>();

export const updateLineup = createAction<ILineup>();

export const switchToTeamID = createAction<number>();
export const savedTeamSuccess = createAction<ITeam>();
export const teamFetchComplete = createAction();
export const setUserHasTeam = createAction<boolean>();
// export const savedTeamFailed = createAction<IError>();

export const autoFillRequest = createAction();
export const autoFillSuccess = createAction<IAutoPickTeam>();
export const autoFillClear = createAction();

export const setActiveTabAction = createAction<StatsTab>();

export const fetchLeaderboardRequest = createAction<IFetchRanksRequestPayload>();
export const fetchLeaderboardSuccess = createAction<IWithNextPayload<ILeaderboardPayload>>();
export const fetchLeaderboardConcat = createAction<IWithNextPayload<ILeaderboardPayload>>();

export const teamGlobalRequestStateHandler =
	createAction<IGenerateRequestStateHandler<ITeamsReducer["requestState"]>>();

export const setHeads = createAction<ITeamHeads>();
export const clearHeads = createAction();
export const setTeamComplete = createAction<boolean>();
export const showTeamNameModal = createAction();

export interface IFetchTeamByIdPayload {
	id: number;
	roundId: number;
}

export interface IFetchTeamByIdParams extends IFetchTeamByIdPayload {
	sport: Sport;
}

export const fetchTeamById = createAction<IFetchTeamByIdPayload>();
export const fetchTeamByIdSuccess = createAction<ITeam>();
export const fetchTeamByIdFailed = createAction();
