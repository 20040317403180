import React, {useCallback, useEffect, useState} from "react";
import {Input, PrimaryButton, PureButton} from "components/styled";
import {Exist} from "components/Exist";
import styled from "styled-components";
import {ILeagueControl} from "modules/types";
import {sendLeagueInviteRequest} from "modules/actions";
import {getLeagueRequestStateByKey} from "modules/selectors";
import {useDispatch, useSelector} from "react-redux";
import {media} from "assets/media";
import {IconPlus, IconRemove} from "components/Icons";
import {useTranslation} from "react-i18next";

const FormControlButton = styled(PureButton)`
	color: ${({theme}) => theme.text_color};
	width: 48px;
	height: 48px;
	display: flex;
	justify-content: center;
	align-items: center;

	@media screen and ${media.lessThanMD} {
		position: relative;
		top: 0;
		right: 0;
		transform: unset;
		width: 32px;
		height: 32px;
		margin-left: 10px;
	}
`;

const Form = styled.form`
	${PrimaryButton} {
		margin-bottom: 16px;
	}
`;

const ControlWrapper = styled.div`
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 16px;
	margin-bottom: 20px;

	&:last-of-type {
		margin-bottom: 32px;
	}

	${Input} {
		width: 100%;
	}

	@media screen and ${media.lessThanMD} {
		gap: 0;
		${Input}:first-of-type {
			max-width: 100px;
			margin-right: 16px;
		}
	}
`;

const defaultInviteControls: ILeagueControl[] = [
	{id: 1, name: "", email: ""},
	{id: 2, name: "", email: ""},
];

interface IProps {
	leagueId?: number;
}

export const InviteForm: React.FC<IProps> = ({leagueId}) => {
	const dispatch = useDispatch();
	const {t} = useTranslation();
	const [inviteControl, setInviteControl] = useState(defaultInviteControls);
	const {isLoading, isSuccess} = useSelector(getLeagueRequestStateByKey)("sendInvites");
	const inviteAdded = inviteControl.filter(
		(control) => control.name !== "" && control.email !== ""
	).length;
	const nameLabel = t("registration.modal.first_name");

	useEffect(() => {
		if (isSuccess) {
			setInviteControl(defaultInviteControls);
		}
	}, [isSuccess]);

	const onFormChange = useCallback(
		(e: React.ChangeEvent<HTMLFormElement>) => {
			const {id, name, value} = e.target;

			if (!name || !id) {
				return;
			}

			const index = inviteControl.findIndex((item) => item.id === Number(id));
			if (index === -1) {
				return;
			}

			const copy = [...inviteControl];
			copy[index] = {...copy[index], [name]: String(value || "")};
			setInviteControl(copy);
		},
		[inviteControl]
	);

	const onFormSubmit = useCallback(
		(e: React.SyntheticEvent) => {
			e.preventDefault();
			e.stopPropagation();

			const filledControls = inviteControl
				.filter((control) => control.name !== "" && control.email !== "")
				.map((control) => ({name: control.name, email: control.email}));

			if (filledControls.length === 0) {
				return;
			}

			dispatch(
				sendLeagueInviteRequest({
					id: leagueId!,
					payload: filledControls,
				})
			);
		},
		[leagueId, inviteControl, dispatch]
	);

	const addFormControl = useCallback(() => {
		setInviteControl([...inviteControl, {id: Date.now(), name: "", email: ""}]);
	}, [inviteControl]);

	const removeFormControl = useCallback(
		(e: React.SyntheticEvent<HTMLButtonElement>) => {
			const id = Number(e.currentTarget.dataset.id);
			const index = inviteControl.findIndex((control) => control.id === id);

			if (!id || index === -1) {
				return;
			}
			const copy = [...inviteControl];
			copy.splice(index, 1);
			setInviteControl(copy);
		},
		[inviteControl]
	);

	if (!leagueId) {
		return null;
	}

	return (
		<Form onChange={onFormChange} onSubmit={onFormSubmit}>
			{inviteControl.map((control, index) => (
				<ControlWrapper key={control.id}>
					<Input
						id={String(control.id)}
						name="name"
						label={nameLabel}
						value={control.name}
					/>
					<Input
						id={String(control.id)}
						label={t("create_league.form_step_two.email")}
						name="email"
						value={control.email}
						type="email"
					/>
					<Exist when={index !== inviteControl.length - 1}>
						<FormControlButton
							type="button"
							className="action-button"
							data-id={control.id}
							onClick={removeFormControl}>
							<IconRemove width={14} height={14} />
						</FormControlButton>
					</Exist>
					<Exist when={index === inviteControl.length - 1}>
						<FormControlButton
							type="button"
							className="action-button"
							onClick={addFormControl}>
							<IconPlus width={18} height={18} />
						</FormControlButton>
					</Exist>
				</ControlWrapper>
			))}
			<PrimaryButton className="w-220" type="submit" disabled={isLoading || !inviteAdded}>
				{isLoading ? "Sending..." : t("create_league.form_step_two.button_send")}
			</PrimaryButton>
		</Form>
	);
};
