import React, {PropsWithChildren} from "react";
import {APP_VERSION} from "modules/constants";

interface IProps {
	version: number;
}

export const ExistForVersion: React.FC<PropsWithChildren<IProps>> = ({children, version}) => {
	if (version > APP_VERSION) {
		return null;
	}

	return <React.Fragment>{children}</React.Fragment>;
};
