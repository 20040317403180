import {
	Exist,
	IconRemove,
	ModalBackdrop,
	ModalClose,
	ModalCloseButton,
	ModalContent,
} from "components";
import React, {useCallback, useState} from "react";
import styled from "styled-components";
import {
	AuthLogos,
	Input,
	NavigateToAnotherAuthPageLink,
	PrimaryButton,
	PureButton,
	SubmitButtonWrapper,
	TextFieldControl,
} from "components/styled";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {handleAuthModal, userLogin} from "modules/actions";
import {EAuthModal} from "modules/types/enums";
import {getIsSavingMode} from "modules/selectors";
import LogoIcon from "assets/images/ford_logo_blue.svg";
import fantasyIcon from "assets/images/fantasy_logo_wrap.svg";
import {useTranslation} from "react-i18next";

const Title = styled.h2`
	color: #222222;
	text-align: center;
	font-weight: 700;
`;

const Form = styled.form`
	margin-top: 40px;
`;

const ForgotPasswordLink = styled.div`
	display: block;
	max-width: 300px;
	width: 100%;
	margin: 0 auto;
	text-align: right;

	a {
		color: ${({theme}) => theme.secondary};
		font-size: 14px;
		letter-spacing: 0;
		line-height: 18px;
		text-decoration: none;
	}
`;

export const ModalLogin: React.FC = () => {
	const dispatch = useDispatch();
	const {t} = useTranslation();
	const isSaveMode = useSelector(getIsSavingMode);
	const [loginValues, setLoginValues] = useState({
		email: "",
		password: "",
	});

	const onSubmit = (e: React.SyntheticEvent) => {
		e.preventDefault();
		dispatch(userLogin(loginValues));
	};

	const updateField = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			const value = e.target.value;
			const fieldName = e.target.name || e.target.dataset["field"];

			if (!fieldName) {
				return;
			}

			setLoginValues({
				...loginValues,
				[fieldName]: value,
			});
		},
		[loginValues]
	);

	const toggleModal = useCallback(() => {
		dispatch(handleAuthModal({authModalToShow: EAuthModal.REGISTRATION}));
	}, [dispatch]);

	const closeModal = useCallback(() => {
		dispatch(handleAuthModal({showAuthModal: false}));
	}, [dispatch]);

	const modalHasFadeClass = isSaveMode ? "fade" : "";

	return (
		<ModalBackdrop className={modalHasFadeClass}>
			<ModalContent className="full">
				<Exist when={isSaveMode}>
					<ModalCloseButton onClick={closeModal}>
						<IconRemove />
					</ModalCloseButton>
				</Exist>
				<Exist when={!isSaveMode}>
					<ModalClose to="/">
						<IconRemove />
					</ModalClose>
				</Exist>
				<AuthLogos>
					<img src={LogoIcon} alt="Besta Deildin" width="168" />
					<img src={fantasyIcon} alt="Football Fantasy" width="147" />
				</AuthLogos>
				<Title>{t("landing.header.Log_in")}</Title>
				<Form onSubmit={onSubmit}>
					<TextFieldControl className="sized">
						<Input
							variant="outlined"
							label={t("landing.credentials.default_email")}
							type="email"
							inputProps={{"data-field": "email"}}
							onChange={updateField}
							value={loginValues.email}
							required={true}
						/>
					</TextFieldControl>

					<TextFieldControl className="sized">
						<Input
							variant="outlined"
							label={t("landing.credentials.default_password")}
							type="password"
							inputProps={{"data-field": "password"}}
							value={loginValues.password}
							onChange={updateField}
							required={true}
						/>
					</TextFieldControl>
					<ForgotPasswordLink>
						<Link to="/forgot-password">{t("landing.text.forgot_password")}</Link>
					</ForgotPasswordLink>
					<SubmitButtonWrapper>
						<PrimaryButton>{t("landing.body.button_log_in")}</PrimaryButton>
					</SubmitButtonWrapper>
					<NavigateToAnotherAuthPageLink>
						<Exist when={!isSaveMode}>
							{t("landing_signin_signup")}{" "}
							<Link to="/register">{t("landing.body.button_sign_up")}</Link>
						</Exist>
						<Exist when={isSaveMode}>
							{t("landing_signin_signup")}
							<PureButton type="button" onClick={toggleModal}>
								{t("landing.body.button_sign_up")}
							</PureButton>
						</Exist>
					</NavigateToAnotherAuthPageLink>
				</Form>
			</ModalContent>
		</ModalBackdrop>
	);
};
