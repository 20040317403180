import {ModalActions, ModalBackdrop, ModalCloseButton, ModalContent} from "./styled";
import React, {useCallback, useEffect, useState} from "react";
import {IconRemove} from "components/Icons";
import styled from "styled-components";
import {Input, PrimaryButton, SecondaryButton} from "components/styled";
import {useDispatch, useSelector} from "react-redux";
import {saveTeam, storeTeamName, toggleEditTeamNameModal} from "modules/actions";
import {
	getIsEditTeamModalOpen,
	getIsSavingMode,
	getIsTeamChecked,
	getIsUserHasTeam,
	getIsUserLogged,
	getTeam,
} from "modules/selectors";
import {media} from "assets/media";
import {useTranslation} from "react-i18next";

const InnerWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;

	h2 {
		color: ${({theme}) => theme.secondary};
		font-size: 20px;
		font-weight: bold;
		letter-spacing: 0;
		line-height: 24px;
		margin: 0 0 11px 0;
	}

	p {
		color: ${({theme}) => theme.secondary};
		font-size: 16px;
		letter-spacing: 0;
		line-height: 24px;
		margin: 0 0 24px 0;
	}
`;

const FieldWrapper = styled.div`
	max-width: 300px;
	width: 100%;

	> div {
		width: 100%;
	}

	@media screen and (max-width: ${media.md}) {
		max-width: unset;
	}
`;

export const NewTeamNameModal: React.FC = () => {
	const dispatch = useDispatch();
	const {t} = useTranslation();
	const team = useSelector(getTeam);
	const isUserHasTeam = useSelector(getIsUserHasTeam);
	const isSavingMode = useSelector(getIsSavingMode);
	const isTeamChecked = useSelector(getIsTeamChecked);
	const [teamName, setTeamName] = useState(team?.name || "");
	const isModalOpen = useSelector(getIsEditTeamModalOpen);
	const isLogged = useSelector(getIsUserLogged);

	const updateTeamName = (e: React.ChangeEvent<HTMLInputElement>) => {
		setTeamName(e.currentTarget.value);
	};

	const confirmTeamName = useCallback(() => {
		dispatch(storeTeamName(teamName));
		dispatch(toggleEditTeamNameModal(false));

		const loggedNonTeamName = isUserHasTeam && isLogged;
		const registerNonTeamName = isSavingMode && !isUserHasTeam;

		if (loggedNonTeamName || registerNonTeamName) {
			dispatch(saveTeam());
		}
	}, [isSavingMode, isUserHasTeam, teamName, isLogged, dispatch]);

	useEffect(() => {
		if (team.name) {
			dispatch(toggleEditTeamNameModal(false));
			setTeamName(team.name);
		}
	}, [dispatch, team]);

	const closeModal = useCallback(() => {
		dispatch(toggleEditTeamNameModal(false));
		setTeamName("");

		if (isSavingMode && !isUserHasTeam) {
			dispatch(storeTeamName(`Besta Deildin Fantasy ${team.id || 1}`));
			dispatch(saveTeam());
		}
	}, [dispatch, isUserHasTeam, isSavingMode, team?.id]);

	if (!isModalOpen || !isTeamChecked) {
		return null;
	}

	return (
		<ModalBackdrop className="fade">
			<ModalContent>
				<ModalCloseButton onClick={closeModal}>
					<IconRemove />
				</ModalCloseButton>
				<InnerWrapper>
					<h2>{t("team_name.modal.title")}</h2>
					<p>
						{t("team_name.modal.sub_text")}
					</p>

					<FieldWrapper>
						<Input
							name="name"
							label={t("team.name.enter")}
							value={teamName}
							onChange={updateTeamName}
						/>
					</FieldWrapper>
				</InnerWrapper>
				<ModalActions>
					<SecondaryButton onClick={closeModal}>{t("team_name.modal.button_later")}</SecondaryButton>
					<PrimaryButton disabled={!teamName} onClick={confirmTeamName}>
						{t("team_name.modal.button_confirm")}
					</PrimaryButton>
				</ModalActions>
			</ModalContent>
		</ModalBackdrop>
	);
};
