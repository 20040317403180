import {createSelector} from "reselect";
import {MatchStatus, RoundStatus} from "modules/types/enums";
import {getMatchesArray, getRounds, getTeam} from "modules/selectors";
import {filter, find} from "lodash";

export const getIsTeamLockout = createSelector(getTeam, getRounds, (team, rounds) => {
	if (!("startRound" in team)) {
		return false;
	}

	const startRound = rounds.find((round) => round.id === team.startRound);
	return startRound ? startRound.status !== RoundStatus.Scheduled : false;
});

const twoHours = 1000 * 60 * 60 * 2;
export const getTradesDeadlineTime = createSelector(getMatchesArray, (matches) => {
	const nonCompleteMatches = filter(matches, (match) => match.status !== MatchStatus.Complete);
	const firstMatch = nonCompleteMatches[0];

	if (!firstMatch) {
		return;
	}

	const firstMatchStartDate = new Date(firstMatch.date).valueOf();

	if (firstMatch.status === MatchStatus.Scheduled) {
		return firstMatchStartDate;
	}

	const nextDeadlineMatch = find(nonCompleteMatches, (match, index, non_complete_matches) => {
		const previousMatch = non_complete_matches[index - 1];
		if (!previousMatch) {
			return false;
		}
		return new Date(match.date).valueOf() >= new Date(previousMatch.date).valueOf() + twoHours;
	});

	if (!nextDeadlineMatch) {
		return;
	}

	return new Date(nextDeadlineMatch.date).valueOf();
});
