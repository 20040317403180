import React from "react";
import {useSelector} from "react-redux";
import classicHeaderLogo from "assets/images/ford_logo.svg";
import gamedayHeaderLogo from "assets/images/GamedayProjectLogo.png";
import {getProject} from "modules/selectors";
import {Project} from "modules/types/sport";
import {isGameDayGame} from "modules/utils";

interface IHeaderLogoImage {
	showOpposite?: boolean;
}

export const currentLogo = (currentProject: Project, showOpposite: boolean) => {
	let projectToUse = currentProject;
	if (showOpposite) {
		projectToUse = isGameDayGame(currentProject) ? Project.Classic : Project.Gameday;
	}
	return isGameDayGame(projectToUse) ? gamedayHeaderLogo : classicHeaderLogo;
};

export const HeaderLogoImage: React.FC<IHeaderLogoImage> = ({showOpposite = false}) => (
	<img src={currentLogo(useSelector(getProject), showOpposite)} alt="logo" />
);
