import {createAction} from "redux-act";
import {Project, Sport} from "modules/types";
import {INotification} from "modules/types/reducers";
import {IStatsCentreTeam} from "modules/types/shared";
import {Theme} from "@mui/material";

export const setProject = createAction<Project>();
export const setSport = createAction<Sport>();
export const setTheme = createAction<Theme>();
export const setLanguage = createAction<string>();
export const showNotification = createAction<INotification>();
export const hideNotification = createAction();

export const fetchAllTeamsStatsRequest = createAction();
export const fetchAllTeamsStatsSuccess = createAction<Record<number, IStatsCentreTeam>>();
