import React from "react";
import styled from "styled-components";
import {PlayersPoolCell, PlayersPoolThead} from "./styled";
import {PlayerPollCell} from "components/Team/PlayerPoll/PlayerPollCell";
import {ISortBy} from "modules/types/team";
import {useTranslation} from "react-i18next";

const FirstHeadCell = styled(PlayersPoolCell)`
	@media (max-width: 1000px) {
		justify-content: flex-start;
		padding-left: 20px;
	}
`;

interface IProps {
	playersLength: number;
	sortBy: ISortBy;
	updateSortOnCLick: (e: React.SyntheticEvent<HTMLElement>) => void;
}

export const PlayerPollHeader: React.FC<IProps> = ({playersLength, sortBy, updateSortOnCLick}) => {
	const hasNoPlayers = playersLength === 0;
	const {t} = useTranslation();

	if (hasNoPlayers) {
		return <EmptyMessage>No players...</EmptyMessage>;
	}

	return (
		<PlayersPoolThead>
			<FirstHeadCell className={"max-width center-align header"}>
				{t("join_leagues.table.h_players")}
			</FirstHeadCell>
			<PlayersPoolCell className="header">{t("stats_centre.tabs.teams")}</PlayersPoolCell>
			<PlayerPollCell
				sortBy={sortBy}
				sortProperty={"cost"}
				title={"ISK"}
				updateSortOnCLick={updateSortOnCLick}
				tooltipId={"player-pool-tooltip-value"}
				tooltip={"Player Value"}
			/>
			<PlayerPollCell
				sortBy={sortBy}
				sortProperty={"stats.total_points"}
				title={"Pts"}
				updateSortOnCLick={updateSortOnCLick}
				tooltipId={"player-pool-tooltip-points"}
				tooltip={"Total Points"}
			/>
			<PlayerPollCell
				sortBy={sortBy}
				sortProperty={"stats.picked_by"}
				title={"Pk %"}
				updateSortOnCLick={updateSortOnCLick}
				tooltipId={"player-pool-tooltip-picked"}
				tooltip={t("team.player_pool.th_pick")}
			/>
			<PlayersPoolCell>&nbsp;</PlayersPoolCell>
		</PlayersPoolThead>
	);
};

const EmptyMessage = styled.p`
	padding: 10px;
	font-size: 14px;
	line-height: 17px;
`;
