import React, {useCallback} from "react";
import {StepTitle, StepWrapper} from "./styled";
import {useMediaQuery} from "@mui/material";
import {media} from "assets/media";
import styled from "styled-components";
import {SecondaryButton} from "components/styled";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getLastCreatedLeague, getProject, getSport} from "modules/selectors";
import useClipboard from "react-use-clipboard";
import {Exist} from "components/Exist";
import {SocialSharing} from "components/SocialSharing";
import {InviteForm} from "components/Leagues/InviteForm";
import {createLeagueClear, createLeagueClearStep} from "modules/actions";
import {useTranslation} from "react-i18next";

const InnerWrapper = styled.div`
	h3 {
		color: ${({theme}) => theme.text_color};
		font-size: 16px;
		letter-spacing: 0;
		line-height: 19px;
		font-weight: normal;
		margin: 16px 0;
	}

	p {
		color: ${({theme}) => theme.text_color};
		font-size: 14px;
		letter-spacing: 0;
		line-height: 17px;
		margin-bottom: 16px;
	}
`;

const CopyButton = styled(SecondaryButton)`
	max-width: 220px;
	width: 100%;
	-webkit-border-radius: 6px;
	-moz-border-radius: 6px;
	border-radius: 6px;

	&:disabled {
		background: transparent !important;
		opacity: 0.6;
	}

	@media screen and ${media.lessThanMD} {
		width: 100%;
		max-width: unset;
	}
`;

const SkipLink = styled(Link)`
	display: block;
	color: #f02f42;
	font-size: 14px;
	letter-spacing: 0;
	line-height: 17px;
	text-decoration: none;
	margin-top: 38px;

	&:hover,
	&:active,
	&:focus {
		text-decoration: underline;
	}
`;

interface IProps {
	isDisabled: boolean;
}

export const LeagueCreateStepTwo: React.FC<IProps> = ({isDisabled}) => {
	const dispatch = useDispatch();
	const {t} = useTranslation();
	const project = useSelector(getProject);
	const sport = useSelector(getSport);
	const isMobile = useMediaQuery(media.lessThanMD);
	const league = useSelector(getLastCreatedLeague);
	const mobileClass = isMobile ? "mobile" : "";
	const disabledClass = isDisabled ? "disabled" : "";

	const [isCopied, setCopied] = useClipboard(String(league?.code || ""), {
		successDuration: 3000,
	});

	const clearCreated = useCallback(() => {
		dispatch(createLeagueClearStep());
		dispatch(createLeagueClear());
	}, [dispatch]);

	const skipLink = `/${project}/${sport}/leagues`;
	return (
		<StepWrapper className={`${disabledClass} ${mobileClass}`}>
			<StepTitle>
				<p>{t("create_league.form_step_one.title")}</p>
				<div />
			</StepTitle>
			<InnerWrapper>
				<h3>{t("create_league.form_step_two.via_code")}</h3>
				<p>
					{t("create_league.form_step_two.text", {"League Name": league?.name, "PIN": league?.code})}
				</p>
				<CopyButton disabled={isCopied} onClick={setCopied}>
					{isCopied ? t("leagues.create.copy_code") : t("create_league.form_step_two.button_copy")}
				</CopyButton>

				<h3>{t("leagues.body.subtitle_email")}</h3>
				<InviteForm leagueId={league?.id} />

				<div>
					<h3>{t("create_league.form_step_two.via_social")}</h3>
					<Exist when={Boolean(league?.id)}>
						<SocialSharing leagueId={league?.id || 0} />
					</Exist>
				</div>

				<SkipLink onClick={clearCreated} to={skipLink}>
					{t("create_league.form_step_two.skip")}
				</SkipLink>
			</InnerWrapper>
		</StepWrapper>
	);
};
