import {SPORT_MAP} from "modules/constants";
import {IStore} from "modules/types";
import {createSelector} from "reselect";
import {memoize} from "lodash";

const getSharedData = ({shared}: IStore) => shared;

export const getSport = createSelector(getSharedData, (state) => state.sport);
export const getProject = createSelector(getSharedData, (state) => state.project);
export const getTheme = createSelector(getSharedData, (state) => state.appTheme);
export const getRouteSport = createSelector(getSharedData, (state) => SPORT_MAP[state.sport]);
export const getLanguage = createSelector(getSharedData, (state) => state.language);
export const getNotificationState = createSelector(getSharedData, (state) => state.notification);
export const getTeamStats = createSelector(getSharedData, (state) => state.teamsStats);

export const getTeamStatById = createSelector(getSharedData, (state) =>
	memoize((id: number = 0) => state.teamsStats[id])
);
