import React from "react";
import styled from "styled-components";
import {useSelector} from "react-redux";
import {every, get} from "lodash";
import {
	getIsTeamLockout,
	getLastCompleteRound,
	getPlayerById,
	getTeamHeads,
	isPlayerPlaying,
	isSelectedRoundActive,
} from "modules/selectors";
import {PlayerStatus} from "modules/types/enums";
import {media} from "assets/media";

const PlayerDataPart = styled.div`
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex: 1;
	font-weight: bold;

	&.white {
		background: #fff;
	}

	&.grey {
		background: #ddd;
	}

	&.thin {
		font-weight: normal;
	}

	&.active {
		background: #0bad0b;
		color: #ffffff;
	}

	&.injured {
		background: #a82727;
		color: #ffffff;
	}

	@media screen and ${media.lessThanMD} {
		font-size: 8px;
	}
`;

interface IProps {
	playerId?: number;
	roundId: number;
}

const getStatOrDash = (stats?: number, isCaptain?: boolean): string => {
	if (!stats || isNaN(stats)) {
		return "-";
	}

	return String(isCaptain ? stats * 2 : stats);
};

export const TableTeamStats: React.FC<IProps> = ({playerId, roundId}) => {
	const player = useSelector(getPlayerById)(playerId);
	const isPlaying = useSelector(isPlayerPlaying)(playerId);
	const teamHeads = useSelector(getTeamHeads);
	const isLockout = useSelector(getIsTeamLockout);
	const lastCompleteRound = useSelector(getLastCompleteRound);
	const isRoundActive = useSelector(isSelectedRoundActive);
	const isLiveScore = every([isPlaying, isRoundActive]);
	const injuredClass = player?.status === PlayerStatus.Injured ? "injured" : "";
	const isCaptain = playerId === teamHeads.captain;
	const isOverall = !roundId;
	const lastCompleteRoundId = get(lastCompleteRound, "id", 0);
	const overallPoints = player.stats.round_scores?.[lastCompleteRoundId];
	const roundPoints = player.stats.round_scores?.[roundId];
	const points = isOverall && lastCompleteRoundId ? overallPoints : roundPoints;

	if (!player) {
		return null;
	}

	if (!isLockout) {
		return null;
	}

	if (isLiveScore) {
		return (
			<PlayerDataPart className={`white thin active ${injuredClass}`}>
				{getStatOrDash(points, isCaptain)}
			</PlayerDataPart>
		);
	}

	return <PlayerDataPart className="grey">{getStatOrDash(points, isCaptain)}</PlayerDataPart>;
};
