import React, {forwardRef, useCallback} from "react";
import styled from "styled-components";
import {FieldWrapper} from "components/styled";
import {FormationPicker} from "components/Team/FormationPicker";
import {Legend} from "components/Team/Legend";
import {PlayingField, FieldRow} from "components/Team/styled";
import {get, range} from "lodash";
import {AdLine, CircularPreloaded, Exist, Player} from "components";
import {useDispatch, useSelector} from "react-redux";
import {
	getShownStats,
	getTeam,
	getProject,
	getIsLoadingTeamHistory,
	getIsSelectedRoundNoActive,
} from "modules/selectors";
import {getIsTeamLockout} from "modules/selectors/lockout";
import {useNavigate, useParams} from "react-router-dom";
import {removePlayerFromTeam, tradeOutPlayer} from "modules/actions";
import field_classic_bg from "assets/images/field_classic_bg.png";
import field_classic_bg_mobile from "assets/images/field_classic_bg_mobile.png";
import field_gameday_bg from "assets/images/field_gameday_bg.png";
import field_gameday_bg_mobile from "assets/images/field_gameday_bg_mobile.png";
import {isGameDayGame} from "modules/utils";

const Wrapper = styled.div`
	position: relative;
`;

export const LegendContainer = styled.div`
	width: 100%;
	position: absolute;
	bottom: 18px;
	display: flex;
	justify-content: center;

	@media (max-width: 560px) {
		bottom: 16px;
	}
`;

const PreloaderWrapper = styled.div`
	padding: 100px 20px;
	display: flex;
	justify-content: center;
	align-items: center;
`;

interface IProps {
	onClickEmptyCell?: (position: number) => void;
	isPublicTeam?: boolean;
}

const ComponentTeamField = forwardRef<HTMLDivElement, IProps>(
	({onClickEmptyCell, isPublicTeam = false, ...props}, ref) => {
		const dispatch = useDispatch();
		const navigate = useNavigate();
		const params = useParams();
		const id = params.id;
		const team = useSelector(getTeam);
		const isLockout = useSelector(getIsTeamLockout);
		const {lineup, formation} = team;
		const isRemoveDisabled = isLockout;
		const isTransferOutDisabled = !isLockout;
		const formationClass = formation.includes("5") ? "wide" : "";
		const shownStats = useSelector(getShownStats);
		const isLoading = useSelector(getIsLoadingTeamHistory);
		const isSelectedRoundNoActive = useSelector(getIsSelectedRoundNoActive);
		const isShowActions = !(isSelectedRoundNoActive || isPublicTeam);

		const currentProject = useSelector(getProject);
		const fieldBG = isGameDayGame(currentProject) ? field_gameday_bg : field_classic_bg;
		const fieldBGMob = isGameDayGame(currentProject)
			? field_gameday_bg_mobile
			: field_classic_bg_mobile;
		const onClickEmptyPosition = useCallback(
			(position: number) => () => {
				onClickEmptyCell?.(position);
			},
			[onClickEmptyCell]
		);

		const onClickTransfer = useCallback(
			(playerID: number) => {
				if (id) {
					dispatch(removePlayerFromTeam(playerID));
					dispatch(tradeOutPlayer(playerID));
					return;
				}
				navigate(`transfers/${playerID}`);
			},
			[navigate, dispatch, id]
		);

		const players = range(1, 5).map((row_index) => (
			<FieldRow key={row_index} className="field-row">
				{get<number[], number[]>(lineup, row_index, []).map((playerID, cell_index) => (
					<Player
						isRemoveDisabled={isRemoveDisabled}
						isTransferDisabled={isTransferOutDisabled}
						onClickEmptyCell={onClickEmptyPosition(row_index)}
						onClickTransfer={onClickTransfer}
						key={cell_index}
						playerID={playerID}
						showStats={shownStats}
						isPublicTeam={isPublicTeam}
					/>
				))}
			</FieldRow>
		));

		if (isLoading) {
			return (
				<PreloaderWrapper>
					<CircularPreloaded size={80} />
				</PreloaderWrapper>
			);
		}

		return (
			<React.Fragment>
				<Wrapper ref={ref} {...props}>
					<FieldWrapper>
						<AdLine className={"top"} />
						<PlayingField
							fieldBG={fieldBG}
							fieldBGMob={fieldBGMob}
							className={formationClass}>
							<Exist when={isShowActions}>
								<FormationPicker activeFormation={formation} />
							</Exist>

							{players}

							<Exist when={isShowActions}>
								<LegendContainer>
									<Legend />
								</LegendContainer>
							</Exist>
						</PlayingField>
						<AdLine className={"bottom"} />
					</FieldWrapper>
				</Wrapper>
			</React.Fragment>
		);
	}
);

ComponentTeamField.displayName = "TeamField";

export const TeamField = ComponentTeamField;
