import React, {useEffect} from "react";
import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import {
	getFreeTradesCount,
	getIsTeamLockout,
	getIsUserLogged,
	getIsUserRecovered,
	getPointCost,
	getProject,
	getSelectedRoundId,
	getTeamGameBar,
	getTeamRemainingSalary,
	getWeeklyTransfersMade,
} from "modules/selectors";
import {SALARY_CUP} from "modules/constants";
import {fetchGameBarRequest} from "modules/actions";
import {useIsPathIncludes} from "modules/utils/hooks";
import {TeamCaptainSelect} from "components/Team/TeamCaptainSelect";
import {useMediaQuery} from "@mui/material";
import {Exist} from "components/Exist";
import {media} from "assets/media";
import {currencyFormatDecimal, isGameDayGame} from "modules/utils";
import {useTranslation} from "react-i18next";

const Wrapper = styled.div`
	display: flex;
	justify-content: flex-start;
	padding: 10px 10px 15px 10px;
	border-radius: 0 0 5px 5px;
	border: 1px solid #dddddd;
	border-top: 0;
`;

export const TeamStats: React.FC = () => {
	const isCaptainsMobile = useMediaQuery("(max-width: 900px)");
	const dispatch = useDispatch();
	const {t} = useTranslation();
	const isLogged = useSelector(getIsUserLogged);
	const isUserRecovered = useSelector(getIsUserRecovered);
	const selectedRound = useSelector(getSelectedRoundId);
	const remainBudgetValue = useSelector(getTeamRemainingSalary);
	const remainBudget = currencyFormatDecimal(remainBudgetValue, true);
	const teamValue = currencyFormatDecimal(SALARY_CUP - remainBudgetValue, true);
	const pointsCost = useSelector(getPointCost);
	const freeTrades = useSelector(getFreeTradesCount);
	const weeklyTransfers = useSelector(getWeeklyTransfersMade);
	const gameBar = useSelector(getTeamGameBar);

	const isPreseasonFlag = !useSelector(getIsTeamLockout);
	const isTransferFlag = useIsPathIncludes("transfers");
	const currentProject = useSelector(getProject);

	useEffect(() => {
		if (selectedRound && isLogged && isUserRecovered && !isTransferFlag) {
			dispatch(fetchGameBarRequest({round: selectedRound}));
		}
	}, [isLogged, selectedRound, isUserRecovered, isTransferFlag, dispatch]);

	if (isGameDayGame(currentProject)) {
		return (
			<Wrapper>
				<StatsBlock title={t("team.summary_bar.points")} value={gameBar.round_points} />
				<StatsBlock title={t("team.summary_bar.position")} value={gameBar.round_rank} />
			</Wrapper>
		);
	}

	if (isPreseasonFlag) {
		return (
			<Wrapper>
				<StatsBlock title={t("team.summary_bar.value")} value={`ISK${teamValue}`} />
				<StatsBlock
					title={t("team.summary.remaining_budget")}
					value={`ISK${remainBudget}`}
				/>
				<Exist when={!isCaptainsMobile}>
					<TeamCaptainSelect />
				</Exist>
			</Wrapper>
		);
	}

	if (isTransferFlag) {
		return (
			<Wrapper>
				<StatsBlock
					title={t("team.summary_bar.transfers")}
					value={`${weeklyTransfers}/${String(freeTrades || 0)}`}
				/>
				<StatsBlock
					title={t("team.summary.point_cost")}
					value={String(pointsCost || t("team.summary.free"))}
					className={pointsCost ? "point-cost-negative" : "point-cost-free"}
				/>
				<StatsBlock title={t("transfer.modal.budget")} value={`ISK${remainBudget}`} />
			</Wrapper>
		);
	}

	return (
		<Wrapper>
			<StatsBlock title={t("my_team.summary.round_points")} value={gameBar.round_points} />
			<StatsBlock
				title={t("my_team.summary.overall_points")}
				value={gameBar.overall_points}
			/>
			<StatsBlock title={t("my_team.summary.month_points")} value={gameBar.round_rank} />
			<StatsBlock
				title={t("my_team.summary.overall_position")}
				value={gameBar.overall_rank}
			/>
			<Exist when={!isCaptainsMobile}>
				<TeamCaptainSelect />
			</Exist>
		</Wrapper>
	);
};

const StatsWrapper = styled.div`
	border-right: 1px solid #dddddd;
	padding-left: 9px;
	padding-right: 30px;

	&:last-of-type {
		border-right: none;
		padding-right: 0;
	}

	h4 {
		color: #222222;
		font-size: 14px;
		letter-spacing: 0;
		line-height: 17px;
		font-weight: normal;
		margin: 0 0 4px 0;
	}

	p {
		color: #222222;
		font-size: 16px;
		font-weight: bold;
		letter-spacing: 0;
		line-height: 19px;
		margin: 0;
	}

	&.point-cost-free p {
		color: #0000b8;
	}

	&.point-cost-negative p {
		color: #ff0000;
	}

	@media screen and ${media.lessThanSM} {
		padding-right: 24px;
		h4 {
			font-size: 12px;
		}

		p {
			font-size: 14px;
		}
	}
`;

interface IStatsProps {
	title: string;
	value: string | number;
	className?: string;
}

const StatsBlock: React.FC<IStatsProps> = ({title, value, className}) => {
	return (
		<StatsWrapper className={className}>
			<h4>{title}</h4>
			<p>{value}</p>
		</StatsWrapper>
	);
};
