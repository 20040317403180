import React, {useCallback, useMemo, useState} from "react";
import styled, {css} from "styled-components";
import {FORMATIONS, IFormation} from "modules/utils/Team";
import {drop, size, values} from "lodash";
import {PureButton} from "components/styled";
import {changeFormation} from "modules/actions";
import {useDispatch} from "react-redux";
import {media} from "assets/media";
import {useTranslation} from "react-i18next";

interface IWrapperProps {
	isOpen: boolean;
}

const Wrapper = styled.div<IWrapperProps>`
	position: absolute;
	right: 21px;
	top: 22px;
	z-index: ${(props) => (props.isOpen ? 10 : 1)};

	@media (max-width: 700px) {
		top: 13px;
		right: 13px;
	}
`;

interface IFormationItemProps extends IWrapperProps {
	isActive: boolean;
	index: number;
}

const getFormationItemStyle = ({isActive}: IFormationItemProps) =>
	isActive
		? css`
				background: #f7f7f7;
				z-index: 10;
		  `
		: css`
				background: #f7f7f7;
		  `;

const FormationItem = styled.div<IFormationItemProps>`
	display: inline-flex;
	flex-flow: column;
	align-items: center;
	justify-content: center;
	width: 88px;
	height: 98px;
	color: ${({theme}) => theme.secondary};
	font-size: 10px;
	margin-left: 1px;
	cursor: pointer;
	transition: all 0.25s;
	/* (FormationItem width) + (FormationItem margin) * index */
	transform: translateX(${({index, isOpen}) => (isOpen ? 0 : index * 89)}px);
	user-select: none;
	position: relative;
	${getFormationItemStyle};

	&:hover {
		background: #d8d8d8;
	}

	@media screen and (max-width: ${media.md}) {
		width: 50px;
		height: 78px;
		transform: translateX(${({index, isOpen}) => (isOpen ? 0 : index * 51)}px);
	}
`;

const WrapperInner = styled.div`
	position: relative;
	white-space: nowrap;
`;

const Title = styled.div`
	font-size: 12px;
	padding-bottom: 10px;
`;

const Dot = styled.div`
	background: ${({theme}) => theme.secondary};
	width: 8px;
	height: 8px;
	margin: 0 2px;
	border-radius: 100%;

	@media screen and (max-width: ${media.md}) {
		width: 4px;
		height: 4px;
	}
`;

const Line = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 1.5px 0;
`;

const Button = styled(PureButton)`
	background: ${({theme}) => theme.primary};
	position: absolute;
	width: 88px;
	height: 28px;
	right: 0;
	top: 100%;
	color: #ffffff;
	font-size: 12px;
	letter-spacing: 0;
	line-height: 15px;
	text-align: center;

	@media screen and (max-width: ${media.md}) {
		width: 50px;
		height: 23px;
		font-size: 11px;
	}
`;

const formationEntries = Array.from(FORMATIONS);
const formationsSize = size(formationEntries);

interface IFormationPickerProps {
	activeFormation: IFormation;
}

export const FormationPicker: React.FC<IFormationPickerProps> = ({activeFormation}) => {
	const dispatch = useDispatch();
	const [isOpen, setOpenState] = useState(false);
	const {t} = useTranslation();

	const toggleState = useCallback(() => {
		setOpenState(!isOpen);
	}, [isOpen]);

	const onChangeFormation = useCallback(
		(key: IFormation, isActive: boolean) => () => {
			if (isActive) {
				toggleState();
			} else {
				dispatch(changeFormation(key));
				setOpenState(false);
			}
		},
		[toggleState, dispatch]
	);

	const formationItems = useMemo(
		() =>
			formationEntries.map(([key, lineup], index) => {
				const isActive = key === activeFormation;
				const formationIndex = formationsSize - 1 - index;

				return (
					<FormationItem
						onClick={onChangeFormation(key, isActive)}
						isActive={isActive}
						isOpen={isOpen}
						index={formationIndex}
						key={key}>
						<Title>{drop(key, 2)}</Title>
						{values(lineup).map((line, lineIndex) => (
							<Line key={lineIndex}>
								{line.map((_, playerIndex) => (
									<Dot key={playerIndex} />
								))}
							</Line>
						))}
					</FormationItem>
				);
			}),
		[activeFormation, onChangeFormation, isOpen]
	);

	return (
		<Wrapper isOpen={isOpen}>
			<WrapperInner>
				{formationItems}
				<Button onClick={toggleState}>{t("team.pitch.formation_change")}</Button>
			</WrapperInner>
		</Wrapper>
	);
};
