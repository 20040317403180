import React from "react";
import {LandingHeader} from "components/Header/LandingHeader";
import {AppHeader} from "components/Header/AppHeader";
import styled from "styled-components";
import {useLocation} from "react-router-dom";
import {LANDING_PATH, NO_HEADER_PATHS} from "modules/constants";

const HeaderWrapper = styled.div`
	width: 100%;
	position: relative;
	z-index: 10;
`;

export const Header: React.FC = () => {
	const location = useLocation();
	const isLanding = location.pathname === LANDING_PATH;

	if (NO_HEADER_PATHS.includes(location.pathname)) {
		return null;
	}

	if (isLanding) {
		return (
			<HeaderWrapper>
				<LandingHeader />
			</HeaderWrapper>
		);
	}

	return (
		<HeaderWrapper>
			<AppHeader />
		</HeaderWrapper>
	);
};
