import {all, debounce, takeLatest} from "redux-saga/effects";
import * as actions from "modules/actions";
import {errorsManagerSaga} from "modules/sagas/errors";
import {
	contactUsSaga,
	requestPasswordChangeSaga,
	userAccountRecoverSaga,
	userFetchSaga,
	userLoginSaga,
	userLogoutSaga,
	userRegisterSaga,
	userResetPasswordSaga,
	userUpdatePasswordSaga,
	userUpdateSaga,
} from "modules/sagas/user";
import {fetchPlayersSaga} from "modules/sagas/player";
import {
	autoFillSaga,
	changeFormationSaga,
	fetchGameBarSaga,
	fetchLeaderboardSaga,
	getTeamByIdSaga,
	getTeamHistorySaga,
	getTeamSaga,
	makeTradeSaga,
	removePlayerFromTeamSaga,
	resetTradeByIndexSaga,
	saveTeamSaga,
	setPlayerToTeamSaga,
	showTeamNameModalSaga,
	transferPlayerToTeamSaga,
} from "modules/sagas/team";
import {
	fetchAllPlayersStatsSaga,
	fetchAllTeamsStatsSaga,
	fetchHelpsSaga,
	fetchPlayerStatsSaga,
	fetchRoundsDelayedSaga,
	fetchRoundsSaga,
	fetchSquadsSaga,
	fetchChecksumsSaga,
	fetchMessageBarSaga,
} from "modules/sagas/json";
import {
	createLeagueSaga,
	fetchJoinLeaguesSaga,
	fetchLeagueRanksSaga,
	fetchLeagueSaga,
	fetchLeagueTableSaga,
	fetchLeagueUsersSaga,
	joinLeagueSaga,
	LeagueRemoveUserSaga,
	leaveLeagueSaga,
	sendLeagueInviteSaga,
	updateLeagueSaga,
} from "modules/sagas/leagues";
import {fetchLiveScoresSaga} from "modules/sagas/liveScoring";
import {takeEvery} from "typed-redux-saga";

const user = [
	takeLatest(actions.fetchUser, userFetchSaga),
	takeLatest(actions.userRegister, userRegisterSaga),
	takeLatest(actions.userLogin, userLoginSaga),
	takeLatest(actions.userLogoutAndClear, userLogoutSaga),
	takeLatest(actions.userUpdateRequest, userUpdateSaga),
	takeLatest(actions.userChangePasswordRequest, userUpdatePasswordSaga),
	takeLatest(actions.requestPasswordChange, requestPasswordChangeSaga),
	takeLatest(actions.userResetPasswordRequest, userResetPasswordSaga),
	takeLatest(actions.contactUsRequest, contactUsSaga),
	takeLatest(actions.userAccountRecover, userAccountRecoverSaga),
];

const team = [
	takeLatest(actions.fetchTeam, getTeamSaga),
	takeLatest(actions.fetchTeamHistory, getTeamHistorySaga),
	takeLatest(actions.changeFormation, changeFormationSaga),
	takeLatest(actions.pickPlayerToTeam, setPlayerToTeamSaga),
	takeLatest(actions.removePlayerFromTeam, removePlayerFromTeamSaga),
	takeLatest(actions.saveTeam, saveTeamSaga),
	takeLatest(actions.autoFillRequest, autoFillSaga),
	takeLatest(actions.fetchLeaderboardRequest, fetchLeaderboardSaga),
	takeLatest(actions.showTeamNameModal, showTeamNameModalSaga),
	takeLatest(actions.showTeamNameModal, showTeamNameModalSaga),
	takeLatest(actions.fetchTeamById, getTeamByIdSaga),
];

const trades = [
	takeLatest(actions.tradeInPlayer, transferPlayerToTeamSaga),
	takeLatest(actions.resetTradeByIndex, resetTradeByIndexSaga),
	takeLatest(actions.makeTradeRequest, makeTradeSaga),
	takeLatest(actions.fetchGameBarRequest, fetchGameBarSaga),
];

const leagues = [
	takeLatest(actions.fetchLeaguesRequest, fetchLeagueSaga),
	takeLatest(actions.createLeagueRequest, createLeagueSaga),
	takeLatest(actions.updateLeagueRequest, updateLeagueSaga),
	takeLatest(actions.joinLeagueRequest, joinLeagueSaga),
	takeLatest(actions.leaveLeagueRequest, leaveLeagueSaga),
	takeLatest(actions.sendLeagueInviteRequest, sendLeagueInviteSaga),
	takeLatest(actions.fetchLeagueRanksRequest, fetchLeagueRanksSaga),
	takeLatest(actions.fetchLeagueUsersRequest, fetchLeagueUsersSaga),
	takeLatest(actions.leagueRemoveUserRequest, LeagueRemoveUserSaga),
	takeLatest(actions.fetchLeagueTableRequest, fetchLeagueTableSaga),
	debounce(500, actions.fetchJoinLeaguesRequest, fetchJoinLeaguesSaga),
];

const JSONS = [
	takeLatest(actions.fetchSquads, fetchSquadsSaga),
	takeLatest(actions.fetchPlayers, fetchPlayersSaga),
	takeLatest(actions.fetchRounds, fetchRoundsSaga),
	takeLatest(actions.fetchRoundsDelayed, fetchRoundsDelayedSaga),
	takeLatest(actions.fetchPlayerStatsRequest, fetchPlayerStatsSaga),
	takeEvery(actions.fetchHelpRequest, fetchHelpsSaga),
	takeEvery(actions.fetchAllPlayersStatsRequest, fetchAllPlayersStatsSaga),
	takeEvery(actions.fetchAllTeamsStatsRequest, fetchAllTeamsStatsSaga),
	// Live scoring
	takeLatest(actions.subscribeToLiveScores, fetchLiveScoresSaga),
	takeLatest(actions.fetchChecksums, fetchChecksumsSaga),
	takeLatest(actions.fetchMessageBar, fetchMessageBarSaga),
];

export const rootSaga = function* () {
	yield all([
		takeLatest(actions.globalError, errorsManagerSaga),
		...user,
		...team,
		...trades,
		...leagues,
		...JSONS,
	]);
};

export * from "./user";
export * from "./json";
export * from "./player";
