import {createReducer} from "redux-act";
import {
	handleAuthModal,
	handlePreAuthModal,
	storeTeamName,
	switchToTeamID,
	toggleEditTeamNameModal,
	toggleTeamSavedModal,
} from "modules/actions";
import {IAuthModal} from "modules/types";
import {EAuthModal} from "modules/types/enums";
import {IInfoModal, IRemovePlayerModalPayload} from "modules/types/modal";
import {
	closeAccountRecoverModal,
	closeInfoModal,
	closeLeagueLeaveModal,
	closePlayerInfoModal,
	closeRemovePlayerModal,
	closeTransferModal,
	closeWelcomeModal,
	openAccountRecoverModal,
	openInfoModal,
	openLeagueLeaveModal,
	openPlayerInfoModal,
	openRemovePlayerModal,
	openTransferModal,
	openWelcomeModal,
} from "modules/actions/modals";

const defaultState: IModalsReducer = {
	confirmTradeModal: false,
	showTeamNameModal: false,
	showAuthModal: false,
	authModalToShow: EAuthModal.REGISTRATION,
	preAuthModal: false,
	isShowTransferModal: false,
	isShowTeamSavedModal: false,
	isShowLeagueLeaveModal: false,
	isShowWelcomeModal: false,
	isShowAccountRecoverModal: false,
	infoModal: {},
	forgotPasswordModal: {
		isShow: false,
	},
	removeLeaguePlayerModal: {
		isShow: false,
		data: {},
	},
	playerInfoModal: {
		isShow: false,
		playerId: undefined,
	},
};

export interface IModalsReducer {
	confirmTradeModal: boolean;
	showTeamNameModal: boolean;
	showAuthModal: boolean;
	authModalToShow: EAuthModal;
	isShowTeamSavedModal: boolean;
	isShowTransferModal: boolean;
	isShowLeagueLeaveModal: boolean;
	isShowWelcomeModal: boolean;
	isShowAccountRecoverModal: boolean;
	preAuthModal: boolean;
	infoModal: IInfoModal;
	forgotPasswordModal: {
		isShow: boolean;
		error?: string;
		success?: boolean;
	};
	removeLeaguePlayerModal: {
		isShow: boolean;
		data: IRemovePlayerModalPayload;
	};
	playerInfoModal: {
		isShow: boolean;
		playerId?: number;
	};
}

const hideTeamNameModal = (state: IModalsReducer) => ({
	...state,
	showTeamNameModal: false,
});

const openInfoModalHandler = (state: IModalsReducer, payload: IInfoModal) => ({
	...state,
	infoModal: payload,
});

const closeInfoModalHandler = (state: IModalsReducer) => ({
	...state,
	infoModal: {},
});

const onOpenRemovePlayerModal = (
	state: IModalsReducer,
	payload: IRemovePlayerModalPayload
): IModalsReducer => ({
	...state,
	removeLeaguePlayerModal: {
		isShow: true,
		data: payload,
	},
});

const onCloseRemovePlayerModal = (state: IModalsReducer): IModalsReducer => ({
	...state,
	removeLeaguePlayerModal: {
		isShow: false,
		data: {},
	},
});

const onOpenTransferModal = (state: IModalsReducer): IModalsReducer => ({
	...state,
	isShowTransferModal: true,
});

const onCloseTransferModal = (state: IModalsReducer): IModalsReducer => ({
	...state,
	isShowTransferModal: false,
});

const onOpenPlayerInfoModal = (state: IModalsReducer, payload: number): IModalsReducer => ({
	...state,
	playerInfoModal: {
		isShow: true,
		playerId: payload,
	},
});

const onClosePlayerInfoModal = (state: IModalsReducer): IModalsReducer => ({
	...state,
	playerInfoModal: {
		isShow: false,
		playerId: undefined,
	},
});

const onOpenLeagueLeaveModal = (state: IModalsReducer): IModalsReducer => ({
	...state,
	isShowLeagueLeaveModal: true,
});

const onCloseLeagueLeaveModal = (state: IModalsReducer): IModalsReducer => ({
	...state,
	isShowLeagueLeaveModal: false,
});

const onOpenWelcomeModal = (state: IModalsReducer): IModalsReducer => ({
	...state,
	isShowWelcomeModal: true,
});

const onCloseWelcomeModal = (state: IModalsReducer): IModalsReducer => ({
	...state,
	isShowWelcomeModal: false,
});

const onOpenAccountRecoverModal = (state: IModalsReducer): IModalsReducer => ({
	...state,
	isShowAccountRecoverModal: true,
});

const onCloseAccountRecoverModal = (state: IModalsReducer): IModalsReducer => ({
	...state,
	isShowAccountRecoverModal: false,
});

export const modals = createReducer<IModalsReducer>({}, defaultState)
	.on(handleAuthModal, (state, payload: IAuthModal) => ({
		...state,
		...payload,
	}))
	.on(handlePreAuthModal, (state, payload: boolean) => ({
		...state,
		preAuthModal: payload,
	}))
	.on(toggleEditTeamNameModal, (state, showTeamNameModal) => ({
		...state,
		showTeamNameModal,
	}))
	.on(toggleTeamSavedModal, (state, payload) => ({
		...state,
		isShowTeamSavedModal: payload,
	}))
	.on(storeTeamName, hideTeamNameModal)
	.on(switchToTeamID, hideTeamNameModal)
	.on(openInfoModal, openInfoModalHandler)
	.on(closeInfoModal, closeInfoModalHandler)
	.on(openRemovePlayerModal, onOpenRemovePlayerModal)
	.on(closeRemovePlayerModal, onCloseRemovePlayerModal)
	.on(openPlayerInfoModal, onOpenPlayerInfoModal)
	.on(closePlayerInfoModal, onClosePlayerInfoModal)
	.on(openTransferModal, onOpenTransferModal)
	.on(closeTransferModal, onCloseTransferModal)
	.on(openLeagueLeaveModal, onOpenLeagueLeaveModal)
	.on(closeLeagueLeaveModal, onCloseLeagueLeaveModal)
	.on(openWelcomeModal, onOpenWelcomeModal)
	.on(closeWelcomeModal, onCloseWelcomeModal)
	.on(openAccountRecoverModal, onOpenAccountRecoverModal)
	.on(closeAccountRecoverModal, onCloseAccountRecoverModal);
