import React from "react";
import styled from "styled-components";
import {PureButton} from "components/styled";
import {useDispatch, useSelector} from "react-redux";
import {getLanguage} from "modules/selectors";
import {LANGUAGES} from "modules/constants";
import {useTranslation} from "react-i18next";
import {setLanguage} from "modules/actions";
import {ReactComponent as ArrowDown} from "assets/images/icons/chevron.svg";
import {HOCAccordion} from "components/HOCs";

const Wrapper = styled.div`
	position: relative;
	height: 100%;
	width: 70px;
	padding: 12px;
	color: #fff;
	cursor: pointer;

	&:hover > ul {
		display: block;
	}
`;

const CurrentLanguage = styled.p`
	height: 100%;
	display: flex;
	align-items: center;

	svg {
		transform: rotate(180deg);
	}
`;

const DropdownMenu = styled.ul`
	display: none;
	position: absolute;
	left: 0;
	top: 100%;
	width: 100%;
	background-color: ${({theme}) => theme.primary};
`;

const DropdownMenuItem = styled.li`
	width: 100%;
	height: 40px;

	button {
		height: 100%;
		width: 100%;
		color: #fff;
		font-size: 16px;
		transition: color 0.2s ease-in-out;

		&:hover {
			color: ${({theme}) => theme.tertiary};
		}
	}
`;

const CurrentLanguageMobile = styled.div`
	padding: 18px;
	flex: 1;
	display: flex;
	background: ${({theme}) => theme.header.menu.opened_item_color};
	color: #ffffff;
	text-decoration: none;
	border-bottom: 1px solid #ffffff;
	font-size: 18px;
	font-weight: bold;
	letter-spacing: 0;
	line-height: 20px;
`;

const DropdownMenuItemMobile = styled(PureButton)`
	padding: 18px;
	flex: 1;
	display: flex;
	background: ${({theme}) => theme.header.menu.opened_item_color};
	color: #ffffff;
	border-bottom: 1px solid #ffffff;
	padding-left: 36px;
	font-size: 16px;
	letter-spacing: 0;
	line-height: 18px;
	font-weight: normal;
	width: 100%;

	&:not(:last-of-type) {
		border-bottom: none;
	}
`;

interface IProps {
	isMobile?: boolean;
}

export const LanguageSelector: React.FC<IProps> = ({isMobile}) => {
	const dispatch = useDispatch();
	const {i18n} = useTranslation();
	const selectedLanguage = useSelector(getLanguage);

	const changeLanguage = (e: React.SyntheticEvent<HTMLButtonElement>) => {
		const language = e.currentTarget.dataset.language;
		if (language) {
			void i18n.changeLanguage(language);
			dispatch(setLanguage(language));
		}
	};

	if (isMobile) {
		return (
			<HOCAccordion
				item={
					<CurrentLanguageMobile>{selectedLanguage.toUpperCase()}</CurrentLanguageMobile>
				}>
				{LANGUAGES.map((language) => (
					<DropdownMenuItemMobile
						key={language.code}
						onClick={changeLanguage}
						data-language={language.code}>
						{language.code.toUpperCase()}
					</DropdownMenuItemMobile>
				))}
			</HOCAccordion>
		);
	}

	return (
		<Wrapper>
			<CurrentLanguage>
				{selectedLanguage.toUpperCase()}
				<ArrowDown />
			</CurrentLanguage>
			<DropdownMenu>
				{LANGUAGES.map((language) => (
					<DropdownMenuItem key={language.code}>
						<PureButton onClick={changeLanguage} data-language={language.code}>
							{language.code.toUpperCase()}
						</PureButton>
					</DropdownMenuItem>
				))}
			</DropdownMenu>
		</Wrapper>
	);
};
