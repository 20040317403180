import {ILineup, ITeam} from "modules/types/team";
import {FORMATIONS} from "modules/utils/Team";
import {createReducer} from "redux-act";
import {ITeamsReducer} from "modules/types/reducers";
import {
	autoFillClear,
	autoFillRequest,
	autoFillSuccess,
	changeFormationSuccess,
	clearHeads,
	clearTrades,
	editModalSavingMode,
	fetchGameBarSuccess,
	fetchLeaderboardConcat,
	fetchLeaderboardRequest,
	fetchLeaderboardSuccess,
	fetchTeamById,
	fetchTeamByIdFailed,
	fetchTeamByIdSuccess,
	fetchTeamHistory,
	fetchTeamHistoryFailed,
	fetchTeamHistorySuccess,
	makeTradeRequest,
	makeTradeSuccess,
	savedTeamSuccess,
	saveTeam,
	setActiveTabAction,
	setHeads,
	setLastTradeEmptyState,
	setTeamComplete,
	setTeamSaved,
	setUserHasTeam,
	storeTeamName,
	successResetTradeByIndex,
	teamFetchComplete,
	teamGlobalRequestStateHandler,
	toggleEditTeamNameModal,
	toggleNoHeadsModal,
	tradeInPlayer,
	tradeOutPlayer,
	updateLineup,
} from "modules/actions";
import {RequestStateType, StatsTab} from "modules/types/enums";
import {includes, without} from "lodash";

const defaultFormation = "1-4-4-2";
const defaultState: ITeamsReducer = {
	team: {
		name: "",
		formation: defaultFormation,
		lineup: FORMATIONS.get(defaultFormation) as ILineup,
	},
	publicTeam: {
		id: 0,
		name: "",
		full_name: "",
		overall_points: 0,
		overall_rank: 0,
		round_points: 0,
		value: 0,
		user_id: 0,
		formation: defaultFormation,
		lineup: FORMATIONS.get(defaultFormation) as ILineup,
	},
	tradesData: {
		trades: {},
		tradeIn: [],
		tradeOut: [],
		tradesSeasonLimit: 50,
		tradesWeeklyLimit: 5,
	},
	gameBar: {
		round_rank: null,
		overall_rank: null,
		round_points: null,
		overall_points: null,
	},
	isTeamComplete: false,
	isSavingMode: false,
	isTeamSaved: false,
	isTeamChecked: false,
	isUserHasTeam: false,
	leaderboardData: {
		next: false,
		ranks: [],
		user: null,
	},
	activeTab: StatsTab.Weekly,
	fillRequestState: RequestStateType.Idle,
	teamByIdRequestState: RequestStateType.Idle,
	teamHistoryRequestState: RequestStateType.Idle,
	requestState: {
		trade: RequestStateType.Idle,
		leaderboard: RequestStateType.Idle,
		saveTeam: RequestStateType.Idle,
	},
	modalsState: {
		editTeamModal: false,
		headsModal: false,
	},
	captain: 0,
	viceCaptain: 0,
	isLastTradeMakeEmpty: false,
};

export const teams = createReducer<ITeamsReducer>({}, defaultState)
	.on(changeFormationSuccess, (state, team) => ({
		...state,
		isTeamSaved: false,
		team,
	}))
	.on(updateLineup, (state, payload) => ({
		...state,
		isTeamSaved: false,
		team: {
			...state.team,
			lineup: payload,
		},
	}))
	.on(savedTeamSuccess, (state, payload) => ({
		...state,
		team: payload,
		isTeamSaved: true,
		isTeamComplete: true,
	}))
	.on(setUserHasTeam, (state, payload) => ({
		...state,
		isUserHasTeam: payload,
	}))
	.on(teamFetchComplete, (state) => ({
		...state,
		isTeamChecked: true,
		requestState: {...state.requestState, saveTeam: RequestStateType.Idle},
		teamHistoryRequestState: RequestStateType.Success,
	}))
	.on(toggleEditTeamNameModal, (state, payload) => ({
		...state,
		modalsState: {
			...state.modalsState,
			editTeamModal: payload,
		},
	}))
	.on(toggleNoHeadsModal, (state, payload) => ({
		...state,
		modalsState: {
			...state.modalsState,
			headsModal: payload,
		},
	}))
	.on(editModalSavingMode, (state, payload) => ({
		...state,
		isSavingMode: true,
	}))
	.on(storeTeamName, (state, payload) => ({
		...state,
		isTeamSaved: false,
		team: {
			...state.team,
			name: payload,
		},
	}))
	.on(autoFillRequest, (state) => ({
		...state,
		fillRequestState: RequestStateType.Loading,
	}))
	.on(saveTeam, (state) => ({
		...state,
		requestState: {...state.requestState, saveTeam: RequestStateType.Loading},
	}))
	.on(autoFillSuccess, (state, payload) => ({
		...state,
		isTeamSaved: false,
		fillRequestState: RequestStateType.Success,
		team: {
			...state.team,
			lineup: payload.lineup,
		},
	}))
	.on(autoFillClear, (state) => ({
		...state,
		fillRequestState: RequestStateType.Idle,
	}))
	.on(tradeInPlayer, (state, playerID) => {
		// Remove trade as the trade in and trade out players are the same
		if (includes(state.tradesData.tradeOut, playerID)) {
			return {
				...state,
				tradesData: {
					...state.tradesData,
					tradeOut: without(state.tradesData.tradeOut, playerID),
				},
			};
		}

		return {
			...state,
			tradesData: {
				...state.tradesData,
				tradeIn: [...state.tradesData.tradeIn, playerID],
			},
		};
	})
	.on(tradeOutPlayer, (state, playerID) => {
		// Remove trade as the trade in and trade out players are the same
		if (includes(state.tradesData.tradeIn, playerID)) {
			return {
				...state,
				tradesData: {
					...state.tradesData,
					tradeIn: without(state.tradesData.tradeIn, playerID),
				},
			};
		}
		return {
			...state,
			tradesData: {
				...state.tradesData,
				tradeOut: [...state.tradesData.tradeOut, playerID],
			},
		};
	})
	.on(successResetTradeByIndex, (state, {formation, lineup, tradeOut, tradeIn = 0}) => ({
		...state,
		team: {
			...state.team,
			lineup,
			formation,
		},
		tradesData: {
			...state.tradesData,
			tradeIn: without(state.tradesData.tradeIn, tradeIn),
			tradeOut: without(state.tradesData.tradeOut, tradeOut),
		},
	}))
	.on(clearTrades, (state) => ({
		...state,
		tradesData: {
			...state.tradesData,
			trades: {},
			tradeIn: [],
			tradeOut: [],
		},
	}))
	.on(makeTradeRequest, (state) => ({
		...state,
		requestState: {...state.requestState, trade: RequestStateType.Loading},
	}))
	.on(makeTradeSuccess, (state, payload: ITeam) => ({
		...state,
		team: payload,
		isTeamSaved: true,
		requestState: {...state.requestState, trade: RequestStateType.Success},
	}))
	.on(teamGlobalRequestStateHandler, (state, payload) => ({
		...state,
		requestState: {...state.requestState, [payload.key]: payload.state},
	}))
	.on(fetchGameBarSuccess, (state, payload) => ({
		...state,
		gameBar: payload,
	}))
	.on(setHeads, (state, payload) => ({
		...state,
		captain: payload.captain,
		viceCaptain: payload.viceCaptain,
	}))
	.on(clearHeads, (state) => ({
		...state,
		captain: undefined,
		viceCaptain: undefined,
		team: {
			...state.team,
			lineup: {
				...state.team.lineup,
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				captain: undefined,
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				vice_captain: undefined,
			},
		},
	}))
	.on(setActiveTabAction, (state, payload) => ({
		...state,
		activeTab: payload,
	}))
	.on(fetchLeaderboardRequest, (state) => ({
		...state,
		requestState: {...state.requestState, leaderboard: RequestStateType.Loading},
	}))
	.on(fetchLeaderboardSuccess, (state, payload) => ({
		...state,
		leaderboardData: {
			next: payload.next,
			ranks: payload.payload.rankings,
			user: payload.payload.user,
		},
		requestState: {...state.requestState, leaderboard: RequestStateType.Success},
	}))
	.on(fetchLeaderboardConcat, (state, payload) => ({
		...state,
		leaderboardData: {
			next: payload.next,
			ranks: [...state.leaderboardData.ranks, ...payload.payload.rankings],
			user: payload.payload.user,
		},
		requestState: {...state.requestState, leaderboard: RequestStateType.Success},
	}))
	.on(setLastTradeEmptyState, (state, payload) => ({
		...state,
		isLastTradeMakeEmpty: payload,
	}))
	.on(setTeamSaved, (state) => ({
		...state,
		isTeamSaved: true,
	}))
	.on(setTeamComplete, (state, payload) => ({
		...state,
		isTeamComplete: payload,
	}))
	.on(fetchTeamById, (state) => ({
		...state,
		teamByIdRequestState: RequestStateType.Loading,
	}))
	.on(fetchTeamByIdSuccess, (state, payload) => ({
		...state,
		teamByIdRequestState: RequestStateType.Success,
		team: payload,
	}))
	.on(fetchTeamByIdFailed, (state) => ({
		...state,
		teamByIdRequestState: RequestStateType.Error,
	}))
	.on(fetchTeamHistory, (state) => ({
		...state,
		teamHistoryRequestState: RequestStateType.Loading,
	}))
	.on(fetchTeamHistorySuccess, (state, payload) => ({
		...state,
		teamHistoryRequestState: RequestStateType.Success,
		team: {
			...state.team,
			...payload,
		},
	}))
	.on(fetchTeamHistoryFailed, (state) => ({
		...state,
		teamHistoryRequestState: RequestStateType.Error,
	}));
