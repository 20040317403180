import React, {FC} from "react";
import styled from "styled-components";
import {FieldWrapper} from "components/styled";
import {FieldRow, PlayingField} from "components/Team/styled";
import {get, range} from "lodash";
import {CircularPreloaded} from "components";
import {useSelector} from "react-redux";
import {getIsLoadingTeamHistory, getProject, getTeam} from "modules/selectors";
import {isGameDayGame} from "modules/utils";
import {TablePlayer} from "./TablePlayer";
import field_classic_bg from "assets/images/field_classic_bg.png";
import field_classic_bg_mobile from "assets/images/field_classic_bg_mobile.png";
import field_gameday_bg from "assets/images/field_gameday_bg.png";
import field_gameday_bg_mobile from "assets/images/field_gameday_bg_mobile.png";

const Wrapper = styled.div`
	position: relative;
`;

const PreloaderWrapper = styled.div`
	padding: 100px 20px;
	display: flex;
	justify-content: center;
	align-items: center;
`;

interface IProps {
	roundId: number;
}

export const TableTeam: FC<IProps> = ({roundId}) => {
	const team = useSelector(getTeam);
	const {lineup, formation} = team;
	const formationClass = formation.includes("5") ? "wide" : "";
	const isLoading = useSelector(getIsLoadingTeamHistory);

	const currentProject = useSelector(getProject);
	const fieldBG = isGameDayGame(currentProject) ? field_gameday_bg : field_classic_bg;
	const fieldBGMob = isGameDayGame(currentProject)
		? field_gameday_bg_mobile
		: field_classic_bg_mobile;

	const players = range(1, 5).map((row_index) => (
		<FieldRow key={row_index} className="field-row">
			{get<number[], number[]>(lineup, row_index, []).map((playerID, cell_index) => (
				<TablePlayer key={cell_index} playerID={playerID} roundId={roundId} />
			))}
		</FieldRow>
	));

	if (isLoading) {
		return (
			<PreloaderWrapper>
				<CircularPreloaded size={80} />
			</PreloaderWrapper>
		);
	}

	return (
		<React.Fragment>
			<Wrapper>
				<FieldWrapper>
					<PlayingField
						fieldBG={fieldBG}
						fieldBGMob={fieldBGMob}
						className={formationClass}>
						{players}
					</PlayingField>
				</FieldWrapper>
			</Wrapper>
		</React.Fragment>
	);
};
