import React, {useCallback, useEffect} from "react";
import {
	ModalActions,
	ModalBackdrop,
	ModalCloseButton,
	ModalContent,
} from "components/Modals/styled";
import {IconRemove} from "components/Icons";
import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import {TransferViewResult} from "components/TransferViewResult";
import {PrimaryButton, SecondaryButton} from "components/styled";
import {
	getFreeTradesCount,
	getPointCost,
	getTeamRemainingSalary,
	getTeamRequestState,
	getTransferModalState,
	getWeeklyTransfersMade,
} from "modules/selectors";
import {closeTransferModal} from "modules/actions/modals";
import {makeTradeRequest} from "modules/actions";
import {SALARY_CUP_DELIMITER} from "modules/constants";
import {useTranslation} from "react-i18next";

const InnerWrapper = styled.div`
	h2 {
		color: ${({theme}) => theme.text_color};
		font-size: 20px;
		letter-spacing: 0;
		line-height: 24px;
		text-align: center;
	}
`;

const TransferData = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 30px 24px;
	gap: 24px;

	h3 {
		margin: 0;
		color: ${({theme}) => theme.text_color};
		font-size: 24px;
		font-weight: bold;
		letter-spacing: 0;
		line-height: 29px;
		text-align: center;

		&.point-cost-negative {
			color: #ff0000;
		}
	}

	p {
		margin: 0 0 6px 0;
		color: ${({theme}) => theme.text_color};
		font-size: 12px;
		letter-spacing: 0;
		line-height: 15px;
		text-align: center;
		max-width: 95px;
	}
`;

export const TransferModal: React.FC = () => {
	const dispatch = useDispatch();
	const {t} = useTranslation();
	const isModalOpen = useSelector(getTransferModalState);
	const remainBudget = Math.floor(useSelector(getTeamRemainingSalary) / SALARY_CUP_DELIMITER);
	const {isLoading, isSuccess} = useSelector(getTeamRequestState)("trade");
	const freeTrades = useSelector(getFreeTradesCount);
	const weeklyTransfers = useSelector(getWeeklyTransfersMade);
	const pointCost = useSelector(getPointCost);

	const closeModal = useCallback(() => {
		dispatch(closeTransferModal());
	}, [dispatch]);

	const makeTransfer = useCallback(() => {
		dispatch(makeTradeRequest());
	}, [dispatch]);

	useEffect(() => {
		if (isSuccess) {
			closeModal();
		}
	}, [isSuccess, closeModal]);

	if (!isModalOpen) {
		return null;
	}

	return (
		<ModalBackdrop className="fade">
			<ModalContent>
				<ModalCloseButton onClick={closeModal}>
					<IconRemove />
				</ModalCloseButton>
				<InnerWrapper>
					<h2>{t("transfer.modal.title")}</h2>
					<TransferViewResult />
					<TransferData>
						<div>
							<p>{t("transfer.modal.free_weekly")}</p>
							<h3>
								{weeklyTransfers}/{freeTrades}
							</h3>
						</div>
						<div>
							<p>{t("transfer.modal.point_cost")}</p>
							<h3 className={pointCost < 0 ? "point-cost-negative" : ""}>
								{pointCost}
							</h3>
						</div>
						<div>
							<p>{t("transfer.modal.budget")}</p>
							<h3>ISK{remainBudget}m</h3>
						</div>
					</TransferData>

					<ModalActions>
						<SecondaryButton disabled={isLoading} onClick={closeModal}>
							{t("transfer.modal.back")}
						</SecondaryButton>
						<PrimaryButton disabled={isLoading} onClick={makeTransfer}>
							{t("transfer.modal.confirm")}
						</PrimaryButton>
					</ModalActions>
				</InnerWrapper>
			</ModalContent>
		</ModalBackdrop>
	);
};
