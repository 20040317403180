import React from "react";
import {Link} from "react-router-dom";
import styled from "styled-components";
import {PaddingContainer} from "components/styled";
import {useTranslation} from "react-i18next";

const LandingHeaderWrapper = styled(PaddingContainer)`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	height: 48px;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	box-sizing: border-box;

	a {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
		min-width: 115px;
		color: #ffffff;
		font-size: 14px;
		letter-spacing: 0;
		line-height: 17px;
		text-decoration: none;
		transition: color 0.2s ease-in-out;

		&:hover {
			color: ${({theme}) => theme.tertiary};
		}
	}
`;

export const LandingHeader: React.FC = () => {
	const {t} = useTranslation();
	return (
		<LandingHeaderWrapper>
			<Link to="/help">{t("landing.header.help")}</Link>
			<Link to="/login" className="desktop-only">
				{t("landing.header.Log_in")}
			</Link>
		</LandingHeaderWrapper>
	);
};
