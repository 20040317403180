import React, {useCallback, useEffect, useState} from "react";
import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import {
	getLeagueById,
	getLeagueRequestStateByKey,
	getLeagueUsers,
	getUser,
} from "modules/selectors";
import {PreloaderWrapper, PrimaryButton, PureButton} from "components/styled";
import {CircularPreloaded} from "components/Preloader";
import {Exist} from "components/Exist";
import {fetchLeagueUsersRequest, leagueUsersClear} from "modules/actions";
import {IconRemove} from "components/Icons";
import {openRemovePlayerModal} from "modules/actions/modals";
import {RemovePlayerModal} from "components/Modals";
import {useLocation} from "react-router-dom";
import {media} from "assets/media";
import {useTranslation} from "react-i18next";

const Wrapper = styled.div``;

const Table = styled.table`
	width: 100%;
	border-collapse: collapse;
`;

const THead = styled.thead`
	tr {
		border-radius: 4px 4px 0 0;
		background: ${({theme}) => theme.secondary};
	}

	th {
		text-align: left;
		color: #ffffff;
		font-size: 14px;
		letter-spacing: 0;
		line-height: 17px;
		padding: 12px 0 14px 12px;

		&.position {
			width: 1%;
		}

		&.action {
			width: 1%;
			padding-right: 30px;
		}

		&:first-child {
			border-top-left-radius: 4px;
		}

		&:last-child {
			border-top-right-radius: 4px;
		}
	}

	@media screen and ${media.lessThanMD} {
		th {
			&.position {
				width: 15%;
			}

			&.action {
				width: 15%;
			}

			&:first-child {
				border-top-left-radius: 0;
			}

			&:last-child {
				border-top-right-radius: 0;
			}
		}
	}
`;

const TBody = styled.tbody`
	tr {
		border: 1px solid #dddddd;
		border-top: 0;
		
		&.thisIsYou {
			font-weight: bold;
		}
	}

	td {
		padding: 16px 0 16px 12px;
		color: ${({theme}) => theme.text_color};
		font-size: 14px;
		letter-spacing: 0;
		line-height: 14px;

		&.action-td {
			text-align: right;
			padding-right: 14px;
		}
	}

	tr.preloader {
		text-align: center;

		td {
			padding: 12px 0;
		}
	}
`;

const LoadMoreWrapper = styled.div`
	width: 100%;
	text-align: center;
	button {
		margin: 24px auto;
	}
`;

interface IProps {
	leagueId: number;
}

export const LeagueManageTable: React.FC<IProps> = ({leagueId}) => {
	const location = useLocation();
	const {t} = useTranslation();
	const dispatch = useDispatch();
	const league = useSelector(getLeagueById)(leagueId);
	const {users, nextPage} = useSelector(getLeagueUsers);
	const user = useSelector(getUser);
	const {isLoading} = useSelector(getLeagueRequestStateByKey)("fetchLeagueUsers");
	const [filter, setFilter] = useState({
		limit: 20,
		page: 1,
	});

	const loadMore = useCallback(() => {
		setFilter({...filter, page: filter.page + 1});
	}, [filter]);

	useEffect(() => {
		dispatch(leagueUsersClear());
	}, [dispatch, location]);

	useEffect(() => {
		dispatch(fetchLeagueUsersRequest({id: leagueId, payload: filter}));
	}, [dispatch, leagueId, filter]);

	const removeUser = useCallback(
		(e: React.SyntheticEvent<HTMLButtonElement>) => {
			const userId = Number(e.currentTarget.dataset.id);
			if (!userId) {
				return;
			}
			dispatch(openRemovePlayerModal({userId, leagueId}));
		},
		[dispatch, leagueId]
	);

	if (!league) {
		return (
			<PreloaderWrapper>
				<CircularPreloaded />
			</PreloaderWrapper>
		);
	}

	return (
		<Wrapper>
			<Table>
				<THead>
					<tr>
						<th className="position">{t("leaderboard.table.h_position")}</th>
						<th className="manager">{t("leaderboard.table.h_manager")}</th>
						<th className="action">
							<span className="desktop-only">{t("team.pitch.key.remove")}</span>
							<span className="mobile-only">&nbsp;</span>
						</th>
					</tr>
				</THead>
				<TBody>
					{users.map((leagueUser, index) => (
						<tr key={leagueUser.userId} className={leagueUser.userId === user?.id ? "thisIsYou" : ""}>
							<td>{index + 1}</td>
							<td>
								{leagueUser.first_name} {leagueUser.last_name}
							</td>
							<td className="action-td">
								<Exist when={leagueUser.userId !== user?.id}>
									<PureButton onClick={removeUser} data-id={leagueUser.userId}>
										<IconRemove color="#F02F42" />
									</PureButton>
								</Exist>
							</td>
						</tr>
					))}
					<tr className="preloader">
						<Exist when={isLoading}>
							<td colSpan={3}>
								<CircularPreloaded />
							</td>
						</Exist>
					</tr>
				</TBody>
			</Table>
			<Exist when={nextPage}>
				<LoadMoreWrapper>
					<PrimaryButton disabled={isLoading} className="w-220" onClick={loadMore}>
						{t("join_leagues.body.button_join")}
					</PrimaryButton>
				</LoadMoreWrapper>
			</Exist>
			<RemovePlayerModal />
		</Wrapper>
	);
};
