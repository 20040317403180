import {SagaIterator} from "redux-saga";
import {call, put} from "typed-redux-saga";
import {Api} from "modules/utils/Api";
import {fetchPlayersSuccess} from "modules/actions";

export const fetchPlayersSaga = function* (): SagaIterator {
	try {
		const players = yield* call(Api.JSON.players);
		yield* put(fetchPlayersSuccess(players.data));
	} catch (e) {
		console.log(e);
	}
};
