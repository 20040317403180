import React from "react";
import {useSelector} from "react-redux";
import {getTradePairsAccordingPosition} from "modules/selectors";
import styled from "styled-components";
import {Exist} from "components/Exist";
import {Transfer} from "components";
import {useTranslation} from "react-i18next";

const Table = styled.table`
	width: 100%;
	border-collapse: collapse;
`;

const Thead = styled.thead`
	border-radius: 4px 4px 0 0;
	background: ${({theme}) => theme.secondary};

	th {
		color: #ffffff;
		font-size: 14px;
		letter-spacing: 0;
		line-height: 17px;
		padding: 6px 10px;
		font-weight: normal;

		&:first-of-type {
			text-align: left;
		}

		&:last-of-type {
			text-align: right;
		}
	}
`;

const Tbody = styled.tbody`
	tr {
		border: 1px solid #dddddd;
		border-top: none;

		td.icon {
			padding: 0 12px;
		}

		td p.price {
			color: #f02f42;
		}

		td p.price.reverse {
			color: #298109;
		}
	}
`;

export const TransferViewResult: React.FC = () => {
	const trades = useSelector(getTradePairsAccordingPosition);
	const {t} = useTranslation();

	return (
		<Table>
			<Thead>
				<tr>
					<th>{t("transfer.modal.h_transfer_out")}</th>
					<th>&nbsp;</th>
					<th>{t("transfer.modal.h_transfer_in")}</th>
				</tr>
			</Thead>
			<Exist when={trades.length > 0}>
				<Tbody>
					{trades.map(([tradeOut, tradeIn], tradeIndex) => (
						<Transfer
							key={tradeOut}
							tradeOut={tradeOut}
							tradeIn={tradeIn}
							tradeIndex={tradeIndex}
							tradesLength={trades.length}
							hasAction={false}
						/>
					))}
				</Tbody>
			</Exist>
		</Table>
	);
};
