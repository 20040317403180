import {Input} from "components/styled";
import React, {useCallback, useMemo} from "react";
import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import {
	getIsSelectedRoundNoActive,
	getPlayersAccordingLineUp,
	getTeam,
	getTeamHeads,
	isPlayerLocked,
} from "modules/selectors";
import {MenuItem} from "@mui/material";
import {setHeads} from "modules/actions";
import {ITeamHeads} from "modules/types";
import {useTranslation} from "react-i18next";

const Wrapper = styled.div`
	margin-left: auto;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	padding-left: 20px;
	gap: 20px;
	min-width: 310px;

	${Input} {
		max-width: 145px;
		width: 100%;

		.MuiInputLabel-root:not([data-shrink="true"]) {
			top: -6px !important;
			font-size: 14px;
			color: #222222 !important;
		}

		.MuiOutlinedInput-input {
			padding-top: 8px;
			padding-bottom: 10px;
		}

		.MuiOutlinedInput-notchedOutline {
			border-color: #dddddd !important;
		}
	}

	@media screen and (max-width: 750px) {
		gap: 8px;
		padding-left: 0;

		${Input} {
			max-width: unset;
		}
	}
`;

export const TeamCaptainSelect: React.FC = () => {
	const dispatch = useDispatch();
	const {t} = useTranslation();
	const teamPlayers = useSelector(getPlayersAccordingLineUp);
	const team = useSelector(getTeam);
	const headState = useSelector(getTeamHeads);
	const isLocked = useSelector(isPlayerLocked);
	const isSelectedRoundNoActive = useSelector(getIsSelectedRoundNoActive);
	const isCaptainLocked =
		(isLocked(headState.captain) && team?.isCompleted) || isSelectedRoundNoActive;
	const isViceCaptainLocked =
		(isLocked(headState.viceCaptain) && team?.isCompleted) || isSelectedRoundNoActive;

	const captainPlayers = useMemo(() => {
		return teamPlayers.filter((player) => {
			if (player?.id === headState.captain) {
				return true;
			}
			if (player?.id === headState.viceCaptain) {
				return false;
			}
			if (!team?.isCompleted) {
				return true;
			}
			return !isLocked(player?.id);
		});
	}, [teamPlayers, headState, isLocked, team?.isCompleted]);

	const viceCaptainPlayers = useMemo(() => {
		return teamPlayers.filter((player) => {
			if (player?.id === headState.viceCaptain) {
				return true;
			}
			if (player?.id === headState.captain) {
				return false;
			}
			if (!team?.isCompleted) {
				return true;
			}
			return !isLocked(player?.id);
		});
	}, [teamPlayers, headState, isLocked, team?.isCompleted]);

	const onHeadSet = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			const name = e.target.name as keyof ITeamHeads;
			const value = Number(e.target.value);

			if (!name) {
				return;
			}

			dispatch(
				setHeads({
					...headState,
					[name]: value,
				})
			);
		},
		[dispatch, headState]
	);

	return (
		<Wrapper>
			<Input
				select
				name="captain"
				label={t("team.dropdown.captain")}
				onChange={onHeadSet}
				value={headState.captain}
				disabled={isCaptainLocked}>
				<MenuItem value={0} selected={true}>
					{t("team.dropdown.no_captain")}
				</MenuItem>
				{captainPlayers.map((player) => (
					<MenuItem value={player?.id} key={player?.id}>
						{player?.firstName}
					</MenuItem>
				))}
			</Input>
			<Input
				select
				name="viceCaptain"
				label={t("team.dropdown.vice_captain")}
				onChange={onHeadSet}
				value={headState.viceCaptain}
				disabled={isViceCaptainLocked}>
				<MenuItem value={0} selected={true}>
					{t("team.dropdown.no_vice_captain")}
				</MenuItem>
				{/* This functions have to be identical, if wrap in common component - MUI select doesn't work */}
				{/* eslint-disable-next-line sonarjs/no-identical-functions */}
				{viceCaptainPlayers.map((player) => (
					<MenuItem value={player?.id} key={player?.id}>
						{player?.firstName}
					</MenuItem>
				))}
			</Input>
		</Wrapper>
	);
};
