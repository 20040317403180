import {DateTime} from "luxon";
import {Sport} from "modules/types/sport";
import {IStatsColumn} from "modules/types/shared";
import {uniqueId} from "lodash";

export const BASE_URL = process.env.REACT_APP_BASE_URL || "";
export const API_URL = process.env.REACT_APP_API_URL || "";
export const JSON_URL = process.env.REACT_APP_JSON_URL || "";
export const IS_UAT = process.env.REACT_APP_ENVIRONMENT || "";

export const REACT_APP_LOCAL_IMAGE_URL = process.env.REACT_APP_IMAGES_URL || "";
export const SALARY_CUP = Number(process.env.REACT_APP_SALARY_CUP);
export const SALARY_CUP_DELIMITER = Number(process.env.REACT_APP_SALARY_CUP_DELIMITER);
export const APP_VERSION = parseFloat(process.env.REACT_APP_VERSION || "1.0");
export const APP_IS_AD_ENABLED = process.env.REACT_APP_IS_AD_VISIBLE === "true";

export const LANDING_PATH = "/";
export const NO_HEADER_PATHS = ["/login", "/register", "/forgot-password", "/reset-password"];

export const MIN_USER_DATE = DateTime.fromISO("1900-01-01");
export const MAX_USER_DATE = DateTime.now().minus({year: 14});
export const USER_DOB_FORMAT = "dd/MM/yyyy";
export const EMAIL_PATTERN = "[A-za-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$";
export const PASSWORD_PATTERN = "^((?=.*?\\d)(?=.*[A-Z])).{8,}$";
export const USER_NAME_PATTERN = "^[A-Za-z0-9 '`-]+$";

export const FORMATION_LIST = [
	"1-4-4-2",
	"1-4-3-3",
	"1-4-5-1",
	"1-3-5-2",
	"1-3-4-3",
	"1-5-4-1",
] as const;

export const DEFAULT_BUTTON_SUCCESS_DELAY = 1500;
export const SECRET_LOCALSTORAGE_KEY = "ra_is_secret";

const IS_SECRET_ENABLED_ENV = Boolean(
	JSON.parse(process.env.REACT_APP_IS_SECRET_ENABLED || "false")
);
const IS_SECRET_ENABLED_LOCAL = localStorage.getItem(SECRET_LOCALSTORAGE_KEY) !== "false";

export const IS_SECRET_ENABLED = IS_SECRET_ENABLED_ENV && IS_SECRET_ENABLED_LOCAL;

export const SEASON_TRANSFERS = 50;
export const WEEK_TRANSFERS = 5;

export const FB_SHARE_URL = process.env.REACT_APP_FB_SHARE_URL || "";

export const SPORT_MAP = {
	[Sport.IFL]: "classic",
	[Sport.MC]: "gameday",
};

export const LANGUAGES = [
	{name: "English", code: "en"},
	{name: "Icelandic", code: "is"},
];

const headerColumnPrefixID = "header-column";
export const PLAYER_STATS_COLUMNS: IStatsColumn[] = [
	{
		id: uniqueId(headerColumnPrefixID),
		name: "Name",
		value: "name",
		customClassName: "first",
		sortBy: "firstName",
	},
	{
		id: uniqueId(headerColumnPrefixID),
		name: "Team",
		value: "team",
	},
	{
		id: uniqueId(headerColumnPrefixID),
		name: "ISKm",
		value: "price",
		sortBy: "price",
		tooltip: "Price",
	},
	{
		id: uniqueId(headerColumnPrefixID),
		name: "GWP",
		tooltip: "Round Points",
	},
	{
		id: uniqueId(headerColumnPrefixID),
		value: "total_points",
		sortBy: "stats.total_points",
		name: "TP",
		tooltip: "Total Points",
	},
	{
		id: uniqueId(headerColumnPrefixID),
		value: "starting11",
		sortBy: "stats.starting11",
		name: "SXI",
		tooltip: "Starting Appearances",
	},
	{
		id: uniqueId(headerColumnPrefixID),
		value: "substitutions",
		sortBy: "stats.substitutions",
		name: "Sub",
		tooltip: "Substitute Appearances",
	},
	{
		id: uniqueId(headerColumnPrefixID),
		value: "goals",
		sortBy: "stats.goals",
		name: "GS",
		tooltip: "Goals Scored",
	},
	{
		id: uniqueId(headerColumnPrefixID),
		value: "assists",
		sortBy: "stats.assists",
		name: "A",
		tooltip: "Assists",
	},
	{
		id: uniqueId(headerColumnPrefixID),
		value: "clean_sheets",
		sortBy: "stats.clean_sheets",
		name: "CS",
		tooltip: "Full Clean Sheets",
	},
	{
		id: uniqueId(headerColumnPrefixID),
		value: "own_goals",
		sortBy: "stats.own_goals",
		name: "OG",
		tooltip: "Own Goals",
	},
	{
		id: uniqueId(headerColumnPrefixID),
		value: "yellow_card",
		sortBy: "stats.yellow_card",
		name: "YC",
		tooltip: "Yellow Cards",
	},
	{
		id: uniqueId(headerColumnPrefixID),
		value: "red_card",
		sortBy: "stats.red_card",
		name: "RC",
		tooltip: "Red Cards",
	},
	{
		id: uniqueId(headerColumnPrefixID),
		value: "pointsPerMillion",
		name: "P/ISK",
		tooltip: "Points per Million",
	},
	{
		id: uniqueId(headerColumnPrefixID),
		value: "picked_by",
		sortBy: "stats.picked_by",
		name: "%",
		tooltip: "Pick Percentage",
	},
];

export const TEAM_STATS_COLUMNS: IStatsColumn[] = [
	{
		id: uniqueId(headerColumnPrefixID),
		name: "Team",
		value: "team",
		customClassName: "first",
		sortBy: "stats.team",
	},
	{
		id: uniqueId(headerColumnPrefixID),
		name: "B",
		customClassName: "badge",
	},
	{
		id: uniqueId(headerColumnPrefixID),
		name: "P",
		value: "played",
		sortBy: "stats.played",
	},
	{
		id: uniqueId(headerColumnPrefixID),
		name: "GS",
		value: "goals_scored",
		sortBy: "stats.goals_scored",
	},
	{
		id: uniqueId(headerColumnPrefixID),
		name: "A",
		value: "assists",
		sortBy: "stats.assists",
	},
	{
		id: uniqueId(headerColumnPrefixID),
		name: "GC",
		value: "goals_conceded",
		sortBy: "stats.goals_conceded",
	},
	{
		id: uniqueId(headerColumnPrefixID),
		name: "OG",
		value: "own_goals",
		sortBy: "stats.own_goals",
	},
	{
		id: uniqueId(headerColumnPrefixID),
		name: "YC",
		value: "yellow_cards",
		sortBy: "stats.yellow_cards",
	},
	{
		id: uniqueId(headerColumnPrefixID),
		name: "RC",
		value: "red_cards",
		sortBy: "stats.red_cards",
	},
	{
		id: uniqueId(headerColumnPrefixID),
		name: "Top Performer",
		customClassName: "performer",
		sortBy: "stats.top_performer.points_sum",
	},
	{
		id: uniqueId(headerColumnPrefixID),
		name: "GWP",
		value: "gameweek_points",
		sortBy: "stats.gameweek_points",
	},
	{
		id: uniqueId(headerColumnPrefixID),
		name: "TP",
		value: "total_points",
		sortBy: "stats.total_points",
	},
];
