import {createSelector} from "reselect";
import {
	chain,
	filter,
	get,
	identity,
	indexOf,
	lte,
	merge,
	range,
	reduce,
	set,
	size,
	some,
} from "lodash";
import {IDictionary, ILineup, IStore, Nullable} from "modules/types";
import {getAvailableFormation, getPlayersById, getTeam, hasEmptySpot} from "modules/selectors";
import {SALARY_CUP} from "modules/constants";
import {IPlayer} from "modules/types/json";
import {FORMATIONS} from "modules/utils/Team";

const getTeamState = ({teams}: IStore) => teams;

export const getFreeTradesCount = createSelector(getTeam, ({freeTradesCount}) => freeTradesCount);

export const getWeekTradesCount = createSelector(getTeam, ({weekTradesCount}) => weekTradesCount);

export const getPenaltyTradePoints = createSelector(
	getTeam,
	({penaltyTradesPoints}) => penaltyTradesPoints
);

export const getTrades = createSelector(getTeamState, (state) => ({
	tradeIn: state.tradesData.tradeIn,
	tradeOut: state.tradesData.tradeOut,
}));

const returnTradePairsRange = (
	tradesLeft: Nullable<number>,
	tradeOut: number[],
	tradeIn: number[]
) => {
	if (tradesLeft === null) {
		tradesLeft = 25;
	}

	return reduce<number, number[][]>(
		range(0, Number(tradesLeft)),
		(acc, tradeIndex) => {
			const trade = [tradeOut[tradeIndex], tradeIn[tradeIndex]];

			if (some(trade, identity)) {
				acc.push(trade);
			}

			return acc;
		},
		[]
	);
};

export const getTradePairs = createSelector(getTeamState, ({tradesData}) => {
	return returnTradePairsRange(null, tradesData.tradeOut, tradesData.tradeIn);
});

const sortPlayersByPosition = (a: IPlayer, b: IPlayer) => {
	if (!a || !b) {
		return 0;
	}
	if (a.position === b.position) {
		return 0;
	}

	return a.position > b.position ? 1 : -1;
};

export const getTradePairsAccordingPosition = createSelector(
	getTeamState,
	getPlayersById,
	({tradesData}, players) => {
		const tradeOutPlayersData = tradesData.tradeOut
			.map((playerId) => players[playerId])
			.sort(sortPlayersByPosition)
			.map((player) => player?.id);

		// check tradeIn by length index of tradeout
		const tradeInPlayersData = tradesData.tradeOut
			.map((playerId, index) => players[tradesData.tradeIn[index]])
			.sort(sortPlayersByPosition)
			.map((player) => player?.id);

		return returnTradePairsRange(null, tradeOutPlayersData, tradeInPlayersData);
	}
);

export const getTradesLength = createSelector(getTradePairs, (trades) => trades.length);

export const getWeeklyTransfersMade = createSelector(
	getWeekTradesCount,
	getTradesLength,
	(weekTrades, tradesLength) => (weekTrades || 0) + tradesLength
);

export const getPointCost = createSelector(
	getWeeklyTransfersMade,
	getFreeTradesCount,
	(weekTrades, freeTradesCount) => {
		if (freeTradesCount && weekTrades > freeTradesCount) {
			return (freeTradesCount - weekTrades) * 4;
		}
		return 0;
	}
);

const getLineupValueHelper = (lineup: ILineup, playersByID: IDictionary<IPlayer>) =>
	chain(lineup)
		.values()
		.flatten()
		.filter(identity)
		.value()
		.reduce((acc, id) => {
			acc += get(playersByID[id], "cost", 0);
			return acc;
		}, 0);

interface IReplacePlayerInTeamPayload {
	playersByID: IDictionary<IPlayer>;
	lineup: ILineup;
	replaceFrom?: number;
	replaceTo?: number;
}

const findAndReplaceInTeamHelper = ({
	playersByID,
	lineup,
	replaceFrom = 0,
	replaceTo = 0,
}: IReplacePlayerInTeamPayload) => {
	const playerID = replaceFrom || replaceTo;

	if (!playerID) {
		return lineup;
	}

	const player: IPlayer = playersByID[playerID];
	const {position} = player;
	const line = [...lineup[position]];

	return {
		...lineup,
		[position]: set(line, indexOf(line, replaceFrom), replaceTo),
	};
};

interface ITransformFormationPayload {
	lineup: ILineup;
	formation?: ReturnType<typeof getAvailableFormation>;
}

const transformFormationHelper = ({lineup, formation}: ITransformFormationPayload) => {
	if (!formation) {
		return lineup;
	}

	return reduce<ILineup, ILineup>(
		FORMATIONS.get(formation),
		(acc, newLine, key) => {
			const currentLine = lineup[key];
			const currentLineWithPlayers = filter(currentLine, identity);
			const emptyLineSize = size(newLine);
			const playersLineSize = size(currentLineWithPlayers);
			const filledPositionsDiff = playersLineSize - emptyLineSize;

			if (lte(filledPositionsDiff, 0)) {
				acc[key] = merge([], newLine, currentLineWithPlayers);
			}

			return acc;
		},
		{}
	);
};

export const getResetTransferDataSelector = createSelector(
	getTradePairsAccordingPosition,
	getPlayersById,
	getTeam,
	(trades, playersByID, team) => (resetTradeIndex: number) => {
		const [tradeOut, tradeIn] = trades[resetTradeIndex];

		let lineup = {...team.lineup};
		let formation = team.formation;

		lineup = findAndReplaceInTeamHelper({
			playersByID,
			lineup,
			replaceFrom: tradeIn,
			replaceTo: 0,
		});

		const returnToTeamPlayer: IPlayer = playersByID[tradeOut];
		if (!returnToTeamPlayer) {
			return false;
		}
		const position = returnToTeamPlayer.position;

		if (!hasEmptySpot(lineup, position)) {
			formation = getAvailableFormation(lineup, position)!;

			if (!formation) {
				return false;
			}

			lineup = transformFormationHelper({lineup, formation});
		}

		lineup = findAndReplaceInTeamHelper({
			playersByID,
			lineup,
			replaceFrom: 0,
			replaceTo: tradeOut,
		});

		const teamValue = getLineupValueHelper(lineup, playersByID);

		if (SALARY_CUP - teamValue < 0) {
			return false;
		}

		return {formation, lineup, tradeIn, tradeOut};
	}
);
