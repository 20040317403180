import {IGameBarPayload, IGameBarResponse, ILineup, ITeam} from "modules/types";
import {IFormation} from "modules/utils/Team";
import {createAction} from "redux-act";

export interface ISuccessResetTradeByIndexPayload {
	lineup: ILineup;
	formation: IFormation;
	tradeOut: number;
	tradeIn?: number;
}

export const tradeInPlayer = createAction<number>();
export const tradeOutPlayer = createAction<number>();
export const setLastTradeEmptyState = createAction<boolean>();

export const resetTradeByIndex = createAction<number>();
export const successResetTradeByIndex = createAction<ISuccessResetTradeByIndexPayload>();

export const makeTradeRequest = createAction();
export const makeTradeSuccess = createAction<ITeam>();

export const clearTrades = createAction();

export const fetchGameBarRequest = createAction<IGameBarPayload>();
export const fetchGameBarSuccess = createAction<IGameBarResponse>();
