import {SagaIterator} from "redux-saga";
import {call, race, take, select, all, delay, StrictEffect} from "redux-saga/effects";
import {
	differenceBy,
	toPairs,
	last,
	first,
	flow,
	partial,
	get,
	identity,
	isEmpty,
	find,
} from "lodash";

import {unsubscribeFromLiveScores} from "modules/actions";
import {getChecksums, getRounds} from "modules/selectors";

import {fetchChecksumsSaga, fetchPlayersSaga, fetchRoundsSaga} from "modules/sagas";
import {IChecksumsReducer} from "modules/reducers/checksums";
import {ISagaAction} from "modules/types";
import {IRound} from "modules/types/json";
import {RoundStatus} from "modules/types/enums";

const mapChecksumsToSaga = {
	"ifl/rounds": () => fetchRoundsSaga(),
	players: () => fetchPlayersSaga(),
};

const WAIT = 31000; // Half minute
// TODO: refactor
// @typescript-eslint/no-unsafe-assignment disabled because lint issues with type checking
export const fetchLiveScoresSaga = function* (data: ISagaAction<number>): SagaIterator {
	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
	const {stopped} = yield race({
		wait: delay(WAIT), // Wait for a half minute
		stopped: take(unsubscribeFromLiveScores),
	});

	if (!stopped) {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		const [old_checksums]: IChecksumsReducer[] = yield all([
			select(getChecksums),
			call(fetchChecksumsSaga),
		]) as StrictEffect;

		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		const new_checksums: IChecksumsReducer = yield select(getChecksums);
		const requestsForChanges = differenceBy(
			toPairs(old_checksums),
			toPairs(new_checksums),
			last
		)
			.map(flow([first, partial(get, mapChecksumsToSaga)]))
			.filter(identity);

		if (!isEmpty(requestsForChanges)) {
			yield all(requestsForChanges.map((request) => call(request)));
		}

		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		const rounds = yield select(getRounds);
		const {payload} = data;
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		const activeRound = find(rounds, (round: IRound) => round.id === payload);

		if (get(activeRound, "status", "") === RoundStatus.Active) {
			yield call(fetchLiveScoresSaga, data);
		}
	}
};
