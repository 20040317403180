import React, {useCallback} from "react";
import styled from "styled-components";
import {PaddingContainer} from "components/styled";
import {
	getIsActualRoundLocked,
	getIsSelectedRoundNoActive,
	getIsTeamComplete,
	getIsTeamHeadsFull,
	getIsTeamLockout,
	getTeam,
	getTeamEmptyLineup,
} from "modules/selectors";
import {useDispatch, useSelector} from "react-redux";
import {flatMap} from "lodash";
import {
	autoFillRequest,
	clearHeads,
	editModalSavingMode,
	removePlayerFromTeam,
	saveTeam,
	toggleEditTeamNameModal,
	toggleNoHeadsModal,
} from "modules/actions";
import {Exist} from "components/Exist";
import {useIsPathIncludes} from "modules/utils/hooks";
import {NonCompleteTeamButtons} from "./NonCompleteTeamButtons";
import {LockoutButtons} from "components/Team/BottomStatusBar/LockoutButtons";
import {NonLockoutButtons} from "components/Team/BottomStatusBar/NonLockoutButtons";
import {TransferButtons} from "./TransferButtons";
import {useTranslation} from "react-i18next";

const Wrapper = styled.div`
	box-sizing: border-box;
	position: sticky;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 64px;
	padding: 10px 0;
	z-index: 8;
	background: #ffffff;
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
`;

const InnerContainer = styled(PaddingContainer)`
	height: 100%;
`;

const SmallContainer = styled.div`
	box-sizing: border-box;
	max-width: 785px;
	padding: 0 20px;
	display: flex;
	justify-content: space-between;
`;

const SelectedBlock = styled.div`
	p {
		color: #222222;
		font-size: 14px;
		letter-spacing: 0;
		line-height: 17px;
		margin: 0;
	}

	b {
		color: #222222;
		font-size: 16px;
		font-weight: bold;
		letter-spacing: 0;
		line-height: 19px;
	}
`;

const ButtonsBlock = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 20px;

	button {
		min-width: 105px;
	}
`;

export const BottomStatusBar: React.FC = () => {
	const lineup = useSelector(getTeamEmptyLineup);
	const {t} = useTranslation();
	const flatLineUp = flatMap(lineup);
	const total = flatLineUp.length;
	const selected = flatLineUp.filter((item) => item !== 0).length;
	const isTeamComplete = useSelector(getIsTeamComplete);
	const isSelectedRoundNoActive = useSelector(getIsSelectedRoundNoActive);

	if (isSelectedRoundNoActive) {
		return null;
	}

	return (
		<Wrapper>
			<InnerContainer>
				<SmallContainer>
					<SelectedBlock>
						<p>{t("team.summary.selected")}</p>
						<p>
							<b>
								{selected}/{total}
							</b>
						</p>
					</SelectedBlock>
					<ButtonsBlock>
						<Exist when={!isTeamComplete}>
							<NonCompleteTeamButtons />
						</Exist>
						<Exist when={isTeamComplete}>
							<TeamSaveButtons />
							<TransferButtons />
						</Exist>
					</ButtonsBlock>
				</SmallContainer>
			</InnerContainer>
		</Wrapper>
	);
};

const TeamSaveButtons: React.FC = () => {
	const dispatch = useDispatch();
	const isLockout = useSelector(getIsTeamLockout);
	const isHeadsFull = useSelector(getIsTeamHeadsFull);
	const isTransferPage = useIsPathIncludes("transfers");
	const lineup = useSelector(getTeamEmptyLineup);
	const flatLineUp = flatMap(lineup);
	const team = useSelector(getTeam);
	const isActualRoundLocked = useSelector(getIsActualRoundLocked);

	const clearTeam = useCallback(() => {
		flatLineUp.forEach((playerId) => {
			dispatch(removePlayerFromTeam(playerId));
			dispatch(clearHeads());
		});
	}, [flatLineUp, dispatch]);

	const autoFillHandler = useCallback(() => {
		dispatch(autoFillRequest());
	}, [dispatch]);

	const saveTeamHandle = useCallback(() => {
		dispatch(editModalSavingMode(true));
		if (!isHeadsFull && !isActualRoundLocked) {
			dispatch(toggleNoHeadsModal(true));
			return;
		}
		if (!team?.name) {
			dispatch(toggleEditTeamNameModal(true));
			return;
		}
		dispatch(saveTeam());
	}, [team, dispatch, isHeadsFull, isActualRoundLocked]);

	if (isTransferPage) {
		return null;
	}

	if (!isLockout) {
		return (
			<NonLockoutButtons
				saveTeamHandle={saveTeamHandle}
				clearTeam={clearTeam}
				autoFillHandler={autoFillHandler}
			/>
		);
	}
	return (
		<LockoutButtons
			saveTeamHandle={saveTeamHandle}
			clearTeam={clearTeam}
			autoFillHandler={autoFillHandler}
		/>
	);
};
