import {SecondaryLink} from "components/styled";
import React from "react";
import styled from "styled-components";
import {Exist} from "components/Exist";
import {media} from "assets/media";
import {ExistForVersion} from "components/ExistForVersion";
import {useTranslation} from "react-i18next";

const Wrapper = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 12px;
	padding: 8px 0;

	a {
		margin: 0;
		padding: 11px 30px;
		border-radius: 6px;
		font-weight: normal;
	}

	@media screen and ${media.lessThanMD} {
		a {
			box-sizing: border-box;
			min-width: 60px;
			font-size: 14px;
			padding: 8px 6px;
			text-align: center;
		}
	}
`;

interface ILeagueNavigationButtonsProps {
	leagueId: number;
	isOwner: boolean;
}

export const MyLeagueNavigationButtons: React.FC<ILeagueNavigationButtonsProps> = ({
	leagueId,
	isOwner,
}) => {
	const {t} = useTranslation();
	return (
		<Wrapper>
			<Exist when={isOwner}>
				<ExistForVersion version={2.0}>
					<SecondaryLink to={`${leagueId}/table`}>{t("leagues.league_card.table")}</SecondaryLink>
				</ExistForVersion>
				<SecondaryLink to={`${leagueId}/manage`}>{t("leagues.league_card.manage")}</SecondaryLink>
				<SecondaryLink to={`${leagueId}/invites`}>{t("leagues.league_card.invites")}</SecondaryLink>
				<SecondaryLink to={`${leagueId}/settings`}>{t("leagues.league_card.settings")}</SecondaryLink>
			</Exist>
			<Exist when={!isOwner}>
				<ExistForVersion version={2.0}>
					<SecondaryLink to={`${leagueId}/table`}>{t("leagues.league_card.table")}</SecondaryLink>
				</ExistForVersion>
				<SecondaryLink to={`${leagueId}/invites`}>{t("leagues.league_card.invites")}</SecondaryLink>
				<SecondaryLink to={`${leagueId}/about`}>{t("leagues.league_card.about")}</SecondaryLink>
			</Exist>
		</Wrapper>
	);
};
