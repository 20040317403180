import React, {useCallback, useEffect, useState} from "react";
import {StepTitle, StepWrapper} from "components/Leagues/Create/styled";
import styled from "styled-components";
import {Input, PrimaryButton, RadioListWrapper} from "components/styled";
import {ICreateLeagueForm} from "modules/types";
import {createLeagueRequest, globalError} from "modules/actions";
import {ReactComponent as IconInfo} from "assets/images/icons/info.svg";
import {
	FormControl,
	FormControlLabel,
	MenuItem,
	Radio,
	RadioGroup,
	useMediaQuery,
} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {getScheduleRounds, getLeagueRequestStateByKey} from "modules/selectors";
import {first} from "lodash";
import {Exist} from "components/Exist";
import {media} from "assets/media";
import {useTranslation} from "react-i18next";

const Form = styled.form`
	p {
		color: ${({theme}) => theme.text_color};
		font-size: 14px;
		letter-spacing: 0;
		line-height: 17px;
		margin: 16px 0 30px;
	}
`;

const PrivacyBlock = styled.div`
	p {
		margin: 20px 0 4px 0;

		svg {
			vertical-align: middle;
		}
	}
	margin-bottom: 18px;
`;

const SPrimaryButton = styled(PrimaryButton)`
	margin-top: 26px;
`;

interface IProps {
	isDisabled: boolean;
}
export const LeagueCreateStepOne: React.FC<IProps> = ({isDisabled}) => {
	const dispatch = useDispatch();
	const {t} = useTranslation();
	const isMobile = useMediaQuery(media.lessThanMD);
	const rounds = useSelector(getScheduleRounds);
	const {isIdle, isLoading, isSuccess} = useSelector(getLeagueRequestStateByKey)("create");
	const [leagueData, setLeagueData] = useState<ICreateLeagueForm>({
		name: "",
		privacy: "private",
		startRound: 0,
	});

	useEffect(() => {
		setLeagueData({...leagueData, startRound: first(rounds)?.id || 0});
		// Need only when rounds fetched first time
		// eslint-disable-next-line
	}, [rounds]);

	const onSelectChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			const {name, value} = e.target;
			if (!name) {
				return;
			}
			setLeagueData({...leagueData, [name]: value});
		},
		[leagueData]
	);

	const onFormSubmit = useCallback(
		(e: React.SyntheticEvent) => {
			e.preventDefault();
			e.stopPropagation();

			if (leagueData.startRound === 0) {
				dispatch(globalError({message: "Please select start round"}));
				return;
			}
			dispatch(createLeagueRequest(leagueData));
		},
		[dispatch, leagueData]
	);

	const onFormChange = useCallback(
		(e: React.ChangeEvent<HTMLFormElement>) => {
			const name = e.target.name as keyof ICreateLeagueForm;
			const value = String(e.target.value);

			if (!name) {
				return;
			}
			// Used all keys as in ICreateLeagueForm interface
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			setLeagueData({...leagueData, [name]: value});
		},
		[leagueData]
	);

	const isButtonDisabled = isLoading || isSuccess || isDisabled;
	const disabledClass = isDisabled ? "disabled" : "isDisabled";
	const mobileClass = isMobile ? "mobile" : "";

	return (
		<StepWrapper className={`${disabledClass} ${mobileClass}`}>
			<StepTitle>
				<p>{t("create_league.form_step_one.title")}</p>
				<div />
			</StepTitle>
			<Form onChange={onFormChange} onSubmit={onFormSubmit}>
				<p>{t("create_league.form_step_one.text")}</p>
				<Input
					variant="outlined"
					name="name"
					label={t("create_league.form_step_one.name_league")}
					required={true}
					value={leagueData.name}
				/>
				<PrivacyBlock>
					<p>
						{t("create_league.form_step_one.privacy")} <IconInfo fill="#00000" height="13" width="13" />
					</p>
					<RadioListWrapper>
						<FormControl component="fieldset">
							<RadioGroup
								aria-label="privacy"
								value={leagueData.privacy}
								name="privacy">
								<FormControlLabel
									value="private"
									control={<Radio />}
									label={t("create_league.form_step_one.private").toString()}
								/>
								<FormControlLabel
									value="public"
									control={<Radio />}
									label={t("create_league.form_step_one.public").toString()}
								/>
							</RadioGroup>
						</FormControl>
					</RadioListWrapper>
				</PrivacyBlock>

				<Input
					select
					variant="outlined"
					label={t("create_league.form.step_two")}
					name="startRound"
					value={leagueData.startRound}
					onChange={onSelectChange}
					required={true}>
					<MenuItem value={0} selected={true} disabled={true}>
						Select start round
					</MenuItem>
					{rounds.map((round) => (
						<MenuItem key={round.id} value={round.id}>
							{t(`team.summary.filter_gw${round.number}`)}
						</MenuItem>
					))}
				</Input>

				<SPrimaryButton className="w-220" disabled={isButtonDisabled}>
					<Exist when={isIdle}>{t("create_league.form_step_one.button_create")}</Exist>
					<Exist when={isLoading}>Creating...</Exist>
					<Exist when={isSuccess}>{t("create_league.form_step_one.tick")}</Exist>
				</SPrimaryButton>
			</Form>
		</StepWrapper>
	);
};
