import React from "react";
import styled from "styled-components";
import {useSelector} from "react-redux";
import {getPlayerById} from "modules/selectors";
import {getPlayerPositionMap} from "modules/utils";
import {Exist} from "components/Exist";
import {media} from "assets/media";
import {useTranslation} from "react-i18next";

const Wrapper = styled.div``;

const Row = styled.div`
	display: flex;
	justify-content: space-between;
	border-bottom: 1px solid #d8d8d8;
	padding: 24px 22px;

	@media screen and ${media.lessThanMD} {
		padding: 16px 9px;
		flex-wrap: wrap;
		border-bottom: 0;
		justify-content: flex-start;
		gap: 0;
	}
`;

interface IProps {
	playerId: number;
}

export const PlayerOverview: React.FC<IProps> = ({playerId}) => {
	const player = useSelector(getPlayerById)(playerId);
	const {t} = useTranslation();
	const {isGoalkeeper, isDefender, isMidfielder, isForward} = getPlayerPositionMap(
		player?.position
	);
	const isMidfielderOrForward = isMidfielder || isForward;

	const totalPointsText = t("summary.team_card.total_points");
	const ppgText = t("player_profiles.overiview_stats.ppg");
	const sxiText = t("player_profiles.overiview_stats.sxi");
	const subText = t("player_profiles.overiview_stats.sub");
	const notSelected = t("player_profiles.overiview_stats.not_selected");

	return (
		<Wrapper className="overview">
			<Exist when={isGoalkeeper}>
				<Row>
					<Column title={totalPointsText} points={player?.stats?.total_points} />
					<Column title={ppgText} points={player?.stats?.avg_points} />
					<Column title={sxiText} points={player?.stats?.starting11} />
					<Column title={subText} points={player?.stats?.substitutions} />
					<Column title={notSelected} points={player?.stats?.picked_by} />
				</Row>
				<Row>
					<Column
						title={t("player_profiles.overiview_stats.clean_sheets")}
						points={player?.stats?.clean_sheets}
					/>
					<Column
						title={t("stats_centre.teams_table.tooltip_conceded")}
						points={player?.stats?.goals_conceded}
					/>
					<Column
						title={t("stats_centre.player_table.tooltip_og")}
						points={player?.stats?.own_goals}
					/>
					<Column
						title={t("stats_centre.player_table.tooltip_yellow")}
						points={player?.stats?.yellow_card}
					/>
					<Column
						title={t("stats_centre.player_table.tooltip_red")}
						points={player?.stats?.red_card}
					/>
				</Row>
			</Exist>
			<Exist when={isDefender}>
				<Row>
					<Column title={totalPointsText} points={player?.stats?.total_points} />
					<Column title={ppgText} points={player?.stats?.avg_points} />
					<Column title={sxiText} points={player?.stats?.starting11} />
					<Column title={subText} points={player?.stats?.substitutions} />
					<Column title={notSelected} points={player?.stats?.picked_by} />
				</Row>
				<Row>
					<Column
						title={t("player_profiles.overiview_stats.goals")}
						points={player?.stats?.goals}
					/>
					<Column
						title={t("player_profiles.overiview_stats.assists")}
						points={player?.stats?.assists}
					/>
					<Column
						title={t("player_profiles.overiview_stats.clean_sheets")}
						points={player?.stats?.clean_sheets}
					/>
					<Column
						title={t("player_profiles.overiview_stats.own_goals")}
						points={player?.stats?.own_goals}
					/>
					<Column
						title={t("player_profiles.overiview_stats.yellows")}
						points={player?.stats?.yellow_card}
					/>
					<Column
						title={t("player_profiles.overiview_stats.reds")}
						points={player?.stats?.red_card}
					/>
				</Row>
			</Exist>
			<Exist when={isMidfielderOrForward}>
				<Row>
					<Column title={totalPointsText} points={player?.stats?.total_points} />
					<Column title={ppgText} points={player?.stats?.avg_points} />
					<Column title={sxiText} points={player?.stats?.starting11} />
					<Column title={subText} points={player?.stats?.substitutions} />
					<Column title={notSelected} points={player?.stats?.picked_by} />
				</Row>
				<Row>
					<Column
						title={t("player_profiles.overiview_stats.goals")}
						points={player?.stats?.goals}
					/>
					<Column
						title={t("player_profiles.overiview_stats.assists")}
						points={player?.stats?.assists}
					/>
					<Column
						title={t("stats_centre.player_table.tooltip_og")}
						points={player?.stats?.own_goals}
					/>
					<Column
						title={t("stats_centre.player_table.tooltip_yellow")}
						points={player?.stats?.yellow_card}
					/>
					<Column
						title={t("stats_centre.player_table.tooltip_red")}
						points={player?.stats?.red_card}
					/>
				</Row>
			</Exist>
		</Wrapper>
	);
};

interface IColumnProps {
	title: string;
	points: number | undefined;
}

const StatsBlock = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 11px;

	h3 {
		color: ${({theme}) => theme.text_color};
		font-size: 18px;
		letter-spacing: 0;
		line-height: 22px;
		text-align: center;
		font-weight: bold;
		margin: 0;
	}

	p {
		color: ${({theme}) => theme.text_color};
		font-size: 12px;
		letter-spacing: 0;
		line-height: 17px;
		text-align: center;
		font-weight: normal;
		margin: 0;
	}

	@media screen and ${media.lessThanMD} {
		border-bottom: 1px solid #d8d8d8;
		width: 33%;
		padding: 16px 0;
	}
`;
const Column: React.FC<IColumnProps> = ({title, points}) => {
	const hasPoints = !(points === undefined || points === null);
	return (
		<StatsBlock>
			<h3>{hasPoints ? points : "-"}</h3>
			<p>{title || "&nbsp;"}</p>
		</StatsBlock>
	);
};
