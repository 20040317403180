import {IDictionary, IStore} from "modules/types";
import {createSelector} from "reselect";
import {MatchStatus, RoundStatus} from "modules/types/enums";
import {filter, find, findLast, first, flatMap, keyBy, last, memoize, property} from "lodash";
import {IMatch} from "modules/types/json";

const getState = ({round}: IStore) => round;

export const getRounds = createSelector(getState, (state) => state.rounds);

export const getActualRound = createSelector(getRounds, (rounds) =>
	rounds.find(({status}) => [RoundStatus.Active, RoundStatus.Scheduled].includes(status))
);

export const getIsActualRoundLocked = createSelector(getActualRound, (round) => {
	if (!round) {
		return true;
	}

	return round.status !== RoundStatus.Scheduled;
});

export const getIsActualRoundLastMatchActive = createSelector(getActualRound, (round) => {
	if (!round) {
		return false;
	}

	const lastMatch = last(round.matches);

	if (!lastMatch) {
		return false;
	}

	return lastMatch.status === MatchStatus.Active;
});

export const getRoundsFrom = createSelector(getRounds, (rounds) =>
	memoize((roundId: number = 0) => rounds.filter((round) => round.id >= roundId))
);

export const getIsRoundLockedById = createSelector(getRounds, (rounds) =>
	memoize((id: number = 0) => {
		const round = find(rounds, {id});
		if (!round) {
			return true;
		}

		return round.status !== RoundStatus.Scheduled;
	})
);

export const reducerRound = createSelector(getState, (state) => state.selectedRound);

export const getScoreRound = createSelector(
	getRounds,
	(rounds) =>
		find(rounds, ({status}) => [RoundStatus.Active, RoundStatus.Scheduled].includes(status)) ||
		last(rounds)
);

export const getScoreRounds = createSelector(getRounds, getActualRound, (rounds, actualRound) => {
	const scoreRounds = filter(rounds, ({status}) =>
		[RoundStatus.Active, RoundStatus.Complete].includes(status)
	);

	if (actualRound?.status === RoundStatus.Scheduled) {
		scoreRounds.push(actualRound);
	}

	return scoreRounds;
});

export const getSelectedRoundId = createSelector(
	getScoreRound,
	reducerRound,
	(scoreRound, reducerRound) => reducerRound || scoreRound?.id
);

export const isPreseason = createSelector(
	getRounds,
	(rounds) => first(rounds)?.status === RoundStatus.Scheduled
);

export const getActiveMatches = createSelector(getActualRound, (actualRound) =>
	filter(actualRound?.matches, {status: MatchStatus.Active})
);

export const getActiveAndCompleteMatches = createSelector(getActualRound, (actualRound) =>
	filter(actualRound?.matches, ({status}) =>
		[MatchStatus.Active, MatchStatus.Complete].includes(status)
	)
);

export const getIsSomeRoundActive = createSelector(getRounds, (rounds) => {
	const activeRound = rounds.find(({status}) => [RoundStatus.Active].includes(status));
	return Boolean(activeRound);
});

export const getScheduleRounds = createSelector(getRounds, (rounds) => {
	return rounds.filter((round) => round.status === RoundStatus.Scheduled);
});

export const getScheduledRoundsWithLeagueRound = createSelector(getRounds, (rounds) =>
	memoize((id: number = 0) => {
		const scheduledRounds = rounds.filter((round) => round.status === RoundStatus.Scheduled);
		const roundFromAll = find(rounds, {id});
		const round = find(scheduledRounds, {id});

		if (round || !roundFromAll) {
			return scheduledRounds;
		}

		return [roundFromAll, ...scheduledRounds];
	})
);

export const getNonCompleteRounds = createSelector(getRounds, (rounds) => {
	return rounds.filter((round) =>
		[RoundStatus.Scheduled, RoundStatus.Active].includes(round.status)
	);
});

export const getMatchesArray = createSelector(getRounds, (rounds) =>
	flatMap(rounds, ({matches}) => matches)
);

export const getMatchesById = createSelector(
	getRounds,
	(rounds): IDictionary<IMatch> => keyBy(flatMap(rounds, property("matches")), "id")
);

export const getMatchById = createSelector(
	getMatchesById,
	(matchesById) =>
		(matchId: number = 0) =>
			matchesById[matchId]
);

export const getRoundById = createSelector(
	getRounds,
	(rounds) => (round_id: number) => rounds.find((round) => round.id === round_id)
);

export const getSelectedRound = createSelector(getSelectedRoundId, getRoundById, (id, roundsById) =>
	id ? roundsById(id) : undefined
);

export const isSelectedRoundActive = createSelector(
	getSelectedRound,
	(round) => round?.status === RoundStatus.Active
);

export const getLastCompleteRound = createSelector(getRounds, (rounds) =>
	findLast(rounds, (round) => round.status === RoundStatus.Complete)
);

export const getLeaderboardRound = createSelector(
	getLastCompleteRound,
	getRoundById,
	(lastCompleteRound, getRoundByIdSelector) => (roundId: number) => {
		const round = getRoundByIdSelector(roundId);

		return round || lastCompleteRound;
	}
);

export const getIsSelectedRoundNoActive = createSelector(
	getActualRound,
	getSelectedRoundId,
	(actualRound, selectedRoundId) => actualRound?.id !== selectedRoundId
);
