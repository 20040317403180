import styled from "styled-components";
import {media} from "assets/media";

export const PlayingField = styled.div<{fieldBG: string; fieldBGMob: string}>`
	background: url(${(props) => props.fieldBG}) no-repeat center top;
	background-size: 100% 100%;
	border-radius: 4px;
	height: 630px;
	width: 100%;
	padding-top: 73px;
	position: relative;
	box-sizing: border-box;

	@media (max-width: 1500px) {
		height: 650px;
	}

	@media (max-width: 1000px) {
		height: 750px;
	}

	@media (max-width: 700px) {
		background-image: url(${(props) => props.fieldBGMob});
		padding-top: 75px;
		background-size: cover;
	}

	@media (max-width: 480px) {
		height: 550px;
		border-radius: 10px;
		padding-top: 40px;

		.field-row {
			gap: 32px;
		}

		&.wide .field-row {
			gap: 16px;
		}
	}
`;

export const FieldRow = styled.div`
	display: flex;
	justify-content: space-around;
	height: 21.5%;

	@media screen and ${media.lessThanMD} {
		justify-content: center;
		gap: 32px;
	}

	@media screen and (max-width: 480px) {
		height: 19%;
		gap: 16px;
	}
`;
