import React, {useCallback, useState} from "react";
import {IconRemove} from "components/Icons/IconRemove";
import {IconFilter} from "components/Icons";
import {useSelector} from "react-redux";
import {getAllSquads, getPositions} from "modules/selectors";
import {IPoolFilters} from "modules/types";
import styled from "styled-components";
import {Exist} from "components/Exist";
import {MenuItem} from "@mui/material";
import {media} from "assets/media";
import {Input, PrimaryButton} from "components/styled";
import {useTranslation} from "react-i18next";

const PlayersPoolFilters = styled.div`
	position: relative;
	padding: 14px 21px 6px 21px;
	background: #f8f8f8;
	border-bottom: 1px solid #dddddd;

	&:after {
		content: "";
		height: 1px;
		width: 100%;
		position: absolute;
		left: 0;
		bottom: 0;
		background: linear-gradient(90deg, #160259 0%, #0000b8 47.92%, #1eb6ff 100%);
	}
`;

const FilterRow = styled.div`
	display: flex;
	justify-content: space-between;
	gap: 21px;

	> div,
	button {
		width: 100%;
		margin-bottom: 20px;
	}

	> button {
		max-width: 85px;
	}

	@media screen and (max-width: ${media.md}) {
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 0;

		> div,
		button {
			margin-bottom: 12px;
		}

		&.main {
			flex-direction: row;
			gap: 12px;
		}
	}
`;

const IconRemoveWrapper = styled.div`
	transform: translateY(1px);
`;

interface IProps {
	updateFilter: (data: IPoolFilters) => void;
	filters: IPoolFilters;
}

interface IPriceRange {
	title: string;
	value: string;
	ariaSelected: boolean;
}

const priceRangeFilterMap: IPriceRange[] = [
	{title: "team.player_pool.filter_prices", value: "0-99", ariaSelected: true},
	{title: "team.player_pool.filter_affordable", value: "affordable", ariaSelected: true},
	{title: "team.player_pool.filter_above_12", value: "12-99", ariaSelected: false},
	{title: "team.player_pool.filter_above_5", value: "10-99", ariaSelected: false},
	{title: "team.player_pool.filter_4_5", value: "8-10", ariaSelected: false},
	{title: "team.player_pool.filter_3_5", value: "6-8", ariaSelected: false},
	{title: "team.player_pool.Filter_Under", value: "0-6", ariaSelected: false},
];
export const PoolFilters: React.FC<IProps> = ({updateFilter, filters}) => {
	const positions = useSelector(getPositions);
	const {t} = useTranslation();
	const squads = useSelector(getAllSquads);
	const [showFilters, setFiltersVisibility] = useState(false);
	const handleFiltersVisibility = useCallback(() => {
		setFiltersVisibility(!showFilters);
	}, [showFilters]);

	const onChangeAdapter = (e: React.ChangeEvent<HTMLInputElement>) => {
		const name = e.target.name;
		const value = e.target.value;

		if (!name) {
			return;
		}

		if (updateFilter) {
			updateFilter({
				...filters,
				[name]: value,
			});
		}
	};

	return (
		<PlayersPoolFilters>
			<FilterRow className="main">
				<Input
					value={filters.search}
					className={"search"}
					label={t("team.player_pool.search")}
					name={"search"}
					onChange={onChangeAdapter}
				/>
				<PrimaryButton onClick={handleFiltersVisibility}>
					<span>{t("team.player_pool.button_filter")} </span>
					<Exist when={showFilters}>
						<IconRemoveWrapper>
							<IconRemove width={16} height={16} />
						</IconRemoveWrapper>
					</Exist>
					<Exist when={!showFilters}>
						<IconFilter />
					</Exist>
				</PrimaryButton>
			</FilterRow>
			<Exist when={showFilters}>
				<FilterRow>
					<Input
						select
						onChange={onChangeAdapter}
						name={"position"}
						label="Position"
						value={filters.position}>
						<MenuItem value="0" aria-selected={false}>
							{t("stats_centre.players.filter_positions")}
						</MenuItem>
						{positions.map(({id, text}) => (
							<MenuItem key={id} value={id} aria-selected={false}>
								{t(text)}
							</MenuItem>
						))}
					</Input>
					<Input
						select
						onChange={onChangeAdapter}
						label="Club"
						name={"country"}
						value={filters.country}>
						<MenuItem value="0" aria-selected={true}>
							{t("stats_centre.players.filter_club", "All Clubs")}
						</MenuItem>
						{squads.map(({id, name}) => (
							<MenuItem key={id} value={id} aria-selected={false}>
								{name}
							</MenuItem>
						))}
					</Input>
				</FilterRow>
				<FilterRow>
					<Input
						select
						onChange={onChangeAdapter}
						label="Price"
						name={"price"}
						value={filters.price}>
						{priceRangeFilterMap.map((item) => (
							<MenuItem
								key={item.value}
								value={item.value}
								aria-selected={item.ariaSelected}>
								{t(item.title)}
							</MenuItem>
						))}
					</Input>
				</FilterRow>
			</Exist>
		</PlayersPoolFilters>
	);
};
