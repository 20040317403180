export enum Project {
	Classic = "classic",
	Gameday = "gameday",
}

export enum Sport {
	MC = "MC",
	IFL = "IFL",
}

export interface IWithSportPayload<T> {
	payload: T;
	sport: Sport;
}

export interface ISportWithIdPayload<T> extends IWithSportPayload<T> {
	id: number;
}

export interface IWithIdPayload<T> {
	id: number;
	payload: T;
}

export interface ISportAndId {
	id: number;
	sport: Sport;
}

export interface IWithNextPagePayload<T> {
	payload: T;
	newPage: boolean;
}

export interface IWithNextPayload<T> {
	payload: T;
	next: boolean;
}
