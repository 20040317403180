import React, {ChangeEvent, useCallback, useState} from "react";
import {Trans, useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import styled from "styled-components";
import {every, identity} from "lodash";
import {
	ModalActions,
	ModalBackdrop,
	ModalContent,
	ModalHeaderTitle,
} from "components/Modals/styled";
import {getAccountRecoverModalState} from "modules/selectors";
import {userAccountRecover} from "modules/actions";
import {PrimaryButton, TextFieldControl} from "components/styled";
import {IconInfo} from "components/Icons";
import {TextLink} from "components/Link";
import {Checkbox, FormControlLabel} from "@mui/material";
import {ReactComponent as UncheckedIcon} from "assets/images/icons/checkbox_unchecked.svg";
import {ReactComponent as CheckedIcon} from "assets/images/icons/checkbox_checked.svg";

const InnerWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0 12px;
	margin-top: 34px;
`;

const ModalTitle = styled(ModalHeaderTitle)`
	margin: 22px 0 22px;
`;

export const AccountRecoverModal: React.FC = () => {
	const dispatch = useDispatch();
	const {t} = useTranslation();
	const isOpen = useSelector(getAccountRecoverModalState);
	const [state, setState] = useState({
		terms: false,
	});
	const isDisabled = !every(state, identity);

	const submitHandler = useCallback(() => {
		dispatch(userAccountRecover());
	}, [dispatch]);

	const changeHandler = useCallback(
		(name: keyof typeof state) => (e: ChangeEvent<HTMLInputElement>) => {
			setState((prev) => ({
				...prev,
				[name]: e.target.checked,
			}));
		},
		[]
	);

	if (!isOpen) {
		return null;
	}

	return (
		<ModalBackdrop className="fade">
			<ModalContent>
				<InnerWrapper>
					<IconInfo color="#000000" width={66} height={66} />
					<ModalTitle>{t("return_modal.header.copy")}</ModalTitle>

					<TextFieldControl className="sized checkbox mb-0">
						<FormControlLabel
							control={
								<Checkbox
									checked={state.terms}
									onChange={changeHandler("terms")}
									icon={<UncheckedIcon />}
									checkedIcon={<CheckedIcon />}
								/>
							}
							label={
								<Trans
									i18nKey="return_modal.body.copy"
									components={{
										link1: (
											<TextLink
												href="/help/terms"
												target="_blank"
												rel="noopener noreferrer"
											/>
										),
									}}
								/>
							}
						/>
					</TextFieldControl>
				</InnerWrapper>

				<ModalActions>
					<PrimaryButton className="w-220" onClick={submitHandler} disabled={isDisabled}>
						{t("return_modal.button.continue")}
					</PrimaryButton>
				</ModalActions>
			</ModalContent>
		</ModalBackdrop>
	);
};
