import {createTheme} from "@mui/material";

declare module "@mui/material/styles" {
	// allow configuration using `createTheme`
	// eslint-disable-next-line @typescript-eslint/naming-convention
	interface ThemeOptions {
		[key: string]: unknown;
	}
}

const themeObject = {
	primary: "#0000B8",
	secondary: "#00095B",
	tertiary: "#C8FF0A",
	pink: "#FF009A",
	text_color: "#000151",
	text_red: "#F02F42",
	other_project: "#F02F42",
	text: {
		dark: "#222222",
		light: "F0F0F0",
		white: "#FFFFFF",
	},
	input: {
		label: "#222222",
		error: "#FF0000",
		border: "#D4D7DA",
	},
	button: {
		disabled_opacity: 0.2,
		primary: {
			default: "#0000B8",
			hover: "#68B4F9",
			disabled: "#D4D7DA",
			color: "#FFFFFF",
		},
		secondary: {
			default: "#FFFFFF",
			hover: "#68B4F9",
			disabled: "#D4D7DA",
			color: "#0000B8",
		},
		approval: {
			default: "#0000B8",
			hover: "#1EB6FF",
			disabled: "#D4D7DA",
			color: "#FFFFFF",
		},
		warning: {
			default: "#F02F42",
			hover: "#FF4343",
			disabled: "#F9ACB3",
			color: "#FFFFFF",
		},
	},
	header: {
		menu: {
			opened_item_color: "transparent",
			second_menu_color: "#00FFFF",
		},
		main: {
			background: "linear-gradient(270deg, #0000b8 1.99%, #160259 99.97%)",
			color: "#FFFFFF",
			border: "#00316B",
		},
		sub: {
			background: "linear-gradient(90deg, #0000B8 0%, #00FFFF 98.57%)",
			color: "#FFFFFF",
		},
		active_color: "#C8FF0A",
	},
	footer: {
		background: "#000151",
		color: "#FFFFFF",
		border_color: "#00FFFF",
	},
};

const gamedayObject = {
	...themeObject,
	other_project: "#000151",
	gradient: {
		start: "#F02F42",
		stop: "#F02F42",
	},
	header: {
		...themeObject.header,
		main: {
			background: "#F02F42",
			color: "#FFFFFF",
			border: "#00316B",
		},
	},
};

export const theme = createTheme(themeObject);
export const gamedayTheme = createTheme(gamedayObject);
type ThemeInterface = typeof themeObject;

declare module "styled-components" {
	// Allows change type of theme to avoid any
	/* eslint-disable @typescript-eslint/no-empty-interface */

	// eslint-disable-next-line @typescript-eslint/naming-convention
	export interface DefaultTheme extends ThemeInterface {}
}
