import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import {PlayerJersey, SquadLogo} from "components";
import {useSelector} from "react-redux";
import {useMediaQuery} from "@mui/material";
import {getPlayerById, isPlayerPlaying} from "modules/selectors";
import {PlayerStatus, StatsTab} from "modules/types/enums";
import {media} from "assets/media";
import {IconTeamHeads} from "components/Team/Player/IconTeamHeads";
import {PlayerEmpty} from "components/Team/Player/PlayerEmpty";
import {IconPlayerStatusOnField} from "components/Team/Player/IconPlayerStatusOnField";
import {
	EmptyPlayerName,
	getPlayerNameStyles,
	Wrapper,
	PlayerInfoTop,
	Figure,
	PlayerInfoWrapper,
	PlayerData,
} from "components/Team/Player/styled";
import {TableTeamStats} from "./TableTeamStats";

const PlayerName = styled(EmptyPlayerName)`
	${getPlayerNameStyles};

	@media screen and ${media.lessThanMD} {
		font-size: 10px;
	}
`;

const SquadLogoContainer = styled.div`
	position: absolute;
	left: 4px;
	bottom: 13px;
	display: flex;

	@media (max-width: 560px) {
		left: 1px;
		bottom: 7px;
	}
`;

const PlayerStatusContainer = styled.div`
	position: absolute;
	right: 4px;
	bottom: 13px;

	@media (max-width: 560px) {
		right: 1px;
		bottom: 7px;
	}
`;

const StatusIcon = styled(IconPlayerStatusOnField)`
	width: 17px;
	height: 17px;

	@media (max-width: 560px) {
		width: 12px;
		height: 12px;
	}
`;

const StyledSquadLogo = styled.div`
	width: 20px;
	height: 20px;

	img {
		width: 100%;
		height: 100%;
	}
`;

interface IProps {
	playerID: number;
	roundId: number;
}

const PlayerOnField: React.FC<IProps> = ({playerID, roundId}: IProps) => {
	const wrapperRef = useRef<HTMLButtonElement>(null);
	const isTouchScreen = useMediaQuery("(pointer: coarse)");
	const player = useSelector(getPlayerById)(playerID);
	const isPlaying = useSelector(isPlayerPlaying)(playerID);

	const isInjured = player?.status === PlayerStatus.Injured;
	const [isControlsOpened, setControlsOpenedState] = useState(false);

	useEffect(() => {
		if (!isTouchScreen) {
			return;
		}

		const handleClickOutside = (event: TouchEvent) => {
			const element = wrapperRef.current;

			if (event.target && element) {
				const isClickedInsideComponent = element.contains(event.target as Element);

				if (isClickedInsideComponent && !isControlsOpened) {
					event.preventDefault();
				}

				setControlsOpenedState(isClickedInsideComponent);
			}
		};

		document.addEventListener("touchend", handleClickOutside);
		return () => {
			document.removeEventListener("touchend", handleClickOutside);
		};
	}, [wrapperRef, isTouchScreen, isControlsOpened]);

	if (!player) {
		return <PlayerEmpty playerID={playerID} showStats={StatsTab.Weekly} />;
	}

	const {preferredName, squadId} = player;
	const wrapperClassName = isControlsOpened ? "active" : "";

	return (
		<Wrapper ref={wrapperRef} className={wrapperClassName} disabled>
			<IconTeamHeads playerId={playerID} />
			<PlayerInfoTop>
				<SquadLogoContainer>
					<StyledSquadLogo>
						<SquadLogo squadId={squadId} alt="Player icon" />
					</StyledSquadLogo>
				</SquadLogoContainer>
				<Figure>
					<PlayerJersey squadId={squadId} alt="Player icon" />
				</Figure>
				<PlayerStatusContainer>
					<StatusIcon status={player?.status} />
				</PlayerStatusContainer>
			</PlayerInfoTop>
			<PlayerInfoWrapper>
				<PlayerName isInjured={isInjured} isPlaying={isPlaying}>
					{preferredName}
				</PlayerName>
				<PlayerData>
					<TableTeamStats playerId={playerID} roundId={roundId} />
				</PlayerData>
			</PlayerInfoWrapper>
		</Wrapper>
	);
};

export const TablePlayer: React.FC<IProps> = (props) =>
	props.playerID ? <PlayerOnField {...props} /> : <PlayerEmpty {...props} />;
