import React, {useCallback} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import styled from "styled-components";
import {
	ModalActions,
	ModalBackdrop,
	ModalCloseButton,
	ModalContent,
	ModalHeaderTitle,
	ModalText,
} from "components/Modals/styled";
import {closeWelcomeModal} from "modules/actions/modals";
import {getWelcomeModalState} from "modules/selectors";
import {PrimaryButton, SecondaryLink} from "components/styled";
import {IconInfo, IconRemove} from "components/Icons";

const InnerWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0 12px;
	margin-top: 34px;
`;

const ModalTitle = styled(ModalHeaderTitle)`
	margin: 22px 0 12px;
`;

const Link = styled(SecondaryLink)`
	padding: 14px;
	font-size: 14px;
`;

export const WelcomeModal: React.FC = () => {
	const dispatch = useDispatch();
	const {t} = useTranslation();
	const isOpen = useSelector(getWelcomeModalState);

	const closeModal = useCallback(() => {
		dispatch(closeWelcomeModal());
	}, [dispatch]);

	if (!isOpen) {
		return null;
	}

	return (
		<ModalBackdrop className="fade">
			<ModalContent>
				<ModalCloseButton onClick={closeModal}>
					<IconRemove />
				</ModalCloseButton>

				<InnerWrapper>
					<IconInfo color="#000000" width={66} height={66} />
					<ModalTitle>{t("welcome.modal.header")}</ModalTitle>
					<ModalText>{t("welcome.modal.body")}</ModalText>
				</InnerWrapper>
				<ModalActions>
					<Link to="/help/guidelines" target="_blank" rel="noopener noreferrer">
						{t("welcome.modal.learn_more_button")}
					</Link>

					<PrimaryButton onClick={closeModal}>
						{t("welcome.modal.got_it_button")}
					</PrimaryButton>
				</ModalActions>
			</ModalContent>
		</ModalBackdrop>
	);
};
