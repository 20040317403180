import React, {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getPlayerById, getSquadById} from "modules/selectors";
import {currencyFormatDecimal} from "modules/utils";
import {resetTradeByIndex} from "modules/actions";
import {PlayerJersey} from "components/PlayerJersey";
import {Exist} from "components/Exist";
import {PureButton} from "components/styled";
import {IconRollback, IconTransfer} from "components/Icons";
import styled from "styled-components";
import {media} from "assets/media";
import {useTranslation} from "react-i18next";

const PlayerPrice = styled.p`
	color: ${({theme}) => theme.text_color};
	font-size: 13px;
	letter-spacing: 0;
	line-height: 19px;
	text-transform: uppercase;
	margin-left: auto;

	&.reverse {
		margin-right: auto;
		margin-left: 0;
	}

	@media screen and ${media.lessThanMD} {
		display: none;
	}
`;

const PlayerInfo = styled.div`
	display: flex;
	align-items: center;
	min-height: 50px;
	padding: 6px 0;
	box-sizing: border-box;

	img {
		top: 0;
		width: 38px !important;
		margin: 0 14px;
	}

	p {
		color: ${({theme}) => theme.text_color};
		font-size: 14px;
		letter-spacing: 0;
		line-height: 17px;
		margin: 0;

		b {
			font-size: 13px;
		}
	}

	&.reverse {
		text-align: right;
		flex-direction: row-reverse;
	}
`;

const PlayerWrapper = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;

	.price {
		//margin-left: auto;
	}

	&.reverse {
		flex-direction: row-reverse;

		.price {
			//margin-right: auto;
			//margin-left: 0;
		}
	}
`;

interface ITransferProps {
	tradeIn?: number;
	tradeOut?: number;
	tradeIndex: number;
	tradesLength: number;
	hasAction?: boolean;
}

export const Transfer: React.FC<ITransferProps> = ({tradeIn, tradeOut, tradeIndex, hasAction}) => {
	const dispatch = useDispatch();
	const {t} = useTranslation();
	const tradeInPlayerEntity = useSelector(getPlayerById)(Number(tradeIn));
	const tradeOutPlayerEntity = useSelector(getPlayerById)(Number(tradeOut));

	const tradeInPlayerSquad = useSelector(getSquadById)(tradeInPlayerEntity?.squadId);
	const tradeOutPlayerSquad = useSelector(getSquadById)(tradeOutPlayerEntity?.squadId);

	const inPrice = currencyFormatDecimal(tradeInPlayerEntity?.cost, true);
	const outPrice = currencyFormatDecimal(tradeOutPlayerEntity?.cost, true);

	const rollbackTransfer = useCallback(() => {
		dispatch(resetTradeByIndex(tradeIndex));

		// Navigate back if reset last transfer
		// if (tradesLength === 1 && tradeOut) {
		// 	dispatch(tradeOutPlayer(tradeOut));
		// 	dispatch(removePlayerFromTeam(tradeOut));
		// }
	}, [dispatch, tradeIndex]);

	if (!tradeOutPlayerEntity) {
		return null;
	}

	return (
		<tr>
			<td className="out">
				<PlayerWrapper>
					<PlayerInfo>
						<PlayerJersey squadId={tradeOutPlayerEntity?.squadId} />
						<div>
							<p>
								<b>{tradeOutPlayerEntity?.preferredName}</b>
							</p>
							<p className="desktop-only">{tradeOutPlayerSquad?.name}</p>
							<p className="mobile-only">ISK{inPrice}</p>
						</div>
					</PlayerInfo>
					<PlayerPrice className="price">ISK{outPrice}</PlayerPrice>
				</PlayerWrapper>
			</td>

			<td className="icon">
				<IconTransfer />
			</td>

			<Exist when={Boolean(tradeInPlayerEntity?.id)}>
				<td className="in">
					<PlayerWrapper className="reverse">
						<PlayerInfo className="reverse">
							<PlayerJersey squadId={tradeInPlayerEntity?.squadId} />
							<div>
								<p>
									<b>{tradeInPlayerEntity?.preferredName}</b>
								</p>
								<p className="desktop-only">{tradeInPlayerSquad?.name}</p>
								<p className="mobile-only">ISK{inPrice}</p>
							</div>
						</PlayerInfo>
						<PlayerPrice className="price reverse">ISK{inPrice}</PlayerPrice>
					</PlayerWrapper>
				</td>
			</Exist>

			<Exist when={!tradeInPlayerEntity?.id}>
				<td className="in">
					<PlayerWrapper className="reverse">
						<PlayerInfo className="reverse">
							<PlayerJersey squadId={0} />
							<div>
								<p>
									<b>{t("player_profiles.overiview_stats.not_selected")}</b>
								</p>
								<p className="mobile-only">ISKN/A</p>
								<p className="desktop-only">-</p>
							</div>
						</PlayerInfo>
						<PlayerPrice className="price reverse">ISKN/A</PlayerPrice>
					</PlayerWrapper>
				</td>
			</Exist>
			<Exist when={Boolean(hasAction)}>
				<td className="reset">
					<PureButton onClick={rollbackTransfer}>
						<IconRollback />
					</PureButton>
				</td>
			</Exist>
		</tr>
	);
};
