import {SagaIterator} from "redux-saga";
import {
	createLeagueSuccess,
	fetchJoinLeaguesClear,
	fetchJoinLeaguesConcat,
	fetchJoinLeaguesSuccess,
	fetchLeagueRanksSuccess,
	fetchLeaguesSuccess,
	fetchLeagueTableConcat,
	fetchLeagueTableSuccess,
	fetchLeagueUsersSuccess,
	globalError,
	// joinLeagueClear,
	JoinLeagueSuccess,
	leagueRemoveUserClear,
	leagueRemoveUserSuccess,
	leagueRequestGlobalStateHandler,
	leaveLeagueClear,
	leaveLeagueSuccess,
	sendLeagueInviteClear,
	sendLeagueInviteSuccess,
	updateLeagueSuccess,
} from "modules/actions";
import {getSport} from "modules/selectors";
import {call, delay, put, select} from "typed-redux-saga";
import {
	ICreateLeagueForm,
	ICreateLeagueRequestPayload,
	IFetchLeaguesGetParams,
	IFetchRanksRequestPayload,
	ILeagueInvitePayload,
	ILeagueRemoveUserPayload,
	IPaginationRequest,
	ISagaAction,
	ISportWithIdPayload,
	IWithIdPayload,
	IWithSportPayload,
} from "modules/types";
import {Api} from "modules/utils/Api";
import {DEFAULT_BUTTON_SUCCESS_DELAY} from "modules/constants";
import {openInfoModal} from "modules/actions/modals";
import {RequestStateType} from "modules/types/enums";
import {extractErrorMessage} from "modules/utils";
import {AxiosError} from "axios";
import {IApiResponse} from "modules/utils/Api/HTTPClient";

export const fetchLeagueSaga = function* ({
	payload,
}: ISagaAction<IFetchLeaguesGetParams>): SagaIterator {
	try {
		const filter: IFetchLeaguesGetParams = {
			privacy: payload.privacy === "all" ? undefined : payload.privacy,
			limit: payload.limit,
			offset: payload.offset,
		};
		const sport = yield* select(getSport);
		const response = yield* call(Api.League.fetch, {sport, payload: filter});
		yield* put(fetchLeaguesSuccess(response.data.success.leagues));
	} catch (e) {
		yield* put(globalError({message: extractErrorMessage(e as AxiosError<IApiResponse>)}));
	} finally {
		yield* delay(2000);
		yield* put(
			leagueRequestGlobalStateHandler({
				key: "fetch",
				state: RequestStateType.Idle,
			})
		);
	}
};

export const createLeagueSaga = function* ({
	payload,
}: ISagaAction<ICreateLeagueForm>): SagaIterator {
	try {
		const sport = yield* select(getSport);
		const data: IWithSportPayload<ICreateLeagueRequestPayload> = {
			sport,
			payload: {
				name: payload.name,
				privacy: payload.privacy,
				start_round_id: payload.startRound,
			},
		};
		const response = yield* call(Api.League.create, {...data});
		yield* put(createLeagueSuccess([response.data.success.league]));
	} catch (e) {
		yield* put(globalError({message: extractErrorMessage(e as AxiosError<IApiResponse>)}));
	} finally {
		yield* delay(2000);
		yield* put(
			leagueRequestGlobalStateHandler({
				key: "fetch",
				state: RequestStateType.Idle,
			})
		);
	}
};

export const updateLeagueSaga = function* ({
	payload,
}: ISagaAction<ISportWithIdPayload<ICreateLeagueRequestPayload>>): SagaIterator {
	try {
		const response = yield* call(Api.League.update, payload);
		yield* put(updateLeagueSuccess(response.data.success.league));
	} catch (e) {
		yield* put(globalError({message: extractErrorMessage(e as AxiosError<IApiResponse>)}));
	} finally {
		yield* delay(DEFAULT_BUTTON_SUCCESS_DELAY);
		yield* put(
			leagueRequestGlobalStateHandler({
				key: "update",
				state: RequestStateType.Idle,
			})
		);
	}
};

export const fetchJoinLeaguesSaga = function* ({
	payload,
}: ISagaAction<IPaginationRequest>): SagaIterator {
	try {
		const sport = yield* select(getSport);
		const response = yield* call(Api.League.fetchJoin, {sport, payload});
		if (payload.page === 1) {
			yield* put(fetchJoinLeaguesSuccess(response.data.success));
		} else {
			yield* put(fetchJoinLeaguesConcat(response.data.success));
		}
	} catch (e) {
		yield* put(globalError({message: extractErrorMessage(e as AxiosError<IApiResponse>)}));
	} finally {
		yield* put(fetchJoinLeaguesClear());
	}
};

export const joinLeagueSaga = function* ({payload}: ISagaAction<string>): SagaIterator {
	try {
		const sport = yield* select(getSport);
		const response = yield* call(Api.League.join, {sport, payload});
		yield* put(JoinLeagueSuccess(response.data.success.league));
	} catch (e) {
		yield* put(globalError({message: extractErrorMessage(e as AxiosError<IApiResponse>)}));
	} /*finally {
		yield* put(joinLeagueClear());
	}*/
};

export const leaveLeagueSaga = function* ({payload}: ISagaAction<number>): SagaIterator {
	try {
		const sport = yield* select(getSport);
		yield* call(Api.League.leave, {sport, payload});
		yield* put(leaveLeagueSuccess());
	} catch (e) {
		yield* put(globalError({message: extractErrorMessage(e as AxiosError<IApiResponse>)}));
	} finally {
		yield* put(leaveLeagueClear());
	}
};

export const sendLeagueInviteSaga = function* ({
	payload,
}: ISagaAction<IWithIdPayload<ILeagueInvitePayload[]>>): SagaIterator {
	try {
		const sport = yield* select(getSport);
		yield* call(Api.League.sendInvites, {sport, id: payload.id, payload: payload.payload});
		yield* put(sendLeagueInviteSuccess());
		yield* put(
			openInfoModal({
				title: "league.create.invites",
				text: "league.create.friends",
			})
		);
	} catch (e) {
		yield* put(globalError({message: extractErrorMessage(e as AxiosError<IApiResponse>)}));
	} finally {
		yield* put(sendLeagueInviteClear());
	}
};

export const fetchLeagueRanksSaga = function* ({
	payload,
}: ISagaAction<IWithIdPayload<IFetchRanksRequestPayload>>): SagaIterator {
	try {
		const sport = yield* select(getSport);
		const response = yield* call(Api.League.fetchRanks, {
			sport,
			id: payload.id,
			payload: payload.payload,
		});
		yield* put(fetchLeagueRanksSuccess(response.data.success.rankings));
	} catch (e) {
		yield* put(globalError({message: extractErrorMessage(e as AxiosError<IApiResponse>)}));
	} finally {
		yield* put(
			leagueRequestGlobalStateHandler({
				key: "fetchLeagueRanks",
				state: RequestStateType.Idle,
			})
		);
	}
};

export const fetchLeagueUsersSaga = function* ({
	payload,
}: ISagaAction<IWithIdPayload<IPaginationRequest>>): SagaIterator {
	try {
		const sport = yield* select(getSport);
		const response = yield* call(Api.League.fetchUsers, {
			sport,
			id: payload.id,
			payload: payload.payload,
		});
		yield* put(
			fetchLeagueUsersSuccess({
				payload: response.data.success.users,
				newPage: response.data.success.nextPage,
			})
		);
	} catch (e) {
		yield* put(globalError({message: extractErrorMessage(e as AxiosError<IApiResponse>)}));
	} finally {
		yield* put(
			leagueRequestGlobalStateHandler({
				key: "fetchLeagueUsers",
				state: RequestStateType.Idle,
			})
		);
	}
};

export const LeagueRemoveUserSaga = function* ({
	payload,
}: ISagaAction<ILeagueRemoveUserPayload>): SagaIterator {
	try {
		const sport = yield* select(getSport);
		yield* call(Api.League.removeLeagueUser, {
			sport,
			id: payload.leagueId,
			payload: payload.userId,
		});
		yield* put(leagueRemoveUserSuccess(payload.userId));
	} catch (e) {
		yield* put(globalError({message: extractErrorMessage(e as AxiosError<IApiResponse>)}));
	} finally {
		yield* delay(1000);
		yield* put(leagueRemoveUserClear());
	}
};

export const fetchLeagueTableSaga = function* ({
	payload,
}: ISagaAction<IWithIdPayload<IFetchRanksRequestPayload>>): SagaIterator {
	try {
		const sport = yield* select(getSport);
		const response = yield* call(Api.League.fetchTable, {
			id: payload.id,
			sport,
			payload: payload.payload,
		});

		const requestData = {
			next: response.data.success.next,
			payload: response.data.success.rankings,
		};

		if (payload.payload.offset !== 0) {
			yield* put(fetchLeagueTableConcat(requestData));
		} else {
			yield* put(fetchLeagueTableSuccess(requestData));
		}
	} catch (e) {
		yield* put(globalError({message: extractErrorMessage(e as AxiosError<IApiResponse>)}));
	} finally {
		yield* put(
			leagueRequestGlobalStateHandler({
				key: "fetchLeagueTable",
				state: RequestStateType.Idle,
			})
		);
	}
};
