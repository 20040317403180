import {fill, range, reduce, set, split} from "lodash";
import {FORMATION_LIST} from "modules/constants";
import {ILineup} from "modules/types/team";

export type IFormation = typeof FORMATION_LIST[number];
const createLineupFromFormation = (formation: IFormation): Readonly<ILineup> =>
	reduce(
		split(formation, "-"),
		(acc, arrayLength, index) => {
			set(acc, index + 1, fill(range(0, Number(arrayLength)), 0));
			return acc;
		},
		{}
	);

export const FORMATIONS = new Map(
	FORMATION_LIST.map((formation) => [formation, createLineupFromFormation(formation)])
);
