import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import {PlayerJersey, SquadLogo} from "components";
import {useDispatch, useSelector} from "react-redux";
import {IconPlayerStatusOnField} from "./IconPlayerStatusOnField";
import {noop} from "lodash";
import {useMediaQuery} from "@mui/material";
import {getIsSelectedRoundNoActive, getPlayerById, isPlayerPlaying} from "modules/selectors";
import {PlayerStatus, StatsTab} from "modules/types/enums";
import {
	EmptyPlayerName,
	Figure,
	getPlayerNameStyles,
	PlayerData,
	PlayerInfoTop,
	PlayerInfoWrapper,
	Wrapper,
} from "./styled";
import {PlayerEmpty} from "./PlayerEmpty";
import {Stats} from "components/Team/Player/Stats";
import {PlayerActions} from "./PlayerActions";
import {media} from "assets/media";
import {IconTeamHeads} from "components/Team/Player/IconTeamHeads";
import {openPlayerInfoModal} from "modules/actions/modals";

const PlayerName = styled(EmptyPlayerName)`
	${getPlayerNameStyles};

	@media screen and ${media.lessThanMD} {
		font-size: 10px;
	}
`;

const SquadLogoContainer = styled.div`
	position: absolute;
	left: 4px;
	bottom: 13px;
	display: flex;

	@media (max-width: 560px) {
		left: 1px;
		bottom: 7px;
	}
`;

const PlayerStatusContainer = styled.div`
	position: absolute;
	right: 4px;
	bottom: 13px;

	@media (max-width: 560px) {
		right: 1px;
		bottom: 7px;
	}
`;

const StatusIcon = styled(IconPlayerStatusOnField)`
	width: 17px;
	height: 17px;

	@media (max-width: 560px) {
		width: 12px;
		height: 12px;
	}
`;

const StyledSquadLogo = styled.div`
	width: 20px;
	height: 20px;

	img {
		width: 100%;
		height: 100%;
	}
`;

interface IProps {
	playerID: number;
	onClickEmptyCell?: () => void;
	onClickTransfer?: (playerID: number) => void;
	showStats: StatsTab;
	isRemoveDisabled?: boolean;
	isTransferDisabled?: boolean;
	isPublicTeam?: boolean;
}

const PlayerOnField: React.FC<IProps> = ({
	playerID,
	showStats,
	isRemoveDisabled = false,
	isTransferDisabled = true,
	onClickTransfer = noop,
	isPublicTeam = false,
	...rest
}: IProps) => {
	const dispatch = useDispatch();
	const wrapperRef = useRef<HTMLButtonElement>(null);
	const isTouchScreen = useMediaQuery("(pointer: coarse)");
	const player = useSelector(getPlayerById)(playerID);
	const isPlaying = useSelector(isPlayerPlaying)(playerID);
	const isSelectedRoundNoActive = useSelector(getIsSelectedRoundNoActive);

	const isInjured = player?.status === PlayerStatus.Injured;
	const [isControlsOpened, setControlsOpenedState] = useState(false);
	const isShowActions = !(isSelectedRoundNoActive || isPublicTeam);

	const showPlayerPopup = () => {
		if (isShowActions) {
			dispatch(openPlayerInfoModal(playerID));
		}
	};

	useEffect(() => {
		if (!isTouchScreen) {
			return;
		}

		const handleClickOutside = (event: TouchEvent) => {
			const element = wrapperRef.current;

			if (event.target && element) {
				const isClickedInsideComponent = element.contains(event.target as Element);

				if (isClickedInsideComponent && !isControlsOpened) {
					event.preventDefault();
				}

				setControlsOpenedState(isClickedInsideComponent);
			}
		};

		document.addEventListener("touchend", handleClickOutside);
		return () => {
			document.removeEventListener("touchend", handleClickOutside);
		};
	}, [wrapperRef, isTouchScreen, isControlsOpened]);

	if (!player) {
		return <PlayerEmpty playerID={playerID} showStats={showStats} {...rest} />;
	}

	const {preferredName, squadId, locked} = player;
	const isTransferLocked = isTransferDisabled || Boolean(locked);
	const isRemoveLocked = isRemoveDisabled || Boolean(locked);
	const wrapperClassName = isControlsOpened ? "active" : "";

	return (
		<Wrapper
			ref={wrapperRef}
			className={wrapperClassName}
			onClick={showPlayerPopup}
			disabled={!isShowActions}>
			{isShowActions && (
				<PlayerActions
					playerId={playerID}
					isRemoveDisabled={isRemoveLocked}
					isTransferDisabled={isTransferLocked}
					onClickTransfer={onClickTransfer}
				/>
			)}
			<IconTeamHeads playerId={playerID} />
			<PlayerInfoTop>
				<SquadLogoContainer>
					<StyledSquadLogo>
						<SquadLogo squadId={squadId} alt="Player icon" />
					</StyledSquadLogo>
				</SquadLogoContainer>
				<Figure>
					<PlayerJersey squadId={squadId} alt="Player icon" />
				</Figure>
				<PlayerStatusContainer>
					<StatusIcon status={player?.status} />
				</PlayerStatusContainer>
			</PlayerInfoTop>
			<PlayerInfoWrapper>
				<PlayerName isInjured={isInjured} isPlaying={isPlaying}>
					{preferredName}
				</PlayerName>
				<PlayerData>
					<Stats playerId={playerID} shownStats={showStats} />
				</PlayerData>
			</PlayerInfoWrapper>
		</Wrapper>
	);
};

export const Player: React.FC<IProps> = (props) =>
	props.playerID ? <PlayerOnField {...props} /> : <PlayerEmpty {...props} />;
