import {
	chain,
	filter,
	get,
	gt,
	identity,
	indexOf,
	isEmpty,
	lte,
	merge,
	range,
	reduce,
	set,
	size,
} from "lodash";
import {
	IError,
	IFetchRanksRequestPayload,
	IGameBarPayload,
	INewTeamRequest,
	ISagaAction,
} from "modules/types";
import {
	getAvailableFormation,
	getIsUserLogged,
	getPlayersById,
	getResetTransferDataSelector,
	getSport,
	getTeam,
	getTeamHeads,
	getTeamLineup,
	getTradePairsAccordingPosition,
	getTrades,
	hasEmptySpot,
	getWelcomeModalState,
	getAccountRecoverModalState,
	getActualRound,
} from "modules/selectors";
import {ILineup, INewTeam, ITeam} from "modules/types/team";
import {Api, ApiError} from "modules/utils/Api";

import {FORMATIONS, IFormation} from "modules/utils/Team";
import {IPlayer} from "modules/types/json";
import {SagaIterator} from "redux-saga";
import {call, delay, put, select, take} from "typed-redux-saga";
import {
	autoFillClear,
	autoFillSuccess,
	changeFormation,
	changeFormationSuccess,
	clearHeads,
	clearTrades,
	fetchGameBarSuccess,
	fetchLeaderboardConcat,
	fetchLeaderboardSuccess,
	globalError,
	handlePreAuthModal,
	makeTradeSuccess,
	removePlayerFromTeam,
	savedTeamSuccess,
	setHeads,
	setLastTradeEmptyState,
	setTeamComplete,
	setTeamSaved,
	setUserHasTeam,
	successResetTradeByIndex,
	teamFetchComplete,
	teamGlobalRequestStateHandler,
	toggleTeamSavedModal,
	tradeOutPlayer,
	updateLineup,
	toggleEditTeamNameModal,
	fetchTeamById,
	fetchTeamByIdFailed,
	fetchTeamByIdSuccess,
	fetchTeamHistory,
	fetchTeamHistorySuccess,
	fetchTeamHistoryFailed,
} from "modules/actions";
import {PlayerPosition, RequestStateType} from "modules/types/enums";
import {closeAccountRecoverModal, closeWelcomeModal} from "modules/actions/modals";
import {AxiosError} from "axios";
import {IApiResponse} from "modules/utils/Api/HTTPClient";
import {extractErrorMessage} from "modules/utils";

const getPositions = (positions: PlayerPosition[]) => {
	const positionsMap = {
		1: "Goalkeeper",
		2: "Defender",
		3: "Midfielder",
		4: "Striker",
	};

	return chain(positions)
		.map((position) => positionsMap[position])
		.join(", ")
		.value();
};

export const changeFormationSaga = function* (params: ISagaAction<IFormation>) {
	const {payload} = params;

	try {
		const formation = FORMATIONS.get(payload);

		if (!formation) {
			return;
		}

		const {lineup, ...rest} = yield* select(getTeam);

		const translationTransferError = "Error while changing formation";

		const outPosition: number[] = [];
		const inPosition: number[] = [];

		const newLineup = reduce<ILineup, ILineup>(
			formation,
			(acc, newLine, key) => {
				const positionID = Number(key);
				const currentLine = lineup[key];
				const currentLineWithPlayers = filter(currentLine, identity);

				const emptyLineSize = size(newLine);
				const currentLineSize = size(currentLine);
				const playersLineSize = size(currentLineWithPlayers);

				const availablePositionsDiff = emptyLineSize - currentLineSize;
				const filledPositionsDiff = playersLineSize - emptyLineSize;

				if (gt(availablePositionsDiff, 0)) {
					inPosition.push(...range(0, availablePositionsDiff).fill(positionID));
				}

				if (lte(filledPositionsDiff, 0)) {
					acc[key] = merge([], newLine, currentLineWithPlayers);
				} else {
					outPosition.push(...range(0, filledPositionsDiff).fill(positionID));
				}

				return acc;
			},
			{}
		);

		const isRequireReplace = [isEmpty(outPosition), isEmpty(inPosition)].every((val) => !val);

		if (isRequireReplace) {
			const invalidLineupMessage = `In order to change to this formation you must transfer out a ${getPositions(
				outPosition
			)} for a ${getPositions(inPosition)}`;

			const errorMessage = translationTransferError + `<p>${invalidLineupMessage}</p>`;

			throw new ApiError(errorMessage);
		}

		const newTeam: ITeam | INewTeam = {
			...rest,
			lineup: newLineup,
			formation: payload,
		};

		yield put(changeFormationSuccess(newTeam));
	} catch (e) {
		yield put(globalError(e as IError));
	}
};

const findAndReplaceInTeamSaga = function* (
	player_id: number,
	replaceFrom: number,
	replaceTo: number
) {
	try {
		const playersById = yield* select(getPlayersById);
		const lineup: ILineup = yield* select(getTeamLineup);
		const player: IPlayer = playersById[player_id];

		if (!player) {
			throw new Error("Picked player not found!");
		}

		const {position} = player;
		const isAddPlayerAction = !replaceFrom;

		if (isAddPlayerAction && !hasEmptySpot(lineup, position)) {
			const newFormation = getAvailableFormation(lineup, position);

			if (newFormation) {
				yield call(changeFormationSaga, changeFormation(newFormation));
			} else {
				throw new Error("Can't define allowed formation.");
			}
		}

		const actualLineup: ILineup = yield* select(getTeamLineup);
		const line = [...actualLineup[position]];

		const newLineup: ILineup = {
			...actualLineup,
			[position]: set(line, indexOf(line, replaceFrom), replaceTo),
		};

		yield put(updateLineup(newLineup));
	} catch (e) {
		yield* put(globalError({message: extractErrorMessage(e as AxiosError<IApiResponse>)}));
		yield* put(removePlayerFromTeam(player_id));
		yield* put(tradeOutPlayer(player_id));
	}
};

export const setPlayerToTeamSaga = function* ({payload}: ISagaAction<number>) {
	yield call(findAndReplaceInTeamSaga, payload, 0, payload);
};

export const transferPlayerToTeamSaga = function* ({payload}: ISagaAction<number>) {
	yield* call(findAndReplaceInTeamSaga, payload, 0, payload);
	const trades = yield* select(getTradePairsAccordingPosition);

	if (trades.length === 0) {
		yield* put(setLastTradeEmptyState(true));
		yield* delay(1000);
		yield* put(setLastTradeEmptyState(false));
		yield* put(setTeamSaved());
	}
};

export const removePlayerFromTeamSaga = function* ({payload}: ISagaAction<number>) {
	yield call(findAndReplaceInTeamSaga, payload, payload, 0);
	const heads = yield* select(getTeamHeads);

	if (payload === heads.captain) {
		yield* put(clearHeads());
	}

	if (payload === heads.viceCaptain) {
		yield* put(
			setHeads({
				...heads,
				viceCaptain: undefined,
			})
		);
	}
};

export const getTeamSaga = function* (): SagaIterator {
	try {
		const sport = yield* select(getSport);
		const response = yield* call(Api.Team.get, sport);
		const team = response.data.success.team;

		yield* put(
			setHeads({
				captain: Number(team.lineup.captain) || 0,
				viceCaptain: Number(team.lineup.vice_captain) || 0,
			})
		);

		yield* put(savedTeamSuccess(team));

		if (team.isCompleted) {
			yield* put(setUserHasTeam(true));
		}

		yield* put(setTeamComplete(Boolean(team.isCompleted)));
	} catch (e) {
		yield* put(globalError({message: extractErrorMessage(e as AxiosError<IApiResponse>)}));
	} finally {
		yield* put(teamFetchComplete());
	}
};

export const getTeamHistorySaga = function* ({
	payload,
}: ReturnType<typeof fetchTeamHistory>): SagaIterator {
	try {
		const actualRound = yield* select(getActualRound);
		const actualRoundId = get(actualRound, "id", 0);

		if (actualRoundId === payload.roundId) {
			yield* call(getTeamSaga);
			return;
		}

		const sport = yield* select(getSport);
		const response = yield* call(Api.Team.getHistory, {...payload, sport});
		const team = response.data.success.team;

		yield* put(
			setHeads({
				captain: Number(team.lineup.captain) || 0,
				viceCaptain: Number(team.lineup.vice_captain) || 0,
			})
		);

		yield* put(fetchTeamHistorySuccess(team));
	} catch (e) {
		yield* put(globalError({message: extractErrorMessage(e as AxiosError<IApiResponse>)}));
		yield* put(fetchTeamHistoryFailed());
	}
};

export const getTeamByIdSaga = function* ({payload}: ReturnType<typeof fetchTeamById>) {
	try {
		const sport = yield* select(getSport);
		const response = yield* call(Api.Team.getById, {...payload, sport});
		const team = response.data.success.team;

		yield* put(
			setHeads({
				captain: Number(team.lineup.captain) || 0,
				viceCaptain: Number(team.lineup.vice_captain) || 0,
			})
		);

		yield* put(fetchTeamByIdSuccess(team));
	} catch (e) {
		yield* put(fetchTeamByIdFailed());
		yield* put(globalError({message: extractErrorMessage(e as AxiosError<IApiResponse>)}));
	}
};

const preAuthorizeSaga = function* () {
	const isAuthorized = yield* select(getIsUserLogged);

	if (!isAuthorized) {
		yield* put(handlePreAuthModal(true));
	}
};

const getUserName = (team: INewTeam): string => {
	return team.name || `Besta Deildin Fantasy ${team.id || 1}`;
};

export const saveTeamSaga = function* (): SagaIterator {
	try {
		const team = yield* select(getTeam);
		const heads = yield* select(getTeamHeads);
		yield* call(preAuthorizeSaga);
		const isAuthorized = yield* select(getIsUserLogged);

		if (!isAuthorized) {
			return;
		}

		const params: INewTeamRequest = {
			name: getUserName(team),
			formation: team.formation,
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			lineup: {
				...reduce(
					team.lineup,
					(acc, value, key) => {
						set(acc, key, filter(value, identity));
						return acc;
					},
					{}
				),
				captain: heads.captain || undefined,
				vice_captain: heads.viceCaptain || undefined,
			},
		};

		const sport = yield* select(getSport);
		const response = yield* call(Api.Team.update, {sport, payload: params});
		if (response.data.success.team) {
			yield* put(savedTeamSuccess(response.data.success.team));
			yield* put(toggleTeamSavedModal(true));
		}
	} catch (e) {
		yield* put(globalError({message: extractErrorMessage(e as AxiosError<IApiResponse>)}));
	} finally {
		yield* put(teamFetchComplete());
	}
};

export const autoFillSaga = function* () {
	try {
		const isLogged = yield* select(getIsUserLogged);
		const sport = yield* select(getSport);
		const team = yield* select(getTeam);
		const heads = yield* select(getTeamHeads);

		const {captain, vice_captain, ...lineup} = team.lineup;
		const requestUrl = isLogged ? Api.Team.autoPick : Api.Team.autoPickFree;
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		const response: Awaited<ReturnType<typeof requestUrl>> = yield* call(requestUrl, {
			sport,
			payload: {
				lineup: {
					...lineup,
					captain: heads.captain || 0,
					vice_captain: heads.viceCaptain || 0,
				},
				formation: team.formation,
			},
		});
		yield* put(autoFillSuccess(response.data.success.team));
		yield* put(
			setHeads({
				captain: Number(response.data.success.team.lineup.captain),
				viceCaptain: Number(response.data.success.team.lineup.vice_captain),
			})
		);
		// if (!isLogged) {
		// 	yield* put(editModalSavingMode(true));
		// }
	} catch (e) {
		yield* put(globalError({message: extractErrorMessage(e as AxiosError<IApiResponse>)}));
	} finally {
		yield* put(autoFillClear());
	}
};

export const resetTradeByIndexSaga = function* ({payload}: ISagaAction<number>) {
	const getResetTransferData = yield* select(getResetTransferDataSelector);
	const resetTransferData = getResetTransferData(payload);

	if (!resetTransferData) {
		return;
	}

	yield put(successResetTradeByIndex(resetTransferData));
};

export const makeTradeSaga = function* (): SagaIterator {
	try {
		const trades = yield* select(getTrades);
		const sport = yield* select(getSport);
		const response = yield* call(Api.Team.makeTrade, {
			sport,
			payload: {
				playersIn: trades.tradeIn,
				playersOut: trades.tradeOut,
			},
		});
		yield* put(makeTradeSuccess(response.data.success));
		yield* put(clearTrades());
	} catch (e) {
		yield* put(globalError({message: extractErrorMessage(e as AxiosError<IApiResponse>)}));
	} finally {
		yield* delay(500);
		yield* put(
			teamGlobalRequestStateHandler({
				key: "trade",
				state: RequestStateType.Idle,
			})
		);
	}
};

export const fetchGameBarSaga = function* ({payload}: ISagaAction<IGameBarPayload>): SagaIterator {
	try {
		const sport = yield* select(getSport);
		const response = yield* call(Api.Team.fetchGameBar, {sport, payload});
		yield* put(fetchGameBarSuccess(response.data.success.gamebar));
	} catch (e) {
		yield* put(globalError({message: extractErrorMessage(e as AxiosError<IApiResponse>)}));
	}
};

export const fetchLeaderboardSaga = function* ({
	payload,
}: ISagaAction<IFetchRanksRequestPayload>): SagaIterator {
	try {
		const sport = yield* select(getSport);
		const response = yield* call(Api.Team.fetchLeaderboard, {sport, payload});

		const requestData = {
			next: response.data.success.next,
			payload: {
				rankings: response.data.success.rankings,
				user: response.data.success.user,
			},
		};

		if (payload.offset !== 0) {
			yield* put(fetchLeaderboardConcat(requestData));
		} else {
			yield* put(fetchLeaderboardSuccess(requestData));
		}
	} catch (e) {
		yield* put(globalError({message: extractErrorMessage(e as AxiosError<IApiResponse>)}));
	} finally {
		yield* put(
			teamGlobalRequestStateHandler({
				key: "leaderboard",
				state: RequestStateType.Idle,
			})
		);
	}
};

export const showTeamNameModalSaga = function* (): SagaIterator {
	try {
		const isOpenAccountRecoverModal = yield* select(getAccountRecoverModalState);

		if (isOpenAccountRecoverModal) {
			yield* take(closeAccountRecoverModal);
			yield* delay(300);
		}

		const isOpenWelcomeModal = yield* select(getWelcomeModalState);

		if (isOpenWelcomeModal) {
			yield* take(closeWelcomeModal);
		}

		yield* put(toggleEditTeamNameModal(true));
	} catch (e) {
		console.error(e);
	}
};
