import React, {useCallback, useEffect} from "react";
import {
	ModalActions,
	ModalBackdrop,
	ModalCloseButton,
	ModalContent,
	ModalHeaderTitle,
	ModalText,
} from "components/Modals/styled";
import {IconRemove} from "components/Icons";
import {useDispatch, useSelector} from "react-redux";
import {closeRemovePlayerModal} from "modules/actions/modals";
import {SecondaryButton, WarningButton} from "components/styled";
import styled from "styled-components";
import warningImage from "assets/images/icons/warning.png";
import {getLeagueRequestStateByKey, getRemovePlayerModalState} from "modules/selectors";
import {leagueRemoveUserRequest} from "modules/actions";
import {Exist} from "components/Exist";
import {CircularPreloaded} from "components/Preloader";
import {useTranslation} from "react-i18next";

const InnerWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0 12px;

	img {
		padding-top: 20px;
	}
`;

export const RemovePlayerModal: React.FC = () => {
	const modalData = useSelector(getRemovePlayerModalState);
	const {isLoading, isSuccess} = useSelector(getLeagueRequestStateByKey)("removeLeagueUser");
	const dispatch = useDispatch();
	const {t} = useTranslation();
	const closeModal = useCallback(() => {
		dispatch(closeRemovePlayerModal());
	}, [dispatch]);

	const removePlayer = useCallback(() => {
		const {userId, leagueId} = modalData.data;

		if (!userId || !leagueId) {
			closeModal();
			return;
		}

		dispatch(
			leagueRemoveUserRequest({
				userId: Number(userId),
				leagueId: Number(leagueId),
			})
		);
	}, [closeModal, modalData, dispatch]);

	useEffect(() => {
		if (isSuccess) {
			closeModal();
		}
	}, [isSuccess, closeModal]);

	if (!modalData.isShow) {
		return null;
	}

	return (
		<ModalBackdrop className="fade">
			<ModalContent>
				<ModalCloseButton onClick={closeModal}>
					<IconRemove />
				</ModalCloseButton>
				<InnerWrapper>
					<img src={warningImage} alt="warning" />
					<ModalHeaderTitle>{t("leagues.modal.title")}</ModalHeaderTitle>
					<ModalText>
						{t("leagues.modal.title")}
					</ModalText>
				</InnerWrapper>
				<ModalActions>
					<SecondaryButton disabled={isLoading} onClick={closeModal}>
						{t("leagues.modal.button_cancel")}
					</SecondaryButton>
					<WarningButton disabled={isLoading} onClick={removePlayer}>
						<Exist when={isLoading}>
							<CircularPreloaded size={16} color="#FFFFFF" />
						</Exist>
						<Exist when={!isLoading}>{t("leagues.modal.button_remove")}</Exist>
					</WarningButton>
				</ModalActions>
			</ModalContent>
		</ModalBackdrop>
	);
};
