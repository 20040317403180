import React, {useCallback} from "react";
import styled from "styled-components";
import {PureButton} from "components/styled";
import {StatsTab} from "modules/types/enums";
import {setActiveTabAction} from "modules/actions";
import {useDispatch, useSelector} from "react-redux";
import {getShownStats} from "modules/selectors";
import {useTranslation} from "react-i18next";

const Wrapper = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: flex-end;
	margin-bottom: -19px;
`;

const TeamTab = styled(PureButton)`
	min-width: 120px;
	display: flex;
	color: ${({theme}) => theme.secondary};
	padding: 14px 12px;
	font-size: 14px;
	letter-spacing: 0;
	text-align: center;
	font-weight: 400;
	border: 1px solid transparent;

	&.active {
		border-color: #d8d8d8;
		border-top: 5px solid ${({theme}) => theme.tertiary};
		border-radius: 5px 5px 0 0;
	}

	&:disabled {
		color: #dddddd;
		pointer-events: none;
	}
`;

export const TeamTabs: React.FC = () => {
	const dispatch = useDispatch();
	const {t} = useTranslation();
	const activeTab = useSelector(getShownStats);

	const changeTab = useCallback(
		(e: React.SyntheticEvent<HTMLButtonElement>) => {
			const tab = e.currentTarget.dataset.tab;
			if (tab === undefined) {
				return;
			}
			dispatch(setActiveTabAction(tab as unknown as StatsTab));
		},
		[dispatch]
	);

	const isTabActive = (tab: StatsTab): string => (tab === activeTab ? "active" : "");

	return (
		<Wrapper>
			<TeamTab
				onClick={changeTab}
				data-tab={StatsTab.Weekly}
				className={isTabActive(StatsTab.Weekly)}>
				{t("team.pitch.round_overall")}
			</TeamTab>
			<TeamTab
				onClick={changeTab}
				data-tab={StatsTab.TransferValue}
				className={isTabActive(StatsTab.TransferValue)}>
				{t("team.pitch.transfer_value")}
			</TeamTab>
		</Wrapper>
	);
};
