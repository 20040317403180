import styled from "styled-components";
import {media} from "assets/media";

export const StepWrapper = styled.div`
	box-sizing: border-box;
	max-width: 405px;
	min-height: 600px;
	width: 100%;
	padding: 12px 14px;
	border: 1px solid #dddddd;
	border-radius: 4px;
	margin-bottom: 50px;

	&.disabled {
		opacity: 0.3;
		pointer-events: none;

		&.mobile {
			display: none;
		}
	}

	@media screen and ${media.lessThanMD} {
		border: none;
		padding: 12px 20px;
	}
`;

export const StepTitle = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 18px;

	p {
		color: ${({theme}) => theme.text_color};
		font-size: 14px;
		letter-spacing: 0;
		line-height: 17px;
		white-space: nowrap;
		margin: 0;

		@media screen and ${media.lessThanMD} {
			font-size: 12px;
		}
	}

	div {
		width: 100%;
		height: 1px;
		border-bottom: 1px solid ${({theme}) => theme.text_color};
	}
`;
