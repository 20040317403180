import React, {useCallback} from "react";
import styled from "styled-components";
import {Exist, LockoutTimer, TeamCaptainSelect, TeamStats} from "components";
import {useDispatch, useSelector} from "react-redux";
import {
	getIsUserHasTeam,
	getRoundsSelectorScoredRounds,
	getSelectedRoundId,
	getTeam,
} from "modules/selectors";
import {fetchTeamHistory, selectRound, toggleEditTeamNameModal} from "modules/actions";
import {Input, PureButton} from "components/styled";
import {ReactComponent as FacebookIcon} from "assets/images/Facebook.svg";
import {ReactComponent as TwitterIcon} from "assets/images/icons/x.svg";
import ShareIcon from "assets/images/share.svg";
import {MenuItem, useMediaQuery} from "@mui/material";
import {ShareTarget, ShareType} from "modules/types/share";
import {share, shareNavigator} from "modules/utils/Share";
import {useIsPathIncludes} from "modules/utils/hooks";
import {media} from "assets/media";
import {useTranslation} from "react-i18next";

const Wrapper = styled.div``;

const TeamHeadInfo = styled.div`
	box-sizing: border-box;
	display: flex;
	border-radius: 5px 5px 0 0;
	background: ${({theme}) => theme.secondary};
	padding: 20px 19px;
	gap: 12px;
	justify-content: space-between;

	h2 {
		color: #ffffff;
		font-size: 24px;
		font-weight: bold;
		letter-spacing: 0;
		margin: 0;
	}

	button {
		color: #ffffff;
		font-size: 14px;
		letter-spacing: 0;
		line-height: 17px;
		text-decoration: none;
		align-self: flex-end;

		&.edit-button {
			position: relative;
			top: -9px;
			left: 2px;

			@media screen and (max-width: 1000px) {
				top: 0;
				left: 0;
			}
		}

		&:hover {
			text-decoration: underline;
		}
	}

	> div {
		display: flex;
		gap: 8px;
		align-items: center;

		@media screen and (max-width: 1000px) {
			display: block;
		}
	}
`;

const SocialButton = styled(PureButton)`
	cursor: pointer;
	width: 20px;
	height: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	align-self: center !important;
	margin: 0 10px;

	img {
		width: auto;
		height: 100%;

		&.mobile-share {
			height: 30px;
		}
	}
	&.tw {
		svg {
			width: 16px;
		}
	}
	svg {
		transition: all 0.2s ease-in-out;

		&:hover {
			color: ${({theme}) => theme.tertiary};
			fill: ${({theme}) => theme.tertiary};
		}
	}
`;

const ActionWrapper = styled.div`
	display: flex !important;
	justify-content: flex-end;
	align-items: center;
`;

const SInput = styled(Input)`
	background: #ffffff;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;

	.MuiSelect-select {
		padding-top: 8px;
		padding-bottom: 10px;
	}
`;

const TeamCaptainSelectWrapper = styled.div`
	padding: 8px 20px;
`;

const TeamNameStyled = styled.h2`
	max-width: 150px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
`;

export const TeamInfo: React.FC = () => {
	const dispatch = useDispatch();
	const {t} = useTranslation();
	const team = useSelector(getTeam);
	const isUserHasTeam = useSelector(getIsUserHasTeam);
	const isMobile = useMediaQuery(media.lessThanMD);
	const isCaptainsWidth = useMediaQuery("(max-width: 750px)");

	const rounds = useSelector(getRoundsSelectorScoredRounds);
	const selectedRound = useSelector(getSelectedRoundId);
	const isTransferFlag = useIsPathIncludes("transfers");

	const onRoundChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			const value = Number(e.target.value);

			if (!value) {
				return;
			}

			dispatch(selectRound(value));
			dispatch(fetchTeamHistory({roundId: value}));
		},
		[dispatch]
	);

	const shareHandler = (e: React.SyntheticEvent<HTMLButtonElement>) => {
		const target = e.currentTarget.dataset.target;

		if (!target) {
			return;
		}
		share({
			target: target as ShareTarget,
			type: ShareType.General,
		});
	};

	const shareClickMobile = () => {
		if ("share" in navigator) {
			// eslint-disable-next-line @typescript-eslint/no-floating-promises
			shareNavigator({type: ShareType.General, target: ShareTarget.Facebook});
		}
	};

	const openModal = useCallback(() => {
		dispatch(toggleEditTeamNameModal(true));
	}, [dispatch]);

	const teamName = team?.name || "Nameyourteam";
	return (
		<Wrapper>
			<TeamHeadInfo>
				<div>
					<TeamNameStyled>{teamName}</TeamNameStyled>
					<PureButton className="edit-button" onClick={openModal}>
						{t("team.summary.edit")}
					</PureButton>
				</div>
				<ActionWrapper>
					<Exist when={isUserHasTeam && !isTransferFlag}>
						<Exist when={!isMobile}>
							<SocialButton data-target={ShareTarget.Facebook} onClick={shareHandler}>
								<FacebookIcon />
							</SocialButton>
							<SocialButton
								className="tw"
								data-target={ShareTarget.Twitter}
								onClick={shareHandler}>
								<TwitterIcon />
							</SocialButton>
						</Exist>
						<Exist when={isMobile}>
							<SocialButton onClick={shareClickMobile}>
								<img src={ShareIcon} alt="Share" className={"mobile-share"} />
							</SocialButton>
						</Exist>

						<Exist when={Boolean(rounds.length)}>
							<SInput select value={selectedRound || 0} onChange={onRoundChange}>
								{rounds.map((round) => (
									<MenuItem key={round.id} value={round.id}>
										{t(`team.summary.filter_gw${round.number}`)}
									</MenuItem>
								))}
							</SInput>
						</Exist>
					</Exist>
				</ActionWrapper>
			</TeamHeadInfo>
			<TeamStats />
			<Exist when={isCaptainsWidth && !isTransferFlag}>
				<TeamCaptainSelectWrapper>
					<TeamCaptainSelect />
				</TeamCaptainSelectWrapper>
			</Exist>
			<Exist when={isMobile}>
				<LockoutTimer />
			</Exist>
		</Wrapper>
	);
};
