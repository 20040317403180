import {IconRemove} from "components";
import {PureButton} from "components/styled";
import {getCurrentMessage, isMessageDismissed} from "modules/selectors";
import {dismissMessageBar} from "modules/actions";
import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import styled, {css, keyframes} from "styled-components";

const fadeOut = keyframes`
	0% { transform: translateX(0); opacity: 1;}
	100% { transform: translateX(-50%); opacity: 0; }
`;

interface IMessageBarWrapper {
	animate: boolean;
}

const getAnimationStyles = ({animate}: IMessageBarWrapper) =>
	animate &&
	css`
		animation: ${fadeOut} 0.8s ease-in;
	`;

const Wrapper = styled.div`
	position: relative;
	margin: 20px 0;
	background-color: #fff;
	padding: 16px 60px 16px 20px;
	border: 2px solid #dddddd;
	${getAnimationStyles};

	@media (max-width: 700px) {
		margin-left: 10px;
		margin-right: 10px;
		padding: 16px 20px;
	}
`;

const Title = styled.h5`
	font-size: 14px;
	line-height: 17px;
	margin-bottom: 6px;
`;

const Content = styled.div`
	font-size: 12px;
	line-height: 16px;
`;

const DeleteButton = styled(PureButton)`
	position: absolute;
	top: 24px;
	right: 24px;

	@media (max-width: 700px) {
		top: 10px;
		right: 10px;
	}
`;

export const MessageBar: React.FC = () => {
	const dispatch = useDispatch();
	const messageBar = useSelector(getCurrentMessage);
	const hideMessage = useSelector(isMessageDismissed);
	const [animate, setAnimate] = useState(false);
	const {enabled, version, title, text} = messageBar;

	const removeMessageBar = () => dispatch(dismissMessageBar(version));
	const fadeOutMessageBar = () => setAnimate(true);

	if (!enabled || hideMessage) {
		return null;
	}

	return (
		<Wrapper animate={animate} onAnimationEnd={removeMessageBar}>
			<DeleteButton onClick={fadeOutMessageBar}>
				<IconRemove />
			</DeleteButton>
			<Title>{title}</Title>
			<Content dangerouslySetInnerHTML={{__html: text}} />
		</Wrapper>
	);
};
