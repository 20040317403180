import React from "react";
import styled from "styled-components";
import {PaddingContainer} from "components/styled";
import footerLogo from "assets/images/footer_logo.svg";
import fanhub from "assets/images/genius.svg";
import {ReactComponent as Twitter} from "assets/images/icons/x.svg";
import {ReactComponent as Facebook} from "assets/images/icons/facebook.svg";
import {Link, useLocation} from "react-router-dom";
import {media} from "assets/media";
import {NO_HEADER_PATHS} from "modules/constants";
import {useTranslation} from "react-i18next";

const Wrapper = styled.footer`
	background-color: ${({theme}) => theme.secondary};
	padding-top: 50px;
	padding-bottom: 20px;
	color: #fff;

	@media (max-width: ${media.sm}) {
		padding-top: 30px;
		padding-bottom: 26px;
	}
`;

const Logos = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 32px;

	@media (max-width: ${media.sm}) {
		flex-direction: column;
		gap: 60px;
		margin-bottom: 20px;
	}
`;

const GameLogos = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
`;

const SocialLinks = styled.div`
	display: flex;
	align-items: center;
	gap: 16px;

	svg {
		transition: all 0.2s ease-in-out;

		&:hover {
			color: ${({theme}) => theme.tertiary};
			fill: ${({theme}) => theme.tertiary};
		}
	}
`;

const FooterText = styled.p`
	font-size: 14px;
	line-height: 17px;
	margin-bottom: 24px;

	@media (max-width: ${media.sm}) {
		text-align: center;
		font-size: 10px;
		line-height: 13px;
		margin-bottom: 20px;
	}
`;

const Nav = styled.nav`
	display: flex;
	flex-direction: column;
	gap: 16px;
	margin-bottom: 24px;

	a {
		font-size: 14px;
		transition: color 0.2s ease-in-out;

		&:hover {
			color: ${({theme}) => theme.tertiary};
		}
	}

	@media (max-width: ${media.sm}) {
		align-items: center;

		a {
			font-size: 12px;
		}
	}
`;

const FanHubLink = styled.a`
	position: absolute;
	bottom: 0;
	right: 24px;

	@media (max-width: ${media.sm}) {
		position: static;
		display: block;
		width: fit-content;
		margin: auto;
		margin-bottom: 22px;
	}
`;

const Copyright = styled.p`
	font-size: 14px;

	@media (max-width: ${media.sm}) {
		text-align: center;
		font-size: 12px;
	}
`;

export const Footer: React.FC = () => {
	const location = useLocation();
	const {t} = useTranslation();

	if (NO_HEADER_PATHS.includes(location.pathname)) {
		return null;
	}

	return (
		<Wrapper>
			<PaddingContainer>
				<Logos>
					<GameLogos>
						<img src={footerLogo} alt="Ford logo" />
					</GameLogos>
					<SocialLinks>
						<a
							href="https://twitter.com/bestadeildin"
							target={"_blank"}
							rel="noreferrer">
							<Twitter />
						</a>
						<a
							href="https://www.facebook.com/Bestadeildin"
							target={"_blank"}
							rel="noreferrer">
							<Facebook />
						</a>
					</SocialLinks>
				</Logos>
				<FooterText>{t("landing.body.Lorem_ipsum")} </FooterText>
				<Nav>
					<Link to="/help/terms">{t("landing.footer.t&c")}</Link>
					<Link to="/help/contact-us">{t("landing.footer.contact_us")}</Link>
				</Nav>
				<FanHubLink href="https://geniussports.com" target="_blank">
					<img src={fanhub} alt="FanHub" />
				</FanHubLink>
				<Copyright>© {t("landing.footer.besta_deildin")}</Copyright>
			</PaddingContainer>
		</Wrapper>
	);
};
