import {RefObject} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {getProject, getSport} from "modules/selectors";

export const useScrollTo =
	({current}: RefObject<HTMLElement>) =>
	() => {
		if (current) {
			window.scrollTo({
				top: current.getBoundingClientRect().top + window.scrollY,
				behavior: "smooth",
			});
		}
	};

export const useHelpLocation = () => {
	const helpPages = ["", "/", "/guidelines", "/faqs", "/prizes", "/contact-us", "/terms"];
	const location = useLocation();
	const path = location.pathname.replace("/help", "");

	const isPathInvalid = !helpPages.includes(path);

	return {
		isGuideLine: path.includes("/guidelines") || isPathInvalid,
		isFaqs: path.includes("/faqs"),
		isPrizes: path.includes("/prizes"),
		isContactUs: path.includes("/contact-us"),
		isTerms: path.includes("/terms"),
	};
};

export const useIsPathIncludes = (path: string) => {
	const location = useLocation();
	return location.pathname.includes(path);
};

export const useNavigateWithSport = () => {
	const project = useSelector(getProject);
	const sport = useSelector(getSport);
	const navigate = useNavigate();

	return (path: string) => {
		navigate(`/${project}/${sport}${path}`);
	};
};
