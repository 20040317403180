import React from "react";
import styled from "styled-components";
import {Exist} from "components/Exist";
import {PrimaryButton} from "components/styled";
import {getJoinLeaguesState, getLeagueRequestStateByKey} from "modules/selectors";
import {JoinRow} from "components/Leagues/Join/JoinRow";
import {media} from "assets/media";
import {useSelector} from "react-redux";
import {CircularPreloaded} from "components/Preloader";
import {useTranslation} from "react-i18next";

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 32px;
	margin-bottom: 50px;

	@media screen and ${media.lessThanMD} {
		margin-bottom: 20px;
		gap: 20px;
	}
`;

const SPrimaryButton = styled(PrimaryButton)`
	max-width: 320px;
	width: 100%;

	@media screen and ${media.lessThanMD} {
		max-width: calc(100% - 40px);
	}
`;

const Table = styled.table`
	width: 100%;
	border-collapse: collapse;

	thead {
		border-radius: 4px 4px 0 0;
		background: ${({theme}) => theme.secondary};
		border: 1px solid transparent;

		th {
			color: #ffffff;
			font-size: 14px;
			font-weight: bold;
			letter-spacing: 0;
			line-height: 14px;
			height: 40px;
			padding-left: 20px;
			text-align: right;
			vertical-align: middle;

			&.join-league-head-name {
				text-align: left;
			}

			&:first-child {
				border-top-left-radius: 4px;
			}

			&:last-child {
				border-top-right-radius: 4px;
			}
		}

		@media screen and ${media.lessThanMD} {
			border: unset;

			th {
				padding-left: 12px;

				&.join-league-head-start-week {
					display: none;
				}

				&:first-child {
					border-top-left-radius: 0;
				}

				&:last-child {
					border-top-right-radius: 0;
				}
			}
		}
	}
`;

interface IProps {
	isLoadMoreAvailable: boolean;
	onLoadMore: () => void;
}

export const JoinLeagueTable: React.FC<IProps> = ({isLoadMoreAvailable, onLoadMore}) => {
	const {isLoading} = useSelector(getLeagueRequestStateByKey)("joinFetch");
	const {leagues} = useSelector(getJoinLeaguesState);
	const {t} = useTranslation();

	const isLoadMoreVisible = isLoadMoreAvailable && !isLoading;

	return (
		<Wrapper>
			<Table>
				<thead>
					<tr>
						<th className="join-league-head-name">{t("leagues.league_card.league_name")}</th>
						<th className="join-league-head-start-week">Starting Week</th>
						<th>{t("join_leagues.table.h_players")}</th>
						<th>&nbsp;</th>
					</tr>
				</thead>
				<tbody>
					{leagues.map((league) => (
						<JoinRow key={league.id} league={league} />
					))}
				</tbody>
			</Table>
			<Exist when={isLoading}>
				<CircularPreloaded />
			</Exist>
			<Exist when={isLoadMoreVisible}>
				<SPrimaryButton className="join-league-more" onClick={onLoadMore}>
					Show more
				</SPrimaryButton>
			</Exist>
		</Wrapper>
	);
};
