import {IStore} from "modules/types";
import {createSelector} from "reselect";

const getModalsState = ({modals}: IStore) => modals;

export const getAuthModalState = createSelector(getModalsState, (state) => state.showAuthModal);
export const whatAuthModalToShow = createSelector(getModalsState, (state) => state.authModalToShow);

export const getTeamSavedModalState = createSelector(
	getModalsState,
	(state) => state.isShowTeamSavedModal
);

export const getLeaveLeagueModal = createSelector(
	getModalsState,
	(state) => state.isShowLeagueLeaveModal
);

export const getInfoModalState = createSelector(getModalsState, (state) => state.infoModal);
export const getPreAuthModalState = createSelector(getModalsState, (state) => state.preAuthModal);
export const getRemovePlayerModalState = createSelector(
	getModalsState,
	(state) => state.removeLeaguePlayerModal
);

export const getPlayerInfoModalState = createSelector(
	getModalsState,
	(state) => state.playerInfoModal
);

export const getTransferModalState = createSelector(
	getModalsState,
	(state) => state.isShowTransferModal
);

export const getWelcomeModalState = createSelector(
	getModalsState,
	({isShowWelcomeModal}) => isShowWelcomeModal
);

export const getAccountRecoverModalState = createSelector(
	getModalsState,
	({isShowAccountRecoverModal}) => isShowAccountRecoverModal
);
