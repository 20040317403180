import React from "react";
import {PlayerStatus} from "modules/types/enums";
import {IconInjury} from "components";

interface IProps {
	status: PlayerStatus;
	width?: number;
	height?: number;
	color?: string;
}

export const IconPlayerStatusOnField: React.FC<IProps> = ({status, ...rest}) => {
	switch (status) {
		case PlayerStatus.Injured:
			return <IconInjury {...rest} />;
		case PlayerStatus.Uncertain:
			return null;
		default:
			return null;
	}
};
