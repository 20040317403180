import styled from "styled-components";
import {HeaderNavLink, PaddingContainer} from "components/styled";
import React, {PropsWithChildren} from "react";
import {useLocation} from "react-router-dom";

const SubMenuWrapper = styled(PaddingContainer)`
	display: flex;
	justify-content: flex-start;

	${HeaderNavLink} {
		height: calc(100% - 2px);
		border-radius: 0;
		border-bottom: 2px solid transparent;
		color: ${({theme}) => theme.header.sub.color};
		height: 100%;

		&.active,
		&:hover,
		&:active,
		&:focus {
			background: transparent;
			border-bottom: 2px solid ${({theme}) => theme.tertiary};
		}
	}
`;

interface IProps {
	visibilityPath: string;
}

export const DesktopSubMenu: React.FC<PropsWithChildren<IProps>> = ({visibilityPath, children}) => {
	const location = useLocation();

	if (!location.pathname.includes(visibilityPath)) {
		return null;
	}
	return <SubMenuWrapper>{children}</SubMenuWrapper>;
};
