import React, {PropsWithChildren, useEffect} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {IS_SECRET_ENABLED} from "modules/constants";
import {useDispatch, useSelector} from "react-redux";
import {userLogoutAndClear} from "modules/actions";
import {getIsUserLogged} from "modules/selectors";

export const HOCSecret: React.FC<PropsWithChildren> = ({children}) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const isLogged = useSelector(getIsUserLogged);

	useEffect(() => {
		if (location.pathname !== "/" && IS_SECRET_ENABLED) {
			if (isLogged) {
				dispatch(userLogoutAndClear());
			}
			navigate("/");
		}
	}, [dispatch, location, isLogged, navigate]);

	return <React.Fragment>{children}</React.Fragment>;
};
