import React, {useCallback} from "react";
import {
	ModalActions,
	ModalBackdrop,
	ModalCloseButton,
	ModalContent,
	ModalHeaderTitle,
	ModalText,
} from "components/Modals/styled";
import {IconInfo, IconRemove} from "components/Icons";
import styled from "styled-components";
import {PrimaryButton, SecondaryButton} from "components/styled";
import {useDispatch, useSelector} from "react-redux";
import {handleAuthModal, handlePreAuthModal} from "modules/actions";
import {getPreAuthModalState} from "modules/selectors";
import {EAuthModal} from "modules/types/enums";

const InnerWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0 12px;
`;

export const TeamCreateRequireAuth: React.FC = () => {
	const dispatch = useDispatch();
	const isModalVisible = useSelector(getPreAuthModalState);

	const closeModal = useCallback(() => {
		dispatch(handlePreAuthModal(false));
	}, [dispatch]);

	const openAuthModal = (e: React.SyntheticEvent<HTMLButtonElement>) => {
		dispatch(
			handleAuthModal({
				showAuthModal: true,
				authModalToShow: e.currentTarget.dataset.type as EAuthModal,
			})
		);
		closeModal();
	};

	if (!isModalVisible) {
		return null;
	}

	return (
		<ModalBackdrop className="fade">
			<ModalContent>
				<ModalCloseButton onClick={closeModal}>
					<IconRemove />
				</ModalCloseButton>
				<InnerWrapper>
					<IconInfo color="#000151" width={66} height={66} />
					<ModalHeaderTitle>Log in or Register to save your team</ModalHeaderTitle>
					<ModalText>
						To successfully save your team you will need to log in or register.
					</ModalText>
				</InnerWrapper>
				<ModalActions>
					<SecondaryButton data-type={EAuthModal.LOGIN} onClick={openAuthModal}>
						Log in
					</SecondaryButton>
					<PrimaryButton data-type={EAuthModal.REGISTRATION} onClick={openAuthModal}>
						Register
					</PrimaryButton>
				</ModalActions>
			</ModalContent>
		</ModalBackdrop>
	);
};
