import DOMPurify from "dompurify";
import {PlayerPosition, PlayerStatus} from "modules/types/enums";
import {IPlayer} from "modules/types/json";
import {IFilter, ISortBy, Nullable, IStore} from "modules/types";
import {sortBy, property, isString} from "lodash";
import {Project} from "modules/types/sport";
import {Selector} from "reselect";
import {AxiosError} from "axios";
import {IApiResponse} from "modules/utils/Api/HTTPClient";

export const currencyFormatDecimal = (value: number, withoutCurrency: boolean = false) => {
	if (!value) {
		return "0";
	}

	let letter = "";
	let abs = Math.abs(value);

	const million = Math.pow(10, 6);
	const thousand = Math.pow(10, 3);

	const is_number_thousand = abs < million && abs >= thousand;
	const is_number_million = abs >= million;

	if (is_number_thousand) {
		abs = abs / thousand;
		letter = "k";
	} else if (is_number_million) {
		abs = abs / million;
		letter = "m";
	}

	// abs = round10(abs, -1);
	const currency = withoutCurrency ? "" : "€";
	return `${currency}${abs}${letter}`;
};

export const purify = (input: string | undefined, props: DOMPurify.Config = {}) => {
	if (!input) {
		return "";
	}
	// eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
	return String(DOMPurify.sanitize(input, props));
};

export const getSplitString = (input: string | number): string => {
	const value = String(input).split(".");
	value[0] = value[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	return value.join(".");
};

export const getPlayerPositionMap = (position: number | undefined) => {
	if (position === undefined) {
		return {
			isGoalkeeper: false,
			isDefender: false,
			isMidfielder: false,
			isForward: false,
		};
	}

	return {
		isGoalkeeper: position === PlayerPosition.Goalkeeper,
		isDefender: position === PlayerPosition.Defender,
		isMidfielder: position === PlayerPosition.Midfielder,
		isForward: position === PlayerPosition.Forward,
	};
};

export const statsCentreSort = <T>(items: T[], sort: ISortBy): T[] => {
	const sortByValue = sort.sortBy === "price" ? "cost" : sort.sortBy;

	if (sort.order) {
		return sortBy<T>(items, sortByValue);
	}
	return sortBy<T>(items, sortByValue).reverse();
};

export const statsCenterFilterByName = (players: IPlayer[], name: Nullable<string>) => {
	if (!name) {
		return players;
	}

	return players.filter((player) =>
		`${player.preferredName}`.toLocaleLowerCase().includes(name.toLocaleLowerCase())
	);
};

export const statsCenterFilterByStatus = (players: IPlayer[]) => {
	return players.filter((player) => player.status !== PlayerStatus.Eliminated);
};

export const statsCentrePlayerSortBySelects = (players: IPlayer[], sort: IFilter) => {
	if (!sort.team && !sort.position) {
		return players;
	}

	if (!sort.position) {
		return players.filter((player) => player.squadId === Number(sort.team));
	}

	if (!sort.team) {
		return players.filter((player) => player.position === Number(sort.position));
	}

	return players.filter(
		(player) => player.squadId === sort.team && player.position === Number(sort.position)
	);
};

export const isClassicGame = (currentProject: Project) => currentProject === Project.Classic;
export const isGameDayGame = (currentProject: Project) => currentProject === Project.Gameday;
export const allTrue = (items: boolean[]) => items.every((val) => val);

export function storeSelector<T extends keyof IStore>(getter: T): Selector<IStore, IStore[T]>;
export function storeSelector<T>(getter: (store: IStore) => T): typeof getter;
export function storeSelector(getter: (store: IStore) => void | string) {
	return !isString(getter) ? getter : property(getter);
}

export const extractErrorMessage = (error: AxiosError<IApiResponse>) =>
	error.response?.data?.errors?.[0]?.message || error.message;
