import {get} from "lodash";
import {FB_SHARE_URL} from "modules/constants";
import {IShare} from "modules/types";
import {ShareTarget, ShareType} from "modules/types/share";

const getEncodedJson = <T>(object: T) => btoa(JSON.stringify(object));

const getShareText = (type: ShareType, code = "") => {
	switch (type) {
		case ShareType.General:
			return "Build the team you think will be the best during the Icelandic Premier League  season and you could win some epic prizes. It's free and easy to join!";
		case ShareType.League:
			return `You think you can beat me in tipping this Icelandic Premier League season? I'll prove you wrong! Come join my league now. The league pin is ${code}`;
		default:
			console.error("Invalid share type");
			return "";
	}
};

const getLinkForShare = <T>(object: T, type: ShareType) => {
	const encodedJson = getEncodedJson(object);

	switch (type) {
		case ShareType.League:
			return `${FB_SHARE_URL}fantasy_league/${encodedJson}`;
		case ShareType.General:
			return `${FB_SHARE_URL}general/${encodedJson}`;
		default:
			return `${FB_SHARE_URL}general/${encodedJson}`;
	}
};

const prepareShareObject = (data: IShare) => {
	let object = {
		t: getTimestamp(),
	};

	if (data.type === ShareType.League) {
		object = Object.assign(object, {
			leagueId: data.leagueId,
			sport: data.sport!.toLocaleLowerCase(),
		});
	}
	return object;
};

const last_four_number = -4;
const getTimestamp = () => Date.now().toString().substr(last_four_number);

const shareFacebook = (data: IShare) => {
	const FB_API = get(window, "FB");

	if (!FB_API) {
		throw Error("No FB api found");
	}

	const object = prepareShareObject(data);

	FB_API.ui(
		{
			method: "share",
			display: "popup",
			href: getLinkForShare(object, data.type),
		},
		(response: unknown) => {
			console.log(response);
		}
	);
};

const shareTwitter = (data: IShare) => {
	const object = prepareShareObject(data);
	const href = getLinkForShare(object, data.type);
	const text = getShareText(data.type, data.code);
	window.open(
		"https://twitter.com/share?url=" +
			encodeURIComponent(href) +
			"&text=" +
			encodeURIComponent(text),
		"twitter-share-dialog",
		"width=626,height=436"
	);
};

const shareMap = {
	[ShareTarget.Twitter]: shareTwitter,
	[ShareTarget.Facebook]: shareFacebook,
};

export const share = (data: IShare) => {
	const shareFunction = shareMap[data.target];

	if (shareFunction && typeof shareFunction === "function") {
		shareFunction(data);
	}
};

export const shareNavigator = async (data: IShare) => {
	try {
		const url = getLinkForShare(data, data.type);
		const text = getShareText(data.type);

		const shareData = {
			url,
			text,
		};

		await navigator.share(shareData);
	} catch (err) {
		// log error, or handle it in other way
		console.log(err);
	}
};
