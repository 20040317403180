import React, {Fragment} from "react";
import {ILeagueRankItem} from "modules/types";
import {LeagueTableType} from "modules/types/enums";
import {getTeamByIdRequestStates} from "modules/selectors";
import {Collapse, IconButton} from "@mui/material";
import {CircularPreloaded, Exist} from "components";
import styled from "styled-components";
import {partial} from "lodash";
import {ReactComponent as AccordionArrow} from "assets/images/icons/arrowDown.svg";
import {ReactComponent as OpenAccordionArrow} from "assets/images/icons/arrowUp.svg";
import {useSelector} from "react-redux";
import {TableTeam} from "./TableTeam";

const PreloaderWrapper = styled.div`
	padding: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const TeamContainer = styled.tr`
	&& td {
		padding: 0;
	}
`;

export const CollapseButton = styled(IconButton)`
	&.MuiIconButton-root {
		padding: 0;
	}

	&:disabled {
		opacity: 0.5;
	}
`;

const getClassName = (defaultClassName: string = "", isDisabled: boolean) => {
	let className = defaultClassName;

	if (isDisabled) {
		className += " disabled";
	}

	return className;
};

interface IProps {
	type: LeagueTableType;
	rankItem: ILeagueRankItem;
	className?: string;
	isOpen: boolean;
	toggleHandler: (id: number) => void;
	isDisabled: boolean;
	roundId: number;
}

export const LeagueTableItem: React.FC<IProps> = ({
	type,
	rankItem,
	className: defaultClassName,
	toggleHandler,
	isOpen,
	isDisabled,
	roundId,
}) => {
	const {isLoading} = useSelector(getTeamByIdRequestStates);
	const {overallRank, overallPoints, roundRank, roundPoints, teamId} = rankItem;
	const isOverall = type === LeagueTableType.Overall;
	const roundPointsData = isOverall ? overallPoints : roundPoints;
	const roundRankData = isOverall ? overallRank : roundRank;
	const className = getClassName(defaultClassName, isDisabled);

	return (
		<Fragment>
			<tr className={className} onClick={partial(toggleHandler, teamId)}>
				<td className="position">{roundRankData || "-"}</td>
				<td>{rankItem.userName}</td>
				<td>{rankItem.teamName}</td>
				<td className="total">{roundPointsData ?? "-"}</td>
				<td className="right">
					<CollapseButton disabled={isDisabled}>
						{isOpen ? <OpenAccordionArrow /> : <AccordionArrow />}
					</CollapseButton>
				</td>
			</tr>

			<TeamContainer>
				<td colSpan={5}>
					<Collapse in={isOpen} timeout="auto" unmountOnExit>
						<Exist when={isLoading}>
							<PreloaderWrapper>
								<CircularPreloaded color="#0000B8" size={60} />
							</PreloaderWrapper>
						</Exist>
						<Exist when={!isLoading}>
							<TableTeam roundId={roundId} />
						</Exist>
					</Collapse>
				</td>
			</TeamContainer>
		</Fragment>
	);
};
