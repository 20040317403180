import React, {PropsWithChildren, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {subscribeToLiveScores, unsubscribeFromLiveScores} from "modules/actions";
import {get} from "lodash";
import {RoundStatus} from "modules/types/enums";
import {getActualRound} from "modules/selectors";

export const HOCLiveScores: React.FC<PropsWithChildren> = ({children}) => {
	const dispatch = useDispatch();
	const actualRound = useSelector(getActualRound);

	useEffect(() => {
		if (actualRound?.status === RoundStatus.Active) {
			const actualRoundId = get(actualRound, "id", 0);
			dispatch(subscribeToLiveScores(actualRoundId));
		} else {
			dispatch(unsubscribeFromLiveScores());
		}

		return () => {
			dispatch(unsubscribeFromLiveScores());
		};
	}, [dispatch, actualRound]);

	return <React.Fragment>{children}</React.Fragment>;
};
