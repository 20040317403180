import {createAction} from "redux-act";
import {IError} from "modules/types";

export interface IMessageBar {
	enabled: boolean;
	version: number;
	title: string;
	text: string;
}

export const fetchMessageBar = createAction();
export const fetchMessageBarSuccess = createAction<IMessageBar>();
export const fetchMessageBarFailed = createAction<IError>();

export const dismissMessageBar = createAction<number>();
