import React from "react";
import {useSelector} from "react-redux";
import {
	getAutoFillRequestState,
	getIsHeadsWereChanged,
	getIsTeamSaved,
	getTeamRequestState,
	isTeamFullFilled,
} from "modules/selectors";
import {Exist} from "components/Exist";
import {PrimaryButton, SecondaryButton} from "components/styled";
import {useTranslation} from "react-i18next";

interface IProps {
	saveTeamHandle: () => void;
	clearTeam: () => void;
	autoFillHandler: () => void;
}
export const NonLockoutButtons: React.FC<IProps> = ({
	saveTeamHandle,
	clearTeam,
	autoFillHandler,
}) => {
	const autofillState = useSelector(getAutoFillRequestState);
	const saveTeamRequestState = useSelector(getTeamRequestState)("saveTeam");
	const isTeamFull = useSelector(isTeamFullFilled);
	const isTeamSaved = useSelector(getIsTeamSaved);
	const isHeadsHasChanges = useSelector(getIsHeadsWereChanged);
	const {t} = useTranslation();

	const isSaveDisabled = !isTeamFull || isTeamSaved || saveTeamRequestState.isLoading;
	const isAutoDisabled = autofillState.isLoading || saveTeamRequestState.isLoading;

	return (
		<React.Fragment>
			<Exist when={isTeamFull}>
				<SecondaryButton onClick={clearTeam} disabled={isAutoDisabled}>
					{t("team.summary.clear_all")}
				</SecondaryButton>
			</Exist>

			<Exist when={!isTeamFull}>
				<SecondaryButton onClick={autoFillHandler} disabled={isAutoDisabled}>
					{t("team.summary.auto_pick")}
				</SecondaryButton>
			</Exist>

			<Exist when={!isHeadsHasChanges}>
				<PrimaryButton disabled={isSaveDisabled} onClick={saveTeamHandle}>
					{t("team.summary.save")}
				</PrimaryButton>
			</Exist>
			<Exist when={isHeadsHasChanges}>
				<PrimaryButton onClick={saveTeamHandle}>{t("team.summary.save")}</PrimaryButton>
			</Exist>
		</React.Fragment>
	);
};
