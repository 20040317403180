import {createSelector} from "reselect";
import {getTeamsState} from "modules/actions";
import {
	getIsSelectedRoundNoActive,
	getPlayersById,
	getRounds,
	getScoreRounds,
} from "modules/selectors";
import {ILineup, IStore} from "modules/types";
import {chain, filter, find, get, identity, memoize, range, size, toInteger} from "lodash";
import {FORMATION_LIST, SALARY_CUP} from "modules/constants";
import {RequestStateType, RoundStatus, StatsTab} from "modules/types/enums";
import {ITeamsReducer} from "modules/types/reducers";

const getState = ({teams}: IStore) => teams;

export const getPublicTeam = createSelector(getTeamsState, ({publicTeam}) => publicTeam);
export const getTeam = createSelector(getTeamsState, ({team}) => team);
export const getTeamLineup = createSelector(getTeam, ({lineup}) => lineup);
export const getTeamEmptyLineup = createSelector(getTeamLineup, (lineup) => {
	const {captain, vice_captain, ...rest} = lineup;
	return rest;
});
export const isTeamFullFilled = createSelector(
	getTeamEmptyLineup,
	(lineup) => !chain(lineup).values().flatten().value().includes(0)
);

export const getIsTeamLockoutInner = createSelector(getTeam, getRounds, (team, rounds) => {
	if (!("startRound" in team)) {
		return false;
	}

	const startRound = rounds.find((round) => round.id === team.startRound);
	return startRound ? startRound.status !== RoundStatus.Scheduled : false;
});

export const getIsTeamSaved = createSelector(getTeamsState, ({isTeamSaved}) => isTeamSaved);

export const isTeamLockedButNonFilled = createSelector(
	getIsTeamLockoutInner,
	getIsTeamSaved,
	(isLockout, isTeamSaved) => {
		return isLockout && !isTeamSaved;
	}
);

export const hasEmptySpot = (lineup: ILineup, position: number) =>
	lineup[position].some((id) => !id);

export const getAvailableFormation = (lineup: ILineup, position: number) => {
	const futureFormation = range(1, 5).map((row_index) => {
		const newPlayerPosition = row_index === position ? 1 : 0;

		return size(filter(lineup[row_index], identity)) + newPlayerPosition;
	});

	return find(FORMATION_LIST, (formationStr) =>
		formationStr
			.split("-")
			.map(toInteger)
			.every((maxPlayers, index) => futureFormation[index] <= maxPlayers)
	);
};

export const getPlayerInTeamCheckSelector = createSelector(
	getTeamLineup,
	getPlayersById,
	(lineup, playersById) =>
		memoize((playerID: number) => {
			const player = playersById[playerID];

			if (!player) {
				return false;
			}

			const {position, id} = player;

			if (!lineup) {
				return false;
			}

			return lineup[position]?.includes(id);
		})
);

export const getTeamValue = createSelector(
	getTeamLineup,
	getPlayersById,
	(lineup, playersById): number => {
		delete lineup.captain;
		delete lineup.vice_captain;

		return chain(lineup)
			.values()
			.flatten()
			.filter(identity)
			.value()
			.reduce((acc, id) => {
				acc += get(playersById[id], "cost", 0);
				return acc;
			}, 0);
	}
);

export const getTeamRemainingSalary = createSelector(
	getTeamValue,
	(teamValue) => SALARY_CUP - teamValue
);

export const getIsEditTeamModalOpen = createSelector(
	getState,
	(state) => state.modalsState.editTeamModal
);

export const getIsNoHeadsModalOpen = createSelector(
	getState,
	(state) => state.modalsState.headsModal
);

const getRequestState = (property: RequestStateType) => {
	return {
		isIdle: property === RequestStateType.Idle,
		isLoading: property === RequestStateType.Loading,
		isSuccess: property === RequestStateType.Success,
		isError: property === RequestStateType.Error,
	};
};

export const getShownStats = createSelector(
	getState,
	getIsSelectedRoundNoActive,
	(state, isSelectedRoundNoActive) => {
		if (isSelectedRoundNoActive) {
			return StatsTab.Weekly;
		}

		return Number(state.activeTab);
	}
);
export const getIsUserHasTeam = createSelector(getState, (state) => state.isUserHasTeam);
export const getIsSavingMode = createSelector(getState, (state) => state.isSavingMode);
export const getIsTeamChecked = createSelector(getState, (state) => state.isTeamChecked);
export const getAutoFillRequestState = createSelector(getState, (state) =>
	getRequestState(state.fillRequestState)
);

export const getTeamLineupIDs = createSelector(getTeamEmptyLineup, (lineup) =>
	chain(lineup).values().flatMap<number>(identity).filter(identity).value()
);

export const getTeamGameBar = createSelector(getTeamsState, (state) => {
	return {
		round_rank: state.gameBar.round_rank || 0,
		overall_rank: state.gameBar.overall_rank || 0,
		round_points: state.gameBar.round_points || 0,
		overall_points: state.gameBar.overall_points || 0,
	};
});

export const getLeaderboard = createSelector(getState, (state) => state.leaderboardData);

export const getTeamRequestState = createSelector(getState, (state) => {
	return memoize((key: keyof ITeamsReducer["requestState"]) => {
		return {
			isIdle: state.requestState[key] === RequestStateType.Idle,
			isLoading: state.requestState[key] === RequestStateType.Loading,
			isSuccess: state.requestState[key] === RequestStateType.Success,
			isError: state.requestState[key] === RequestStateType.Error,
		};
	});
});

export const getPlayersAccordingLineUp = createSelector(
	getTeamLineupIDs,
	getPlayersById,
	(lineup, getById) => {
		return lineup.map((id) => getById[id]);
	}
);

export const checkIs3PlayerMaximumFromTheSameTeamSelector = createSelector(
	getPlayersAccordingLineUp,
	getPlayersById,
	(lineup, playersById) =>
		memoize((playerID: number) => {
			const player = playersById[playerID];

			if (!player) {
				return false;
			}

			const {squadId} = player;

			if (!lineup) {
				return false;
			}

			return (lineup.filter((item) => item.squadId === squadId) || []).length === 3;
		})
);

export const getTeamHeads = createSelector(getState, (state) => {
	return {
		captain: state.captain,
		viceCaptain: state.viceCaptain,
	};
});

export const getIsTeamHeadsFull = createSelector(getState, (state) => {
	return Boolean(state.captain) && Boolean(state.viceCaptain);
});

export const getIsTeamHeadsPartiallyFull = createSelector(getState, (state) => {
	return Boolean(state.captain) || Boolean(state.viceCaptain);
});

export const getIsHeadsWereChanged = createSelector(
	getTeamHeads,
	getTeamLineup,
	getIsTeamHeadsPartiallyFull,
	(heads, lineup, isHeadsPartiallyFull) => {
		if (!isHeadsPartiallyFull) {
			return false;
		}

		return (
			Number(lineup.captain || 0) !== Number(heads.captain || 0) ||
			Number(heads.viceCaptain || 0) !== Number(lineup.vice_captain || 0)
		);
	}
);

export const getIsLastTransferMakeEmpty = createSelector(
	getState,
	(state) => state.isLastTradeMakeEmpty
);

export const getIsTeamComplete = createSelector(getState, (state) => state.isTeamComplete);

export const getTeamByIdRequestStates = createSelector(getState, (state) =>
	getRequestState(state.teamByIdRequestState)
);

export const getRoundsSelectorScoredRounds = createSelector(
	getScoreRounds,
	getTeam,
	(rounds, team) => {
		return rounds.filter(({id}) => get(team, "startRound", 0) <= id);
	}
);

export const getIsLoadingTeamHistory = createSelector(
	getState,
	(state) => state.teamHistoryRequestState === RequestStateType.Loading
);
