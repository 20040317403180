import React, {lazy} from "react";
import reportWebVitals from "./reportWebVitals";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {BASE_URL} from "modules/constants";
import {retryFailLoad} from "modules/utils/LazyLoad";
import * as Sentry from "@sentry/react";
import {Provider} from "react-redux";
import {store} from "modules/store";
import {
	Footer,
	Header,
	ModalLogin,
	ModalRegister,
	NotAuthOnlyRoute,
	PagePreloader,
	PrivateRoute,
	WelcomeModal,
} from "components";
import {AdapterLuxon} from "@mui/x-date-pickers/AdapterLuxon";
import {LocalizationProvider} from "@mui/x-date-pickers";
import "./assets/css/reset.css";
import "./assets/css/fonts.css";
import "./assets/css/core.css";
import {HOCSecret} from "components/HOCs/HOCSecret";
import {HOCAuth} from "components/HOCs/HOCAuth";
import {HOCThemeAdapter} from "components/HOCs/HOCThemeAdapter";
import {HOCi18n} from "components/HOCs/HOCi18n";
import {createRoot} from "react-dom/client";
import {breadcrumbsIntegration, browserTracingIntegration} from "@sentry/react";

let errorCount = 0;
const MAX_ERRORS = 100;
Sentry.init({
	dsn: "https://d7be64e72b4742b1ab993a4f92332841@o151969.ingest.sentry.io/6246247",
	environment: process.env.REACT_APP_SENTRY_ENV || "development",
	sampleRate: 0.1,
	allowUrls: [".bestadeildin.is", ".geniussports.com"],
	integrations: [
		browserTracingIntegration(),
		breadcrumbsIntegration({
			console: false,
		}),
	],
	denyUrls: [
		"quantcast",
		"xsca",
		// browser's extensions
		/extensions\//i,
		/^chrome:\/\//i,
		/^moz-extension:\/\//i,
	],
	autoSessionTracking: true,
	beforeSend: (event: Sentry.Event) => {
		errorCount++;
		if (errorCount > MAX_ERRORS) {
			return null;
		}
		return event;
	},
});

const Landing = lazy(retryFailLoad(() => import("pages/Landing")));
const Classic = lazy(retryFailLoad(() => import("pages/Classic")));
const Gameday = lazy(retryFailLoad(() => import("pages/Gameday")));

const AuthPageOutlet = lazy(retryFailLoad(() => import("components/Outlets/AuthPageOutlet")));
const ForgotPassword = lazy(retryFailLoad(() => import("pages/ForgotPassword")));
const ResetPassword = lazy(retryFailLoad(() => import("pages/ResetPassword")));

const AccountOutlet = lazy(retryFailLoad(() => import("components/Outlets/AccountOutlet")));
const AccountDetails = lazy(retryFailLoad(() => import("pages/Account/AccountDetails")));
const AccountChangePassword = lazy(
	retryFailLoad(() => import("pages/Account/AccountChangePassword"))
);

const SuccessfullyUnsubscribed = lazy(
	retryFailLoad(() => import("pages/SuccessfullyUnsubscribed"))
);

const Help = lazy(retryFailLoad(() => import("pages/Help")));
const NotFound = lazy(retryFailLoad(() => import("pages/PageNotFound")));

const root = document.getElementById("root");

if (!root) {
	throw Error("Root element was not found");
}

createRoot(root).render(
	<React.StrictMode>
		<Provider store={store}>
			<BrowserRouter basename={BASE_URL}>
				<HOCThemeAdapter>
					<LocalizationProvider dateAdapter={AdapterLuxon}>
						<HOCSecret>
							<HOCi18n>
								<HOCAuth>
									<React.Suspense fallback={<PagePreloader />}>
										<Header />
										<Routes>
											<Route path="help/:section" element={<Help />} />
											<Route path="help" element={<Help />} />

											<Route path="classic/*" element={<Classic />} />
											<Route path="gameday/*" element={<Gameday />} />
											<Route
												path="successfully-unsubscribed"
												element={<SuccessfullyUnsubscribed />}
											/>

											<Route element={<PrivateRoute />}>
												<Route path="account/*" element={<AccountOutlet />}>
													<Route
														path="password"
														element={<AccountChangePassword />}
													/>
													<Route index element={<AccountDetails />} />
												</Route>
											</Route>

											<Route element={<NotAuthOnlyRoute />}>
												<Route path="/" element={<Landing />} />
												<Route element={<AuthPageOutlet />}>
													<Route path="login" element={<ModalLogin />} />
													<Route
														path="register"
														element={<ModalRegister />}
													/>
													<Route
														path="forgot-password"
														element={<ForgotPassword />}
													/>
													<Route
														path="reset-password"
														element={<ResetPassword />}
													/>
												</Route>
											</Route>
											<Route element={<NotFound />} path="/*" />
										</Routes>
										<Footer />
										<WelcomeModal />
									</React.Suspense>
								</HOCAuth>
							</HOCi18n>
						</HOCSecret>
					</LocalizationProvider>
				</HOCThemeAdapter>
			</BrowserRouter>
		</Provider>
	</React.StrictMode>
);

reportWebVitals();
