import React from "react";
import styled from "styled-components";
import {PrimaryButton, PureButton} from "components/styled";
import facebookIcon from "assets/images/icons/facebook.svg";
import {ReactComponent as TwitterIcon} from "assets/images/icons/x.svg";
import {useMediaQuery} from "@mui/material";
import {media} from "assets/media";
import {share, shareNavigator} from "modules/utils/Share";
import {getLeagueById, getSport} from "modules/selectors";
import {ShareTarget, ShareType} from "modules/types/share";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

const Wrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 10px;
`;

const SocialButton = styled(PureButton)`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 8px;
	border-radius: 4px;
	width: 100%;
	font-weight: 200;

	color: #ffffff;
	font-size: 14px;
	letter-spacing: 0;
	line-height: 17px;
	height: 40px;
	padding: 13px 12px;
	transition: 0.25s;

	&.facebook {
		background: #3b5998;
	}

	&.twitter {
		background: #1da1f2;
		color: #fff;
		svg {
			width: 18px;
		}
	}

	img {
		height: 16px;
		width: auto;
	}

	&:hover {
		opacity: 0.7;
	}
`;

const NativeShareButton = styled(PrimaryButton)`
	width: 100%;
	max-width: unset;
`;

interface IProps {
	leagueId: number;
}

export const SocialSharing: React.FC<IProps> = ({leagueId}) => {
	const league = useSelector(getLeagueById)(leagueId);
	const {t} = useTranslation();
	const sport = useSelector(getSport);
	const isMobile = useMediaQuery(media.lessThanMD);
	const isNavigatorAccessible = "share" in navigator && isMobile;
	const shareVia = (e: React.SyntheticEvent<HTMLButtonElement>) => {
		const target = e.currentTarget.dataset.target;

		if (!target) {
			return;
		}

		share({
			target: target as ShareTarget,
			type: ShareType.League,
			sport,
			leagueId,
			code: league?.code || "",
		});
	};

	const shareNative = () => {
		void shareNavigator({
			target: ShareTarget.Twitter,
			type: ShareType.League,
			sport,
			leagueId,
			code: league?.code || "",
		});
	};

	if (isNavigatorAccessible) {
		return (
			<NativeShareButton onClick={shareNative}>
				{t("create_league.form_step_two.button_share")}
			</NativeShareButton>
		);
	}

	return (
		<Wrapper className="sharing">
			<SocialButton
				onClick={shareVia}
				className="facebook"
				data-target={ShareTarget.Facebook}>
				<img src={facebookIcon} alt="Twitter icon" />
				{t("create_league.form_step_two.facebook")}
			</SocialButton>
			<SocialButton onClick={shareVia} className="twitter" data-target={ShareTarget.Twitter}>
				<TwitterIcon />
				{t("create_league.form_step_two.twitter")}
			</SocialButton>
		</Wrapper>
	);
};
