import React, {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
	getAutoFillRequestState,
	getIsTeamHeadsFull,
	getTeam,
	getTeamEmptyLineup,
	getTeamRequestState,
	isTeamFullFilled,
} from "modules/selectors";
import {Exist} from "components/Exist";
import {PrimaryButton, SecondaryButton} from "components/styled";
import {
	autoFillRequest,
	clearHeads,
	editModalSavingMode,
	removePlayerFromTeam,
	saveTeam,
	toggleEditTeamNameModal,
	toggleNoHeadsModal,
} from "modules/actions";
import {flatMap} from "lodash";
import {useTranslation} from "react-i18next";

export const NonCompleteTeamButtons: React.FC = () => {
	const dispatch = useDispatch();
	const {t} = useTranslation();
	const autofillState = useSelector(getAutoFillRequestState);
	const saveTeamRequestState = useSelector(getTeamRequestState)("saveTeam");
	const isTeamFull = useSelector(isTeamFullFilled);
	const lineup = useSelector(getTeamEmptyLineup);
	const flatLineUp = flatMap(lineup);
	const team = useSelector(getTeam);
	const isHeadsFull = useSelector(getIsTeamHeadsFull);

	const isSaveDisabled = !isTeamFull || saveTeamRequestState.isLoading;
	const isAutoDisabled = autofillState.isLoading || saveTeamRequestState.isLoading;

	const clearTeam = useCallback(() => {
		flatLineUp.forEach((playerId) => {
			dispatch(removePlayerFromTeam(playerId));
			dispatch(clearHeads());
		});
	}, [flatLineUp, dispatch]);

	const autoFillHandler = useCallback(() => {
		dispatch(autoFillRequest());
	}, [dispatch]);

	const saveTeamHandle = useCallback(() => {
		dispatch(editModalSavingMode(true));
		if (!isHeadsFull) {
			dispatch(toggleNoHeadsModal(true));
			return;
		}
		if (!team?.name) {
			dispatch(toggleEditTeamNameModal(true));
			return;
		}
		dispatch(saveTeam());
	}, [team, dispatch, isHeadsFull]);

	return (
		<React.Fragment>
			<Exist when={isTeamFull}>
				<SecondaryButton onClick={clearTeam} disabled={isAutoDisabled}>
					{t("team.summary.clear_all")}
				</SecondaryButton>
			</Exist>

			<Exist when={!isTeamFull}>
				<SecondaryButton onClick={autoFillHandler} disabled={isAutoDisabled}>
					{t("team.summary.auto_pick")}
				</SecondaryButton>
			</Exist>

			<PrimaryButton disabled={isSaveDisabled} onClick={saveTeamHandle}>
				{t("team.summary.save")}
			</PrimaryButton>
		</React.Fragment>
	);
};
