import React, {useCallback} from "react";
import {MenuItem} from "@mui/material";
import {Input} from "components/styled";
import styled from "styled-components";
import {useSelector} from "react-redux";
import {getRounds} from "modules/selectors";
import {media} from "assets/media";
import {useTranslation} from "react-i18next";

const Wrapper = styled.div`
	${Input} {
		width: 300px;
	}

	@media screen and ${media.lessThanMD} {
		width: 100%;
		${Input} {
			width: 100%;
		}
	}
`;

interface IProps {
	round: number;
	onChange: (round: number) => void;
}

export const LeaderboardFilters: React.FC<IProps> = ({round, onChange}) => {
	const rounds = useSelector(getRounds);
	const {t} = useTranslation();

	const onRoundChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			onChange(Number(e.target.value));
		},
		[onChange]
	);

	return (
		<Wrapper>
			<Input select label="Type" name="type" value={round} onChange={onRoundChange}>
				<MenuItem value={0} selected={true}>
					{t("league.body.filter_overall")}
				</MenuItem>
				{rounds.map((round) => (
					<MenuItem key={round.id} value={round.id}>
						{t(`team.summary.filter_gw${round.number}`)}
					</MenuItem>
				))}
			</Input>
		</Wrapper>
	);
};
