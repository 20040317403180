import React, {useCallback, useEffect, useState} from "react";
import styled from "styled-components";
import {Exist} from "components/Exist";
import {IconBurgerMenu, IconRemove} from "components";
import {HOCAccordionLink} from "components/HOCs/HOCAccordion";
import {FirstLevelNavLink, HeaderLogo, LogoWrapper, SecondLevelNavLink} from "components/styled";
import fantasy from "assets/images/fantasy_logo_wrap.svg";
import {useDispatch, useSelector} from "react-redux";
import {getIsUserLogged, getProject, getSport} from "modules/selectors";
import {Link, useLocation} from "react-router-dom";
import {userLogoutAndClear} from "modules/actions";
import headerBackground from "assets/images/headerBackground.jpg";
import {HeaderLogoImage} from "./HeaderLogoImage";
import {useTranslation} from "react-i18next";
import {LanguageSelector} from "./LanguageSelector";

const headerHeight = "58px";

const MobileMenuWrapper = styled.div`
	position: relative;
	box-sizing: border-box;
	height: ${headerHeight};
	width: 100%;
	background: url(${headerBackground});
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	color: ${({theme}) => theme.header.main.color};
	padding: 12px 20px;
	z-index: 5;
`;

const MobileHeader = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 100%;
`;

const ToggleMenuButton = styled.button`
	background: transparent;
	border: none;
	cursor: pointer;
`;

const MobileMenuBlock = styled.div`
	box-sizing: border-box;
	position: absolute;
	top: calc(${headerHeight} - 2px);
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	flex-direction: column;
	overflow: auto;
	width: 100%;
	z-index: 9;
	height: calc(100vh - ${headerHeight} + 2px);
	background: ${({theme}) => theme.header.main.background};
	border-top: 2px solid ${({theme}) => theme.header.main.border};
	color: ${({theme}) => theme.header.main.color};
`;

const LoginButtonWrapper = styled.div`
	flex: 1;
	display: flex;
	align-items: flex-end;

	a,
	button {
		box-sizing: border-box;
		width: 100%;
		padding: 18px;
		color: #ffffff;
		display: flex;
		text-decoration: none;
		background: ${({theme}) => theme.header.menu.opened_item_color};
		border: none;
		font-size: 18px;
		font-weight: bold;
		letter-spacing: 0;
		line-height: 20px;
	}
`;

const Relative = styled.div`
	position: relative;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 12px;
`;

export const MobileMenu: React.FC = () => {
	const dispatch = useDispatch();
	const {t} = useTranslation();
	const isLogged = useSelector(getIsUserLogged);
	const sport = useSelector(getSport);
	const currentProject = useSelector(getProject);

	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const location = useLocation();

	const sportLink = useCallback(
		(link: string) => {
			return `/${currentProject}/${sport}${link}`;
		},
		[currentProject, sport]
	);

	const toggleMenu = useCallback(() => {
		setIsMenuOpen(!isMenuOpen);
		if (!isMenuOpen) {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-call
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: "smooth",
			});
			document.body.classList.add("menu-open");
		} else {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-call
			document.body.classList.remove("menu-open");
		}
	}, [isMenuOpen]);

	const isMenuOpenClass = isMenuOpen ? "open" : "";

	useEffect(() => {
		document.body.classList.remove("menu-open");
		setIsMenuOpen(false);
	}, [location]);

	const logout = () => {
		dispatch(userLogoutAndClear());
	};

	return (
		<MobileMenuWrapper className={isMenuOpenClass}>
			<MobileHeader>
				<Relative>
					<LogoWrapper>
						<HeaderLogo to="/">
							<HeaderLogoImage />
						</HeaderLogo>
						<img src={fantasy} alt="Football Fantasy" width="85" />
					</LogoWrapper>
				</Relative>

				<ToggleMenuButton onClick={toggleMenu}>
					<Exist when={isMenuOpen}>
						<IconRemove color="#FFFFFF" width={28} height={28} />
					</Exist>
					<Exist when={!isMenuOpen}>
						<IconBurgerMenu color="#FFFFFF" />
					</Exist>
				</ToggleMenuButton>
			</MobileHeader>
			<Exist when={isMenuOpen}>
				<MobileMenuBlock>
					<Exist when={isLogged}>
						<div>
							<FirstLevelNavLink to={sportLink("/team")}>
								{t("team.header.my_teams")}
							</FirstLevelNavLink>
						</div>
						<HOCAccordionLink
							link={sportLink("/leagues")}
							text={t("team.header.leagues")}>
							<SecondLevelNavLink to={sportLink("/leagues")}>
								{t("team.header.leagues")}
							</SecondLevelNavLink>
							<SecondLevelNavLink to={sportLink("/leagues/create")}>
								{t("create_league.form_step_one.button_create")}
							</SecondLevelNavLink>
							<SecondLevelNavLink to={sportLink("/leagues/join")}>
								{t("leagues.create_join_card.button_join")}
							</SecondLevelNavLink>
						</HOCAccordionLink>
						<div>
							<FirstLevelNavLink to={sportLink("/leaderboard")}>
								{t("team.header.leaderboard")}
							</FirstLevelNavLink>
						</div>
						<div>
							<FirstLevelNavLink to={sportLink("/stats-centre")}>
								{t("team.header.stats_centre")}
							</FirstLevelNavLink>
						</div>
						<div>
							<HOCAccordionLink link="/account" text={t("team.header.my_account")}>
								<SecondLevelNavLink to="/account">
									{t("my_account.title.text")}
								</SecondLevelNavLink>
								<SecondLevelNavLink to="/account/password">
									{t("my_account.subtitle.password")}
								</SecondLevelNavLink>
							</HOCAccordionLink>
						</div>
					</Exist>

					<HOCAccordionLink link="/help" text={t("team.header.help")}>
						<SecondLevelNavLink to="/help">{t("help.title.text")}</SecondLevelNavLink>
						<SecondLevelNavLink to="/help/prizes">
							{t("help.subtitle.prizes")}
						</SecondLevelNavLink>
						<SecondLevelNavLink to="/help/faqs">{t("FAQs")}</SecondLevelNavLink>
						<SecondLevelNavLink to="/help/contact-us">
							{t("help.subtitle.contact")}
						</SecondLevelNavLink>
						<SecondLevelNavLink to="/help/terms">
							{t("landing.footer.terms")}
						</SecondLevelNavLink>
					</HOCAccordionLink>

					<LanguageSelector isMobile={true} />

					<LoginButtonWrapper>
						<Exist when={!isLogged}>
							<Link to="login">{t("landing.header.Log_in")}</Link>
						</Exist>
						<Exist when={isLogged}>
							<button onClick={logout}>{t("landing.header.log_out")}</button>
						</Exist>
					</LoginButtonWrapper>
				</MobileMenuBlock>
			</Exist>
		</MobileMenuWrapper>
	);
};
