import {sortBy} from "lodash";
import {
	fetchAllPlayersStatsSuccess,
	fetchAllTeamsStatsSuccess,
	fetchHelpSuccess,
	fetchPlayerStatsSuccess,
	fetchRoundsSuccess,
	fetchSquadsSuccess,
	globalError,
	fetchChecksumsSuccess,
	fetchChecksumsFailed,
	fetchMessageBarSuccess,
	fetchMessageBarFailed,
} from "modules/actions";
import {Api} from "modules/utils/Api";
import {call, delay, put, select} from "typed-redux-saga";
import {getSport} from "modules/selectors";
import {IError, ISagaAction} from "modules/types";
import {SagaIterator} from "redux-saga";
import {IHelpReducer} from "modules/types/reducers";
import {extractErrorMessage} from "modules/utils";
import {AxiosError} from "axios";
import {IApiResponse} from "modules/utils/Api/HTTPClient";

export const fetchSquadsSaga = function* (): SagaIterator {
	try {
		const sport = yield* select(getSport);
		const response = yield* call(Api.JSON.squads, sport.toLocaleLowerCase());
		yield* put(fetchSquadsSuccess(sortBy(response.data, "name")));
	} catch (e) {
		yield* put(globalError({message: extractErrorMessage(e as AxiosError<IApiResponse>)}));
	}
};

export const fetchAllPlayersStatsSaga = function* (): SagaIterator {
	try {
		const sport = yield* select(getSport);
		const players = yield* call(Api.JSON.all_players_stats, sport.toLocaleLowerCase());
		yield* put(fetchAllPlayersStatsSuccess(players.data));
	} catch (e) {
		yield* put(globalError({message: extractErrorMessage(e as AxiosError<IApiResponse>)}));
	}
};

export const fetchAllTeamsStatsSaga = function* (): SagaIterator {
	try {
		const sport = yield* select(getSport);
		const teams = yield* call(Api.JSON.all_teams_stats, sport.toLocaleLowerCase());
		yield* put(fetchAllTeamsStatsSuccess(teams.data));
	} catch (e) {
		yield* put(globalError({message: extractErrorMessage(e as AxiosError<IApiResponse>)}));
	}
};

export const fetchRoundsSaga = function* (): SagaIterator {
	try {
		const sport = yield* select(getSport);
		const response = yield* call(Api.JSON.rounds, sport.toLocaleLowerCase());
		const sortRounds = sortBy(response.data, "number");
		yield* put(fetchRoundsSuccess(sortRounds));
	} catch (e) {
		yield* put(globalError({message: extractErrorMessage(e as AxiosError<IApiResponse>)}));
	}
};

export const fetchRoundsDelayedSaga = function* () {
	try {
		const sport = yield* select(getSport);
		yield delay(15000);
		const response = yield* call(Api.JSON.rounds, sport.toLocaleLowerCase());
		yield put(fetchRoundsSuccess(response.data));
	} catch (e) {
		yield* put(globalError({message: extractErrorMessage(e as AxiosError<IApiResponse>)}));
	}
};

export const fetchPlayerStatsSaga = function* ({payload}: ISagaAction<number>): SagaIterator {
	try {
		const sport = yield* select(getSport);
		const stats = yield* call(Api.JSON.player_stats, {sport, payload});
		yield* put(fetchPlayerStatsSuccess(stats.data));
	} catch (e) {
		yield* put(globalError({message: extractErrorMessage(e as AxiosError<IApiResponse>)}));
	}
};

export const fetchHelpsSaga = function* ({payload}: ISagaAction<keyof IHelpReducer>): SagaIterator {
	try {
		const response = yield* call(Api.JSON.help_pages, payload);
		yield* put(
			fetchHelpSuccess({
				key: payload,
				value: response.data,
			})
		);
	} catch (e) {
		yield* put(globalError({message: extractErrorMessage(e as AxiosError<IApiResponse>)}));
	}
};

export const fetchChecksumsSaga = function* () {
	try {
		const response = yield* call(Api.JSON.checksums);
		yield put(fetchChecksumsSuccess(response.data));
	} catch (e) {
		yield* put(fetchChecksumsFailed(e as IError));
	}
};

export const fetchMessageBarSaga = function* () {
	try {
		const response = yield* call(Api.JSON.message_bar);

		yield put(fetchMessageBarSuccess(response.data));
	} catch (e) {
		yield put(fetchMessageBarFailed(e as IError));
	}
};
