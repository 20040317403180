import React from "react";
import styled from "styled-components";

interface IProps {
	width?: number;
	height?: number;
	color?: string;
}

const SVG = styled.svg`
	polygon {
		transform: translate(-3px, -3px);
	}
`;

export const IconRemove: React.FC<IProps> = ({color, ...rest}) => (
	<SVG viewBox="0 0 24 24" fill={color} {...rest}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M19.5599 4.45209C19.3357 4.22739 19.0313 4.10111 18.7139 4.10111C18.3964 4.10111 18.0921 4.22739 17.8679 4.45209L11.9999 10.3081L6.13187 4.44009C5.90767 4.21539 5.60329 4.08911 5.28587 4.08911C4.96845 4.08911 4.66406 4.21539 4.43987 4.44009C3.97187 4.90809 3.97187 5.66409 4.43987 6.13209L10.3079 12.0001L4.43987 17.8681C3.97187 18.3361 3.97187 19.0921 4.43987 19.5601C4.90787 20.0281 5.66387 20.0281 6.13187 19.5601L11.9999 13.6921L17.8679 19.5601C18.3359 20.0281 19.0919 20.0281 19.5599 19.5601C20.0279 19.0921 20.0279 18.3361 19.5599 17.8681L13.6919 12.0001L19.5599 6.13209C20.0159 5.67609 20.0159 4.90809 19.5599 4.45209Z"
		/>
	</SVG>
);

IconRemove.defaultProps = {
	width: 24,
	height: 24,
	color: "currentColor",
};
