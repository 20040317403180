import {
	IContactUsRequestPayload,
	IResetPasswordPayload,
	ISagaAction,
	IUser,
	IUserUpdatePasswordPayload,
	IUserUpdatePayload,
} from "modules/types";
import {ILoginPayload, IRegisterPayload} from "modules/types/user";
import {Api, ApiError} from "modules/utils/Api";
import {SagaIterator} from "redux-saga";
import {call, delay, put, select, take} from "typed-redux-saga";
import {
	globalError,
	handleAuthModal,
	saveTeam,
	showNotification,
	userAccountRecoverFailed,
	userAccountRecoverSuccess,
	userChecked,
	userLoginSuccess,
	userRegisterSuccess,
	userStateChangeAction,
	userUpdateSuccess,
} from "modules/actions";
import {getIsSavingMode} from "modules/selectors";
import {RequestStateType} from "modules/types/enums";
import {
	closeAccountRecoverModal,
	openAccountRecoverModal,
	openInfoModal,
	openWelcomeModal,
} from "modules/actions/modals";
import {extractErrorMessage} from "modules/utils";
import {AxiosError} from "axios";
import {IApiResponse} from "modules/utils/Api/HTTPClient";

export const userRegisterSaga = function* ({payload}: ISagaAction<IRegisterPayload>): SagaIterator {
	try {
		const isSaving = yield* select(getIsSavingMode);
		const response = yield* call(Api.Auth.register, payload);
		ApiError.CHECK(response.data);
		const user = response.data.success.user;
		yield* put(userRegisterSuccess(user));

		yield* call(showModalsSaga, user);

		if (isSaving) {
			yield* put(saveTeam());
			yield* put(handleAuthModal({showAuthModal: false}));
		}
	} catch (e) {
		yield* put(globalError({message: extractErrorMessage(e as AxiosError<IApiResponse>)}));
	} finally {
		yield* put(
			userStateChangeAction({
				key: "register",
				state: RequestStateType.Idle,
			})
		);
		yield* put(
			userStateChangeAction({
				key: "update",
				state: RequestStateType.Idle,
			})
		);
		yield* put(userChecked());
	}
};

export const userLoginSaga = function* ({payload}: ISagaAction<ILoginPayload>): SagaIterator {
	try {
		const isSaving = yield* select(getIsSavingMode);
		const response = yield* call(Api.Auth.login, payload);
		ApiError.CHECK(response.data);
		const user = response.data.success.user;
		yield* put(userLoginSuccess(user));

		yield* call(showModalsSaga, user);

		if (isSaving) {
			yield* put(saveTeam());
			yield* put(handleAuthModal({showAuthModal: false}));
		}
		yield* delay(2000);
		yield* put(userChecked());
	} catch (e) {
		console.log(e);
		yield* put(globalError({message: extractErrorMessage(e as AxiosError<IApiResponse>)}));
		yield* put(userChecked());
	} finally {
		yield* put(
			userStateChangeAction({
				key: "login",
				state: RequestStateType.Idle,
			})
		);
	}
};

export const userLogoutSaga = function* (): SagaIterator {
	try {
		yield* call(Api.Auth.logout);
		window.location.href = "/";
	} catch (e) {
		yield* put(globalError({message: extractErrorMessage(e as AxiosError<IApiResponse>)}));
	} finally {
		yield* put(
			userStateChangeAction({
				key: "login",
				state: RequestStateType.Idle,
			})
		);
	}
};

export const userFetchSaga = function* (): SagaIterator {
	try {
		const response = yield* call(Api.User.getUser);
		ApiError.CHECK(response.data);
		const user = response.data.success.user;
		yield* put(userLoginSuccess(user));
		yield* put(userChecked());
		yield* call(showModalsSaga, user);

		yield* delay(2000);
	} catch (e) {
		yield* put(userChecked());
	} finally {
		yield* put(
			userStateChangeAction({
				key: "login",
				state: RequestStateType.Idle,
			})
		);
	}
};

export const userUpdateSaga = function* ({payload}: ISagaAction<IUserUpdatePayload>): SagaIterator {
	try {
		const response = yield* call(Api.User.updateUser, payload);
		yield* put(userUpdateSuccess(response.data.success.user));
	} catch (e) {
		yield* put(globalError({message: extractErrorMessage(e as AxiosError<IApiResponse>)}));
	} finally {
		yield* delay(2000);
		yield* put(
			userStateChangeAction({
				key: "update",
				state: RequestStateType.Idle,
			})
		);
	}
};

export const userUpdatePasswordSaga = function* ({
	payload,
}: ISagaAction<IUserUpdatePasswordPayload>): SagaIterator {
	try {
		yield* call(Api.User.changePassword, payload);
		yield* put(
			userStateChangeAction({
				key: "changePassword",
				state: RequestStateType.Success,
			})
		);
		yield* put(
			showNotification({
				message: "Password has been successfully changed",
			})
		);
	} catch (e) {
		yield* put(globalError({message: extractErrorMessage(e as AxiosError<IApiResponse>)}));
	} finally {
		yield* delay(2000);
		yield* put(
			userStateChangeAction({
				key: "changePassword",
				state: RequestStateType.Idle,
			})
		);
	}
};

export const requestPasswordChangeSaga = function* ({payload}: ISagaAction<string>): SagaIterator {
	try {
		yield* call(Api.User.passwordChangeRequest, payload);
		yield* put(
			userStateChangeAction({
				key: "forgotPassword",
				state: RequestStateType.Success,
			})
		);
		yield* put(
			openInfoModal({
				text: "reset.password.body",
			})
		);
	} catch (e) {
		yield* put(globalError({message: extractErrorMessage(e as AxiosError<IApiResponse>)}));
	} finally {
		yield* put(
			userStateChangeAction({
				key: "forgotPassword",
				state: RequestStateType.Idle,
			})
		);
	}
};

export const userResetPasswordSaga = function* ({
	payload,
}: ISagaAction<IResetPasswordPayload>): SagaIterator {
	try {
		yield* call(Api.User.passwordResetRequest, payload);
		yield* put(
			userStateChangeAction({
				key: "resetPassword",
				state: RequestStateType.Success,
			})
		);
		yield* put(
			openInfoModal({
				text: "Your password has been changed successfully",
			})
		);
	} catch (e) {
		yield* put(globalError({message: extractErrorMessage(e as AxiosError<IApiResponse>)}));
	} finally {
		yield* delay(2000);
		yield* put(
			userStateChangeAction({
				key: "resetPassword",
				state: RequestStateType.Idle,
			})
		);
	}
};

export const contactUsSaga = function* ({
	payload,
}: ISagaAction<IContactUsRequestPayload>): SagaIterator {
	try {
		yield* call(Api.User.contactUs, payload);
		yield* put(
			openInfoModal({
				title: "Thank you",
				text: "Your support request has been received.",
			})
		);
		yield* put(
			userStateChangeAction({
				key: "contactUs",
				state: RequestStateType.Success,
			})
		);
	} catch (e) {
		yield* put(globalError({message: extractErrorMessage(e as AxiosError<IApiResponse>)}));
	} finally {
		yield* delay(2000);
		yield* put(
			userStateChangeAction({
				key: "contactUs",
				state: RequestStateType.Idle,
			})
		);
	}
};

export const showModalsSaga = function* (user: IUser): SagaIterator {
	try {
		if (!user.isRecovered) {
			yield* put(openAccountRecoverModal());
			yield* take(userAccountRecoverSuccess);
		}

		if (!user.isWelcomeModalViewed) {
			yield* put(openWelcomeModal());
			yield* call(Api.User.viewWelcomeModal);
		}
	} catch (e) {
		yield* put(globalError({message: extractErrorMessage(e as AxiosError<IApiResponse>)}));
	}
};

export const userAccountRecoverSaga = function* () {
	try {
		const response = yield* call(Api.User.recover);
		const user = response.data.success.user;
		yield* put(closeAccountRecoverModal());
		yield* put(userAccountRecoverSuccess(user));
	} catch (e) {
		yield* put(userAccountRecoverFailed());
		yield* put(globalError({message: extractErrorMessage(e as AxiosError<IApiResponse>)}));
	}
};
