import React, {PropsWithChildren, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {fetchPlayers, fetchRounds, fetchSquads} from "modules/actions";
import {getSport} from "modules/selectors";

export const HOCMain: React.FC<PropsWithChildren> = ({children}) => {
	const dispatch = useDispatch();
	const sport = useSelector(getSport);

	useEffect(() => {
		dispatch(fetchPlayers());
		dispatch(fetchSquads());
		dispatch(fetchRounds());
	}, [dispatch, sport]);

	return (
		<React.Fragment>
			<React.Fragment>{children}</React.Fragment>
		</React.Fragment>
	);
};
