import React, {PropsWithChildren, useEffect} from "react";
import {fetchUser} from "modules/actions";
import {useDispatch, useSelector} from "react-redux";
import {getIsUserChecked} from "modules/selectors";
import {GlobalErrorModal, GlobalInfoModal} from "components/Modals";
import {PagePreloader} from "components/Preloader";

export const HOCAuth: React.FC<PropsWithChildren> = ({children}) => {
	const dispatch = useDispatch();
	const isChecked = useSelector(getIsUserChecked);

	useEffect(() => {
		dispatch(fetchUser());
	}, [dispatch]);

	if (!isChecked) {
		return <PagePreloader />;
	}

	return (
		<React.Fragment>
			<React.Fragment>{children}</React.Fragment>
			<GlobalErrorModal />
			<GlobalInfoModal />
		</React.Fragment>
	);
};
