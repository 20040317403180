import {SecondaryButton} from "components/styled";
import React, {useCallback, useEffect, useState} from "react";
import styled from "styled-components";
import {IJoinLeague} from "modules/types";
import {getLeagueRequestStateByKey, getProject, getSport, getRoundById} from "modules/selectors";
import {joinLeagueRequest} from "modules/actions";
import {media} from "assets/media";
import {getSplitString} from "modules/utils";
import {Exist} from "components/Exist";
import {CircularPreloaded} from "components/Preloader";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";

const Row = styled.tr`
	border: 1px solid #dddddd;
	box-sizing: border-box;

	td {
		color: ${({theme}) => theme.text_color};
		padding: 10px 0 10px 20px;
		font-size: 14px;
		letter-spacing: 0;
		line-height: 14px;
		font-weight: 400;
		text-align: right;

		&.join-league-row-action {
			padding-right: 20px;

			button {
				padding: 11px 30px;
				min-width: 90px;

				&.active {
					background: ${({theme}) => theme.button.secondary.hover} !important;
				}
			}
		}

		&.join-league-row-name {
			text-align: left;
		}
	}

	@media screen and ${media.lessThanMD} {
		border-left: unset;
		border-right: unset;

		td {
			padding: 4px 12px;

			&.join-league-row-week {
				display: none;
			}
		}

		&.join-league-row-action {
			//button {
			//padding: 4px 12px;
			//}
		}
	}
`;

interface IProps {
	league: IJoinLeague;
}

export const JoinRow: React.FC<IProps> = ({league}) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [isJoin, setIsJoin] = useState(false);
	const {isSuccess} = useSelector(getLeagueRequestStateByKey)("join");
	const sport = useSelector(getSport);
	const project = useSelector(getProject);
	const joinClass = isJoin ? "active" : "";
	const preparedNumTeams = getSplitString(league.num_teams);
	const startRound = useSelector(getRoundById)(league?.start_round || 0);

	const onLeagueJoin = useCallback(() => {
		setIsJoin(true);
		dispatch(joinLeagueRequest(league.code));
	}, [dispatch, league.code]);

	useEffect(() => {
		if (isSuccess && isJoin) {
			navigate(`/${project}/${sport}/leagues/${league.id}/about`);
		}
	}, [project, sport, navigate, isSuccess, isJoin, league]);

	return (
		<Row className={`join-league-row ${league.id}`}>
			<td className="join-league-row-name">{league.name}</td>
			<td className="join-league-row-week">{startRound?.number}</td>
			<td className="join-league-row-players">{preparedNumTeams}</td>
			<td className="join-league-row-action">
				<SecondaryButton className={joinClass} onClick={onLeagueJoin}>
					<Exist when={isJoin}>
						<CircularPreloaded size={16} color="#FFFFFF" />
					</Exist>
					<Exist when={!isJoin}>Join</Exist>
				</SecondaryButton>
			</td>
		</Row>
	);
};
