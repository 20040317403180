import styled from "styled-components";
import IconSearch from "assets/images/icons/IconSearch.svg";
import {PureButton} from "components/styled";

export const PlayersPoolContainer = styled.div`
	width: 100%;
	height: 100%;
	border: 1px solid #ddd;
	border-radius: 5px 5px 0 0;

	@media (max-width: 700px) {
		width: auto;
	}
`;
export const PlayersPoolHeader = styled.div`
	border-radius: 5px 5px 0 0;
	background: ${({theme}) => theme.secondary};
	padding: 24px 21px;
	box-sizing: border-box;
	color: #ffffff;
	font-size: 16px;
	letter-spacing: 0;
	line-height: 16px;

	@media (max-width: 1000px) {
		font-size: 12px;
		padding: 10px;
	}
`;
export const PlayersPoolFilters = styled.div`
	background-color: #f8f8f8;
	padding: 20px 20px 0;
	box-sizing: border-box;
	transition: all 0.5s;

	@media (max-width: 1000px) {
		padding: 10px 10px 0;
	}
`;

interface IPlayersPoolFiltersRow {
	fullWidthSelect?: boolean;
}

export const PlayersPoolFiltersRow = styled.div<IPlayersPoolFiltersRow>`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-bottom: 10px;

	@media (max-width: 1000px) {
		padding-bottom: 0;
	}

	button {
		width: 80px;
		height: 40px;
		display: flex;
		align-items: center;
		justify-content: center;

		svg {
			margin-left: 5px;
		}
	}

	.search {
		border: 1px solid #ddd;
		width: 210px;
		margin-bottom: 0;
		background: url(${IconSearch}) no-repeat right 10px center #fff;
	}

	> select {
		width: ${(props) => (props.fullWidthSelect ? "100%" : "48%")};
		border-color: #ddd;
	}
`;

export const PlayersPoolThead = styled.div`
	//border-top: 2px solid #ad00ff;
	border-bottom: 1px solid #ddd;
	display: flex;
	align-items: center;
	width: 100%;
	height: 50px;
	font-size: 10px;
`;
export const PlayersPoolBody = styled.div`
	width: 100%;
	height: 1000px;
	max-height: 710px;
	overflow: hidden;
	margin-bottom: 0;

	@media (max-width: 1000px) {
		max-height: 70vh;
		min-height: 300px;
		height: 600px;
		margin-bottom: 0;
	}

	.ReactVirtualized__List {
		-ms-overflow-style: none;
		scrollbar-width: none;

		&::-webkit-scrollbar {
			display: none;
		}
	}
`;
export const PlayersPoolCell = styled.div`
	min-width: 38px;
	display: flex;
	align-items: center;
	text-align: center;
	justify-content: center;
	color: ${({theme}) => theme.secondary};
	font-size: 14px;
	line-height: 14px;

	&.max-width {
		width: 100%;
	}

	&.header {
		color: #222222;
	}

	&.active {
		background: #f1f1f1;
	}

	&.column {
		cursor: pointer;
		padding: 4px 0;
		box-sizing: border-box;
		height: 100%;
		flex-direction: column;
		justify-content: space-between;
	}
`;
export const PlayerPoolStatus = styled.div`
	width: 20px;
	margin: 0 10px;

	img {
		width: 16px;
		height: auto;
	}

	&.pointer {
		cursor: pointer;
	}

	@media (max-width: 1000px) {
		display: none;
	}
`;
export const PlayerPoolName = styled(PureButton)`
	p {
		color: ${({theme}) => theme.text_color};
		font-size: 14px;
		letter-spacing: 0;
		line-height: 14px;
		margin: 0;
		text-align: left;
		text-decoration: underline;

		&.squad {
			text-decoration: none;
			font-size: 11px;
		}

		b {
			font-weight: bold;
		}
	}

	@media (max-width: 1000px) {
		padding-left: 20px;
	}

	&:disabled {
		cursor: initial;
	}
`;
