import React from "react";
import {Navigate, Outlet, RouteProps, useLocation} from "react-router-dom";
import {useSelector} from "react-redux";
import {getIsUserLogged, getProject} from "modules/selectors";
import {ILocationState} from "modules/types";
import {isGameDayGame} from "modules/utils";

export const NotAuthOnlyRoute: React.FC<RouteProps> = (props) => {
	const location = useLocation();
	const currentProject = useSelector(getProject);
	const loggedInLink = isGameDayGame(currentProject) ? "/gameday/IFL/team" : "/classic/IFL/team";
	const backURL = String((location.state as ILocationState)?.from || loggedInLink);
	const isLoggedIn = useSelector(getIsUserLogged);

	if (!backURL && isLoggedIn) {
		return <Navigate to={loggedInLink} />;
	}

	if (isLoggedIn) {
		return <Navigate to={backURL} />;
	}
	return <Outlet />;
};
