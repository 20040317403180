import {getActualRound, getIsSomeRoundActive, getTradesDeadlineTime} from "modules/selectors";
import React, {useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import styled from "styled-components";
import IconClock from "assets/images/icons/clock.png";
import {CircularPreloaded, Exist, Timer} from "components";
import {fetchRoundsDelayed} from "modules/actions";
import {media} from "assets/media";
import {DateTime} from "luxon";
import {useTranslation} from "react-i18next";

const Wrapper = styled.div`
	box-sizing: border-box;
	background: ${({theme}) => theme.secondary};
	padding: 16px 24px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;

	h3 {
		color: #ffffff;
		font-size: 30px;
		letter-spacing: 0;
		line-height: 30px;
		text-align: center;
		font-weight: normal;
		text-transform: uppercase;
		margin: 6px 0 12px 0;
	}

	h4 {
		color: #ffffff;
		font-size: 18px;
		letter-spacing: 0;
		line-height: 30px;
		text-align: center;
		font-weight: normal;
		text-transform: uppercase;
		margin: 0;

		span {
			margin-right: 12px;
		}
	}

	@media screen and ${media.lessThanMD} {
		-webkit-border-radius: 0;
		-moz-border-radius: 0;
		border-radius: 0;
		flex-direction: row;
		gap: 20px;
		padding: 10px 12px;

		h3 {
			font-size: 20px;
			line-height: 20px;
			margin: 4px 0;
		}
	}
`;

const NextRoundText = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 10px;

	img {
		width: 24px;
		height: auto;
	}

	span {
		color: #ffffff;
		font-size: 10px;
		letter-spacing: 0;
		line-height: 10px;
	}
`;

export const LockoutTimer: React.FC = () => {
	const dispatch = useDispatch();
	const [isStarting, setIsStarting] = useState(false);
	const tradesDeadlineTime = useSelector(getTradesDeadlineTime);
	const {t} = useTranslation();
	const hasActiveRound = useSelector(getIsSomeRoundActive);
	const actualRound = useSelector(getActualRound);
	const startDate = DateTime.fromISO(actualRound?.start_date || "");
	const someDate = DateTime.fromJSDate(new Date());

	useEffect(() => {
		if (actualRound) {
			setIsStarting(false);
		}
	}, [actualRound]);

	const isActualRoundBeforeCurrentDate = startDate.startOf("day") < someDate.startOf("day");

	const onTradesDeadline = useCallback(() => {
		setIsStarting(true);
		dispatch(fetchRoundsDelayed());
	}, [dispatch]);

	if (isActualRoundBeforeCurrentDate) {
		return null;
	}

	if (isStarting) {
		return (
			<Wrapper className="lockout-timer">
				<h4>
					<span>Starting...</span>
					<CircularPreloaded color="#FFFFFF" size={16} />
				</h4>
			</Wrapper>
		);
	}

	return (
		<Wrapper className="lockout-timer">
			<NextRoundText>
				<Exist when={hasActiveRound}>
					<span>
						{t("player_profiles.detail_stats.th_round")} {actualRound?.number}:
					</span>
				</Exist>
				<Exist when={!hasActiveRound}>
					<img src={IconClock} alt="clock" />
					<span>{t("fixture.next.countdown")}</span>
				</Exist>
			</NextRoundText>
			<Exist when={hasActiveRound}>
				<h3>{t("fixture.round.in_progress")}</h3>
			</Exist>
			<Exist when={!hasActiveRound}>
				<Timer date={tradesDeadlineTime} onComplete={onTradesDeadline} />
			</Exist>
		</Wrapper>
	);
};
