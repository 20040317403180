import {IconChevron} from "components";
import {ISortBy} from "modules/types/team";
import React, {SyntheticEvent, useCallback} from "react";
import {PlayersPoolCell} from "./styled";

interface ICellProps {
	sortBy: ISortBy;
	updateSortOnCLick: (e: SyntheticEvent<HTMLElement, Event>) => void;
	title: string;
	sortProperty: string;
	tooltipId: string;
	tooltip: string;
	className?: string;
}

export const PlayerPollCell: React.FC<ICellProps> = ({
	title,
	sortProperty,
	updateSortOnCLick,
	sortBy,
	tooltipId,
	tooltip,
}) => {
	const getChevronColor = useCallback(
		(field: string, order: boolean) => {
			const isActive = field === sortBy.sortBy && order === sortBy.order;
			return isActive ? "#0000B8" : "#DDDDDD";
		},
		[sortBy]
	);
	const columnProps = {
		className: "column header",
		onClick: updateSortOnCLick,
	};
	return (
		<React.Fragment>
			<PlayersPoolCell
				{...columnProps}
				data-sort={sortProperty}
				data-tip=""
				data-event="touchstart focus mouseover"
				data-event-off="mouseout"
				data-for={tooltipId}>
				<IconChevron color={getChevronColor(sortProperty, false)} to={"top"} />
				{title}
				<IconChevron color={getChevronColor(sortProperty, true)} />
			</PlayersPoolCell>
			{/*<ToolTip*/}
			{/*	id={tooltipId}*/}
			{/*	effect="solid"*/}
			{/*	globalEventOff="touchstart"*/}
			{/*	border={true}*/}
			{/*	type="light"*/}
			{/*>*/}
			{/*	{tooltip}*/}
			{/*</ToolTip>*/}
		</React.Fragment>
	);
};
