import React, {useCallback, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useLocation} from "react-router-dom";
import {useIsPathIncludes, useNavigateWithSport} from "modules/utils/hooks";
import {
	getSport,
	getTeamRequestState,
	getTradePairsAccordingPosition,
	isTeamFullFilled,
} from "modules/selectors";
import {flatMap} from "lodash";
import {openTransferModal} from "modules/actions/modals";
import {removePlayerFromTeam, resetTradeByIndex, tradeOutPlayer} from "modules/actions";
import {PrimaryButton, SecondaryButton} from "components/styled";
import {useTranslation} from "react-i18next";

export const TransferButtons: React.FC = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const sportNavigate = useNavigateWithSport();
	const sport = useSelector(getSport);
	const {isLoading, isSuccess} = useSelector(getTeamRequestState)("trade");
	const isTeamFull = useSelector(isTeamFullFilled);
	const isTransferPage = useIsPathIncludes("transfers");
	const trades = useSelector(getTradePairsAccordingPosition);
	const isIncompleteTrades = flatMap(trades).some((trade) => trade === undefined);
	const {t} = useTranslation();

	const isMakeTradeDisabled = !isTeamFull || isLoading || isIncompleteTrades;
	const isResetTradeDisabled = trades.length === 0 || isLoading;

	const makeTransfer = useCallback(() => {
		dispatch(openTransferModal());
	}, [dispatch]);

	const makeReset = useCallback(() => {
		for (let i = 0; i < trades.length; i++) {
			//reset all trades expect first one
			dispatch(resetTradeByIndex(i));
		}

		// Remove first trade player
		if (trades[0][1]) {
			dispatch(tradeOutPlayer(trades[0][1]));
			dispatch(removePlayerFromTeam(trades[0][1]));
		}
	}, [dispatch, trades]);

	console.log(makeReset);

	const resetAll = useCallback(() => {
		for (let i = 0; i < trades.length; i++) {
			dispatch(resetTradeByIndex(0));
		}
	}, [trades, dispatch]);

	useEffect(() => {
		if (isSuccess) {
			sportNavigate(`/team`);
		}
	}, [isSuccess, sport, sportNavigate]);

	useEffect(() => {
		if (!isTransferPage) {
			resetAll();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, location, isTransferPage]);

	if (!isTransferPage) {
		return null;
	}

	return (
		<React.Fragment>
			<SecondaryButton disabled={isResetTradeDisabled} onClick={resetAll}>
				{t("team.transfer_table.th_3")}
			</SecondaryButton>
			<PrimaryButton disabled={isMakeTradeDisabled} onClick={makeTransfer}>
				{t("my_team.transfer_bar.make_transfers")}
			</PrimaryButton>
		</React.Fragment>
	);
};
