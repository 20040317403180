import React from "react";
import styled from "styled-components";
import {
	HeaderContainer,
	HeaderNavLink,
	LogoWrapper,
	HeaderLogo,
	PaddingContainer,
} from "components/styled";
import {DesktopSubMenu} from "./DesktopSubMenu";
import {HeaderLogoImage} from "./HeaderLogoImage";
import {useLocation} from "react-router-dom";
import {Exist, ExistForVersion} from "components";
import fantasy from "assets/images/fantasy_logo_wrap.svg";
import headerBackground from "assets/images/headerBackground.jpg";
import {HeaderSportLink} from "components/Header/HeaderSportLink";
import {useSelector} from "react-redux";
import {getIsUserLogged} from "modules/selectors";
import {useTranslation} from "react-i18next";
import {LanguageSelector} from "./LanguageSelector";

const AppMenuWrapper = styled.div`
	position: relative;
	height: 80px;
	width: 100%;
	background: url(${headerBackground});
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	color: ${({theme}) => theme.header.main.color};
	z-index: 5;
`;

const InnerContainer = styled(HeaderContainer)`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	height: 100%;
	gap: 13px;
`;

const RightSideLinks = styled.div`
	display: flex;
	align-items: center;
	height: 100%;
	gap: 13px;
	margin-left: auto;
`;

const AppSubMenuWrapper = styled.div`
	height: 52px;
	width: 100%;
	background: ${({theme}) => theme.header.sub.background};
	color: ${({theme}) => theme.header.sub.color};
`;

const OuterContainer = styled(PaddingContainer)`
	height: 100%;
	display: flex;
	justify-content: flex-start;
`;

const Relative = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	gap: 32px;
	padding-right: 32px;
`;

const PATHS_HAVE_SUB_MENU = ["/leagues", "/help", "/account"];

export const DesktopMenu: React.FC = () => {
	const location = useLocation();
	const {t} = useTranslation();
	const isSubMenuVisible = PATHS_HAVE_SUB_MENU.some((path) => location.pathname.includes(path));
	const isLogged = useSelector(getIsUserLogged);
	return (
		<React.Fragment>
			<AppMenuWrapper>
				<InnerContainer>
					<Relative>
						<LogoWrapper>
							<HeaderLogo to={"/"}>
								<HeaderLogoImage />
							</HeaderLogo>
							<img src={fantasy} alt="Football Fantasy" width="106" />
						</LogoWrapper>
					</Relative>
					<Exist when={isLogged}>
						<HeaderSportLink to="/team">{t("team.header.my_teams")}</HeaderSportLink>
						<HeaderSportLink to="/leagues">{t("team.header.leagues")}</HeaderSportLink>
						<ExistForVersion version={2.0}>
							<HeaderSportLink to="/leaderboard">
								{t("team.header.leaderboard")}
							</HeaderSportLink>
							<HeaderSportLink to="/stats-centre">
								{t("team.header.stats_centre")}
							</HeaderSportLink>
						</ExistForVersion>
					</Exist>

					<HeaderNavLink to="/help">{t("landing.header.help")}</HeaderNavLink>
					<DesktopActionMenu />
				</InnerContainer>
			</AppMenuWrapper>

			<Exist when={isSubMenuVisible}>
				<AppSubMenuWrapper>
					<OuterContainer>
						<DesktopSubMenu visibilityPath="/leagues">
							<HeaderSportLink end to="/leagues">
								{t("leagues.title.my_leagues")}
							</HeaderSportLink>
							<HeaderSportLink to="/leagues/create">
								{t("create_league.title.text")}
							</HeaderSportLink>
							<HeaderSportLink to="/leagues/join">
								{t("join_leagues.modal.button_join")}
							</HeaderSportLink>
						</DesktopSubMenu>
						<DesktopSubMenu visibilityPath="/help">
							<HeaderNavLink end to="/help/guidelines">
								{t("help.subtitle.guidlines")}
							</HeaderNavLink>
							<HeaderNavLink to="/help/prizes">
								{t("help.subtitle.prizes")}
							</HeaderNavLink>
							<HeaderNavLink to="/help/faqs">{t("help.subtitle.faqs")}</HeaderNavLink>
							<HeaderNavLink to="/help/contact-us">
								{t("help.subtitle.contact")}
							</HeaderNavLink>
							<HeaderNavLink to="/help/terms">
								{t("landing.footer.terms")}
							</HeaderNavLink>
						</DesktopSubMenu>
						<DesktopSubMenu visibilityPath="/account">
							<HeaderNavLink end to="/account">
								{t("my_account.title.text")}
							</HeaderNavLink>
							<HeaderNavLink to="/account/password">
								{t("my_account.subtitle.password")}
							</HeaderNavLink>
						</DesktopSubMenu>
					</OuterContainer>
				</AppSubMenuWrapper>
			</Exist>
		</React.Fragment>
	);
};

const DesktopActionMenu: React.FC = () => {
	const isLogged = useSelector(getIsUserLogged);
	const {t} = useTranslation();

	return (
		<RightSideLinks>
			<LanguageSelector />
			<Exist when={!isLogged}>
				<HeaderNavLink to="/login">{t("landing.header.Log_in")}</HeaderNavLink>
			</Exist>
			<Exist when={isLogged}>
				<HeaderNavLink to="/account">{t("team.header.my_account")}</HeaderNavLink>
			</Exist>
		</RightSideLinks>
	);
};
