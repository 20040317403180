import React from "react";
import styled from "styled-components";
import {IconInjury, IconPlus, IconRemove, IconReset} from "components";
import {ReactComponent as IconInfo} from "assets/images/icons/info.svg";
import IconCaptain from "assets/images/icons/IconCaptain.svg";
import IconVice from "assets/images/icons/IconViceCaptain.svg";
import {useTranslation} from "react-i18next";

const Wrapper = styled.div`
	background: ${({theme}) => theme.secondary};
	border-radius: 5px;
	width: 100%;
	max-width: 660px;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	justify-content: space-around;
	flex-wrap: wrap;
	gap: 6px;
	color: #fff;
	padding: 10px 5px;

	@media (max-width: 700px) {
		justify-content: flex-start;
		max-width: 305px;
		flex-wrap: wrap;
		padding: 5px 10px;
	}
`;

const Status = styled.div`
	display: flex;
	align-items: center;

	span {
		position: relative;
		font-size: 14px;
		display: inline-block;
		margin-left: 6px;
	}

	@media (max-width: 700px) {
		padding: 5px 0;

		span {
			font-size: 12px;
		}
	}
`;

const IconAddWrapper = styled.div`
	color: #222;
	min-width: 16px;
	width: 16px;
	height: 16px;
	border-radius: 50%;
	display: inline-flex;
	align-items: center;
	justify-content: center;

	img {
		width: 100%;
		height: 100%;
	}
`;

const IconAddWrapperGrey = styled(IconAddWrapper)`
	background: #f0f0f0;
`;

const IconResetWrapper = styled(IconAddWrapper)`
	svg {
		width: 9px;
		height: 9px;
	}
`;

const IconNotSelected = styled(IconInjury)`
	overflow: hidden;
	border-radius: 50%;
`;

export const Legend: React.FC = (props) => {
	const {t} = useTranslation();
	return (
		<Wrapper {...props}>
			<Status>
				<IconAddWrapper>
					<IconInfo fill="#F0F0F0" />
				</IconAddWrapper>
				<span>{t("team.pitch.key.info")}</span>
			</Status>
			<Status>
				<IconAddWrapperGrey>
					<IconRemove width={10} height={10} />
				</IconAddWrapperGrey>
				<span>{t("team.pitch.key.remove")}</span>
			</Status>
			<Status>
				<IconNotSelected />
				<span>{t("team.pitch.key.unavailable")}</span>
			</Status>
			<Status>
				<IconAddWrapperGrey>
					<IconPlus />
				</IconAddWrapperGrey>
				<span>{t("team.pitch.key.add")}</span>
			</Status>
			<Status>
				<IconResetWrapper>
					<IconAddWrapperGrey>
						<IconReset color="#000" />
					</IconAddWrapperGrey>
				</IconResetWrapper>
				<span>{t("team.pitch.key.reset")}</span>
			</Status>
			<Status>
				<IconResetWrapper>
					<IconAddWrapper>
						<img src={IconCaptain} alt="captain" />
					</IconAddWrapper>
				</IconResetWrapper>
				<span>{t("team.pitch.key.captain")}</span>
			</Status>
			<Status>
				<IconResetWrapper>
					<IconAddWrapper>
						<img src={IconVice} alt="vice-captain" />
					</IconAddWrapper>
				</IconResetWrapper>
				<span>{t("team.pitch.key.vice.captain")}</span>
			</Status>
		</Wrapper>
	);
};
