import React, {useCallback} from "react";
import styled from "styled-components";
import {useSelector} from "react-redux";
import {
	getIsTeamLockout,
	getPlayerById,
	getSelectedRoundId,
	getTeamHeads,
	isPlayerPlaying,
	isSelectedRoundActive,
} from "modules/selectors";
import {PlayerStatus, StatsTab} from "modules/types/enums";
import {Exist} from "components/Exist";
import {media} from "assets/media";
import {currencyFormatDecimal} from "modules/utils";
import {every} from "lodash";

const PlayerDataPart = styled.div`
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex: 1;
	font-weight: bold;

	&.white {
		background: #fff;
	}

	&.grey {
		background: #ddd;
	}

	&.thin {
		font-weight: normal;
	}

	&.active {
		background: #0bad0b;
		color: #ffffff;
	}

	&.injured {
		background: #a82727;
		color: #ffffff;
	}

	@media screen and ${media.lessThanMD} {
		font-size: 8px;
	}
`;

interface IProps {
	shownStats?: StatsTab;
	playerId?: number;
}

const getStatOrDash = (stats?: number, isCaptain?: boolean): string => {
	if (!stats || isNaN(stats)) {
		return "-";
	}

	return String(isCaptain ? stats * 2 : stats);
};

export const Stats: React.FC<IProps> = ({shownStats, playerId}) => {
	const player = useSelector(getPlayerById)(playerId);
	const isPlaying = useSelector(isPlayerPlaying)(playerId);
	const teamHeads = useSelector(getTeamHeads);
	const isLockout = useSelector(getIsTeamLockout);
	const selectedRound = useSelector(getSelectedRoundId);
	const isRoundActive = useSelector(isSelectedRoundActive);
	const isLiveScore = every([isPlaying, isRoundActive]);
	const injuredClass = player?.status === PlayerStatus.Injured ? "injured" : "";
	const isCaptain = playerId === teamHeads.captain;

	const isTabs = useCallback(
		(tabs: StatsTab[]) => {
			if (shownStats === undefined) {
				return false;
			}
			return tabs.includes(shownStats);
		},
		[shownStats]
	);

	if (!player) {
		return null;
	}

	if (isTabs([StatsTab.TransferValue])) {
		return (
			<PlayerDataPart className="grey thin">
				{currencyFormatDecimal(player.cost, true)}
			</PlayerDataPart>
		);
	}

	if (!isLockout || !selectedRound) {
		return null;
	}

	if (isLiveScore) {
		return (
			<PlayerDataPart className={`white thin active ${injuredClass}`}>
				{getStatOrDash(player.stats.round_scores?.[selectedRound], isCaptain)}
			</PlayerDataPart>
		);
	}

	return (
		<Exist when={isTabs([StatsTab.Weekly, StatsTab.Overall])}>
			<PlayerDataPart className="grey">
				{getStatOrDash(player.stats.round_scores?.[selectedRound], isCaptain)}
			</PlayerDataPart>
		</Exist>
	);
};
