import React from "react";
import styled from "styled-components";
import {media} from "assets/media";
import {APP_IS_AD_ENABLED} from "modules/constants";
import bannerImg from "assets/images/sponsor/banner_img.jpg";
import sideBannerImg from "assets/images/sponsor/side_banner_img.jpg";
import adLineLogo from "assets/images/sponsor/ford_line_logo.svg";

const AdWrapper = styled.div`
	box-sizing: border-box;
	width: 100%;
	padding: 32px 24px;
	display: flex;
	justify-content: center;

	&.none {
		opacity: 0;
		pointer-events: none;
		height: 80px;
	}

	@media screen and (max-width: ${media.sm}) {
		padding: 16px;
	}
`;

const SideAdvertisement = styled.div`
	box-sizing: border-box;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;

	img {
		display: block;
		width: 100%;
	}
`;

const SideAdvertLink = styled.a`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 300px;
	height: 250px;
`;

const Advertisement = styled.a`
	height: 90px;
	max-width: 728px;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;

	img {
		display: block;
		height: 100%;
	}

	@media screen and (max-width: ${media.sm}) {
		height: 50px;
	}
`;

const AdvertLine = styled.div`
	overflow: hidden;
	width: 100%;
	height: 40px;
	background: url(${adLineLogo}) repeat-x 0 50% ${({theme}) => theme.secondary};

	&.top {
		border-radius: 5px 5px 0 0;
	}

	&.bottom {
		border-radius: 0 0 5px 5px;
	}
`;

interface IProps {
	className?: string;
}

export const AdBlock: React.FC<IProps> = ({className = ""}) => {
	const adClass = APP_IS_AD_ENABLED ? "" : "none";
	return (
		<AdWrapper className={`${className} ${adClass}`}>
			<Advertisement href="https://www.ford.is/is/nyir-bilar" target="_blank">
				<img src={bannerImg} alt="banner" />
			</Advertisement>
		</AdWrapper>
	);
};

interface IProps {
	className?: string;
}

export const AdBlockSideBar: React.FC<IProps> = ({className = ""}) => {
	if (!APP_IS_AD_ENABLED) {
		return null;
	}
	return (
		<SideAdvertisement className={className}>
			<SideAdvertLink href="https://www.ford.is/is/nyir-bilar" target="_blank">
				<img src={sideBannerImg} alt="side banner" />
			</SideAdvertLink>
		</SideAdvertisement>
	);
};

export const AdLine: React.FC<IProps> = ({className = ""}) => {
	if (!APP_IS_AD_ENABLED) {
		return null;
	}

	return <AdvertLine className={className} />;
};
