import {chain, memoize} from "lodash";
import {
	getActiveMatches,
	getActiveAndCompleteMatches,
	getPlayersById,
	getTeamLineupIDs,
} from "modules/selectors";
import {IMatch} from "modules/types/json";
import {createSelector} from "reselect";

export const getPlayersFromMatches = createSelector(
	getTeamLineupIDs,
	getPlayersById,
	(lineupIDs, playersById) =>
		memoize((matches: IMatch[]) => {
			const playingTeams = chain(matches)
				.map(({home_squad_id, away_squad_id}) => [home_squad_id, away_squad_id])
				.flatten()
				.uniq()
				.value();

			return lineupIDs.filter((playerID) => {
				const player = playersById[playerID];

				if (player) {
					return playingTeams.includes(player.squadId);
				}
				return false;
			});
		})
);

export const isPlayerPlaying = createSelector(
	getActiveMatches,
	getPlayersFromMatches,
	(matches, playersFromMatches) =>
		memoize((playerID: number = 0) => playersFromMatches(matches).includes(playerID))
);

export const isPlayerLocked = createSelector(
	getActiveAndCompleteMatches,
	getPlayersFromMatches,
	(matches, playersFromMatches) =>
		memoize((playerID: number = 0) => playersFromMatches(matches).includes(playerID))
);
