import {createReducer} from "redux-act";
import {IUserReducer} from "modules/types/reducers";
import * as actions from "modules/actions";
import {RequestStateType} from "modules/types/enums";
import {IUser} from "modules/types/user";
import {IGenerateRequestStateHandler} from "modules/types";

const defaultState: IUserReducer = {
	isUserChecked: false,
	isUserLogged: false,
	user: undefined,

	userState: {
		register: RequestStateType.Idle,
		login: RequestStateType.Idle,
		fetch: RequestStateType.Idle,
		update: RequestStateType.Idle,
		changePassword: RequestStateType.Idle,
		forgotPassword: RequestStateType.Idle,
		resetPassword: RequestStateType.Idle,
		contactUs: RequestStateType.Idle,
	},
};

const onUserChecked = (state: IUserReducer) => ({
	...state,
	isUserChecked: true,
});

const onUserRegisterRequest = (state: IUserReducer) => ({
	...state,
	userState: {...state.userState, register: RequestStateType.Loading},
});

const onUserRegisterSuccess = (state: IUserReducer, payload: IUser): IUserReducer => ({
	...state,
	user: payload,
	isUserLogged: true,
	userState: {
		...state.userState,
		register: RequestStateType.Success,
		update: RequestStateType.Success,
	},
});

const onUserLoginRequest = (state: IUserReducer) => ({
	...state,
	userState: {...state.userState, login: RequestStateType.Loading},
});

const onUserLoginSuccess = (state: IUserReducer, payload: IUser) => ({
	...state,
	user: payload,
	isUserLogged: true,
	userState: {...state.userState, login: RequestStateType.Idle},
});

const onUserClear = (state: IUserReducer) => ({
	...state,
	user: undefined,
	isUserLogged: false,
});

const onUserUpdateRequest = (state: IUserReducer): IUserReducer => ({
	...state,
	userState: {...state.userState, update: RequestStateType.Loading},
});

const onUserChangePasswordRequest = (state: IUserReducer): IUserReducer => ({
	...state,
	userState: {...state.userState, changePassword: RequestStateType.Loading},
});

const onUserGeneralStateHandler = (
	state: IUserReducer,
	payload: IGenerateRequestStateHandler<IUserReducer["userState"]>
): IUserReducer => ({
	...state,
	userState: {...state.userState, [payload.key]: payload.state},
});

const onRequestPasswordChange = (state: IUserReducer): IUserReducer => ({
	...state,
	userState: {...state.userState, forgotPassword: RequestStateType.Loading},
});

const onContactUsRequest = (state: IUserReducer): IUserReducer => ({
	...state,
	userState: {...state.userState, contactUs: RequestStateType.Loading},
});

const onUserAccountRecoverSuccess = (state: IUserReducer, payload: IUser): IUserReducer => ({
	...state,
	user: payload,
});

export const user = createReducer<IUserReducer>({}, defaultState)
	.on(actions.userChecked, onUserChecked)
	.on(actions.userRegister, onUserRegisterRequest)
	.on(actions.userRegisterSuccess, onUserRegisterSuccess)
	.on(actions.userLogin, onUserLoginRequest)
	.on(actions.userLoginSuccess, onUserLoginSuccess)
	.on(actions.userLogoutAndClear, onUserClear)
	.on(actions.userUpdateRequest, onUserUpdateRequest)
	.on(actions.userUpdateSuccess, onUserRegisterSuccess)
	.on(actions.userChangePasswordRequest, onUserChangePasswordRequest)
	.on(actions.userStateChangeAction, onUserGeneralStateHandler)
	.on(actions.requestPasswordChange, onRequestPasswordChange)
	.on(actions.contactUsRequest, onContactUsRequest)
	.on(actions.userAccountRecoverSuccess, onUserAccountRecoverSuccess);
