import React from "react";
import styled from "styled-components";

const direction = {
	bottom: 0,
	left: 90,
	top: 180,
	right: 270,
};

// 180

interface IProps {
	width?: number;
	height?: number;
	color?: string;
	to?: keyof typeof direction;
}

const SVG = styled.svg<Pick<IProps, "to">>`
	transform: rotate(${({to}) => direction[to!]}deg);
`;

export const IconChevron: React.FC<IProps> = ({color, ...rest}) => (
	<SVG viewBox="0 0 12 7" fill="none" {...rest}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M0.784779 1.13848C0.614969 0.95624 0.619979 0.672255 0.796113 0.496121C0.972247 0.319987 1.25623 0.314976 1.43847 0.484787L6.28564 5.4183L11.1328 0.484787C11.3151 0.314976 11.599 0.319987 11.7752 0.496121C11.9513 0.672255 11.9563 0.95624 11.7865 1.13848L6.28564 6.63934L0.784779 1.13848Z"
			fill={color}
		/>
	</SVG>
);

IconChevron.defaultProps = {
	width: 12,
	height: 7,
	color: "currentColor",
	to: "bottom",
};
