import React, {useCallback} from "react";
import {IconPlus, IconRemove} from "components/Icons";
import {useDispatch, useSelector} from "react-redux";
import {
	getIsActualRoundLastMatchActive,
	getPlayerById,
	getPlayerInTeamCheckSelector,
	isTeamFullFilled,
} from "modules/selectors";
import {pickPlayerToTeam, removePlayerFromTeam} from "modules/actions";
import styled from "styled-components";
import {PureButton} from "components/styled";
import {Exist} from "components/Exist";

interface IButtonProps {
	color?: string;
}
const ActionButton = styled(PureButton)<IButtonProps>`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 45px;
	height: 45px;

	> div {
		width: 16px;
		height: 16px;
		border-radius: 50%;
		background: ${(props) => props.color ?? "#000151"};
		display: flex;
		justify-content: center;
		align-items: center;
	}
`;

interface IProps {
	playerId: number;
}

export const IncompleteTeamActions: React.FC<IProps> = ({playerId}) => {
	const dispatch = useDispatch();
	const {id, locked} = useSelector(getPlayerById)(playerId);
	const isInTeam = useSelector(getPlayerInTeamCheckSelector)(playerId);
	const isCanBeAddedToTeam = !useSelector(isTeamFullFilled);
	const isAddVisible = !isInTeam && isCanBeAddedToTeam;

	const isLastRoundActive = useSelector(getIsActualRoundLastMatchActive);
	const isUserLocked = isLastRoundActive ? false : locked;

	const removePlayer = useCallback(() => {
		dispatch(removePlayerFromTeam(id));
	}, [dispatch, id]);

	const addPlayerToTeam = useCallback(() => {
		dispatch(pickPlayerToTeam(id));
	}, [dispatch, id]);

	if (isUserLocked) {
		return null;
	}

	return (
		<React.Fragment>
			<Exist when={isInTeam}>
				<ActionButton onClick={removePlayer} color="#F02F42">
					<div>
						<IconRemove width={8} height={8} color="#FFFFFF" />
					</div>
				</ActionButton>
			</Exist>

			<Exist when={isAddVisible}>
				<ActionButton onClick={addPlayerToTeam}>
					<div>
						<IconPlus width={14} height={14} color="#FFFFFF" />
					</div>
				</ActionButton>
			</Exist>
		</React.Fragment>
	);
};
