import {createReducer} from "redux-act";
import {dismissMessageBar, fetchMessageBarSuccess} from "modules/actions";
import {IMessageBar} from "modules/types";
import {PersistStorage} from "modules/utils/PersistStorage";
import {concat} from "lodash";

interface IMessageBarReducer {
	dismissedMessages: number[];
	currentMessage: IMessageBar;
}

const storageKey = "message_bar";
const defaultState = {
	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
	dismissedMessages: JSON.parse(PersistStorage.GET(storageKey) || "[]"),
	currentMessage: {
		enabled: false,
		version: 0,
		title: "",
		text: "",
	},
};

export const messageBar = createReducer<IMessageBarReducer>({}, defaultState)
	.on(fetchMessageBarSuccess, (state, message) => ({
		...state,
		currentMessage: message,
	}))
	.on(dismissMessageBar, (state, version) => {
		const dismissedMessages = concat(state.dismissedMessages, version);
		PersistStorage.SET(storageKey, dismissedMessages);
		return {
			...state,
			dismissedMessages,
		};
	});
